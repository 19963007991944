import * as React from 'react';
import './App.css';
import ProjectManage from './pages/project/ProjectManage';
import Api from './data/API';
import {Layout, Menu} from 'antd';
import {Icon} from '@ant-design/compatible';
import Login from './pages/login/Login';
import MYHeader from './components/MYHeader';
import {NavLink, Route, Switch} from 'react-router-dom';
import FaceDeviceManage from './pages/device/FaceDeviceManage';
import CompanyManage from './pages/company/CompanyManage';
import Home from './pages/home';
import BlueDeviceManage from './pages/blueTooth/BlueDeviceManage';
import PermManager from './pages/perm/PermManager';
import FeedBackManage from "./pages/feedBack/FeedBackManage";
import DataTransmissionManage from "./pages/dataTransmission/DataTransmissionManage";
import AdManage from './pages/ad/AdManage';
import PartnerManager from "./pages/parnter/PartnerManager";
import SmsList from "./pages/sms/SmsList";
import TimedTaskMiniApp from './pages/timedTask/TimedTaskMiniApp';
const {Header, Sider, Content} = Layout;
class App extends React.Component {
    public allRoutes = [
        {
            key: 'home',
            path: '/home',
            sidebar: '首页',
            icon: 'gold',
            main: () => <Home/>
        }, {
            key: 'company-manager',
            path: '/company/company-manager',
            sidebar: '公司管理',
            icon: 'gold',
            main: () => <CompanyManage/>
        }, {
            key: 'project-manager',
            path: '/project/project-manager',
            sidebar: '项目管理',
            icon: 'gold',
            main: () => <ProjectManage/>
        }, {
            key: 'face-device-manager',
            path: '/device',
            sidebar: '人脸设备管理',
            icon: 'gold',
            main: () => <FaceDeviceManage/>
        },
        {
            key: 'lanya-device-manager',
            path: '/lanya-device',
            sidebar: '蓝牙设备管理',
            icon: 'gold',
            main: () => <BlueDeviceManage/>
        },
        {
            key: 'perm-manager',
            path: '/perm/perm-manager',
            sidebar: '权限管理',
            icon: 'gold',
            main: () => <PermManager/>
        },
        {
            key: 'feedBack-manager',
            path: '/user/feedBack-manager',
            sidebar: '用户反馈',
            icon: 'gold',
            main: () => <FeedBackManage/>
        },
        {
            key: 'dataTransmission-manager',
            path: '/data/dataTransmission-manager',
            sidebar: '数据传输',
            icon: 'gold',
            main: () => <DataTransmissionManage/>
        },
        {
            key: 'ad-manager',
            path: '/ad/ad-manager',
            sidebar: '广告管理',
            icon: 'gold',
            main: () => <AdManage/>
        },
        {
            key: 'partner-device',
            path: '/partner',
            sidebar: '合伙人管理',
            icon: 'gold',
            main: () => <PartnerManager/>
        },
        {
            key: 'smsList',
            path: '/sms/smsList',
            sidebar: '短信模板管理',
            icon: 'gold',
            main: () => <SmsList/>
        },
        {
            key: 'timedTask',
            path: '/timed_task',
            sidebar: '定时任务管理',
            icon: 'gold',
            main: () => <TimedTaskMiniApp/>
        }

    ];

    public state: any;

    constructor(props: any) {
        super(props);
        this.state = {
            collapsed: false,
            dataReady: false,
            ds: [],
            loginState: false,
            userRoutes: [],
            msg: ''
        };
    }

    componentDidMount() {
        Api.getAllPerm();
    }

    public componentWillMount() {
        const state = Api.getLoginState();
        this.setState({loginState: state});
        if (!state) {
            if (window.location.pathname !== '/wy/login') {
                window.location.href = '/wy/login';
            }
            return;
        }
        this.setState({
                          marginLeft: 'marginLeft',
                          isMarginLeft: false,
                          width: '100%',
                          paddingRight: 220
                      });

    }

    public toggle = () => {
        this.setState({
                          collapsed: !(this.state as any).collapsed,
                      });
        if ((this.state as any).isMarginLeft) {
            this.setState({
                              marginLeft: 'marginLeft',
                              isMarginLeft: false,
                              paddingRight: 220
                          });
        } else {
            this.setState({
                              marginLeft: 'marginLeft2',
                              isMarginLeft: true,
                              paddingRight: 80
                          });
        }
    };

    public render() {
        return (this.state as any).loginState !== true ?
            <Route path="/wy/login" component={Login}/> : (
                <Layout>
                    <Sider
                        width={220}
                        trigger={null}
                        collapsed={(this.state as any).collapsed}
                        className="leftSider"
                    >
                        <div className="logo" style={{height: 30, marginBottom: 10}}>
                            <h3 className="titleText">
                                运营管理中心
                                {/*{(this.state as any).isMarginLeft ? localStorage.getItem('wyYeShortName') : localStorage.getItem('wyYeShortName') + ' | ' + localStorage.getItem('wuyeSlogan')}*/}
                            </h3>
                        </div>
                        <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']}>
                            {this.allRoutes.map((route: any, index: number) => (
                                <Menu.Item key={route.path}>
                                    <NavLink to={route.path}>
                                        <Icon type={route.icon}/>
                                        <span>{route.sidebar}</span>
                                    </NavLink>
                                </Menu.Item>
                            ))}
                        </Menu>
                    </Sider>
                    <Layout className={(this.state as any).marginLeft}>
                        <Header style={{position: 'fixed', zIndex: 1000, backgroundColor: '#fff', padding: '0 10px', height: 50, width: '100%', paddingRight: (this.state as any).paddingRight}}>
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                <div>
                                    <a><Icon type={(this.state as any).collapsed ? 'menu-unfold' : 'menu-fold'} onClick={this.toggle} style={{color: '#fff', fontSize: 25, marginTop: '13px'}}/></a>
                                </div>
                                <div>
                                    <MYHeader/>
                                </div>
                            </div>
                        </Header>
                        <Content className="screen-desktop-wide">
                            <Switch>
                                {this.allRoutes.map((route: any, index: number) => (
                                    <Route
                                        key={index}
                                        path={route.path}
                                        component={route.main}
                                    />
                                ))}
                                {/*<Route path="/partner/partner-device" component={PartnerDeviceList} />*/}
                                {/*<Route path="/partner/partner-income" component={PartnerIncomeList} />*/}
                                <Route path="/device/add-device" component={FaceDeviceManage}/>
                                <Route path="/device/detect-record" component={FaceDeviceManage} />

                                <Route render={() => <h3>{(this.state as any).msg}</h3>}/>
                            </Switch>
                        </Content>
                    </Layout>
                </Layout>);
    }
}

export default App;