import Api from '../data/API';
import { Upload } from 'antd';
import React from 'react';
const URLS = {
	PRIVATE: Api.apiRootPath + '/api/picture/upload_qiniu',
	PUBLIC: Api.apiRootPath + '/api/picture/upload_qiniu_two',
};

export default function CustomUploadImage(props: any) {
	const cdn = props.cdn ? URLS[props.cdn.toUpperCase()] : URLS.PUBLIC;
	const listType = props.listType ? props.listType : 'picture';

	const render = props.render ? props.render : null;
	const { ...rest } = props;
	return (
		<>
				<Upload
				name="file"
				listType={listType}
				data={{ wuye_uuid: localStorage.getItem('wyUuid') }}
				action={cdn}
				accept={'.jpg,.png'}
				{...rest}
			>
				{render}
			</Upload>
		</>

	);
}
