import * as React from 'react';
import GSTable from '../../components/GSTable';
import {addPermItem, getPermList, updatePermItem, updatePermItemOrder} from '../../services/OpService';
import {Button, Form, Input, message, Modal, Select} from 'antd';
import {useCallback, useRef, useState} from 'react';
import {ActionType} from '@ant-design/pro-table/lib/typing';
import {FormInstance} from 'antd/lib/form';
import DragableBodyRow from '../../components/DragableBodyRow';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {DndProvider} from 'react-dnd';
import update from 'immutability-helper';

let permEnum: any = {
    0: '-'
};
let typeEnum: any = {
    'menu': '菜单',
    'button': '按钮'
};
const layout = {
    labelCol: {span: 6},
    wrapperCol: {span: 19},
};
const FormItem = Form.Item;


const PermManager: React.FC = () => {
    let tempList: [any?] = [];
    const actionRef: any = useRef<ActionType>();
    const updateForm: any = React.createRef<FormInstance>();
    const [addForm] = Form.useForm<FormInstance>();
    const [addVisible, setAddVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const columns = [{
        title: '权限名',
        dataIndex: 'name',
        key: 'name',
    }, {
        title: '上级权限',
        dataIndex: 'parentId',
        valueEnum: permEnum,
        key: 'parentId',
    }, {
        title: '类型',
        dataIndex: 'type',
        key: 'type',
        valueEnum: typeEnum
    }, {
        title: '排序',
        dataIndex: 'order',
        key: 'order',
    }, {
        title: '操作',
        key: 'action',
        render: (text: string, record: any) => {
            return (
                <div>
                    <a onClick={() => showUpdateModel(record)}>编辑</a>
                </div>
            );
        }
    }];
    const [updateVisible, setUpdateVisible] = useState(false);
    const [permList, setPermList] = useState<[any?]>([]);
    const [data, setData] = useState([]);
    const getData = async () => {
        setLoading(true);
        let rsp = await getPermList({});
        setLoading(false);
        if (rsp.err === 0) {
            setPermEnum(rsp.data);
            setData(rsp.data);
            return {
                data: rsp.data
            };
        } else {
            return {
                data: []
            };
        }
    };
    const setPermEnum = (data: any) => {
        data.map((item: any) => {
            permEnum[item.id] = item.name;
            if (item.type === 'menu') {
                tempList.push({value: item.id, name: item.name});
            }
            if (item.children) {
                setPermEnum(item.children);
            }
        });
        setPermList(tempList);
    };
    const showUpdateModel = (record: any) => {
        setUpdateVisible(true);
        updateForm.current.setFieldsValue(record);
    };
    const updatePermInfo = () => {
        updateForm.current.validateFields().then(async (data: any) => {
            console.log('data::::', data);
            let rsp = await updatePermItem(data);
            setUpdateVisible(false);
            if (rsp.err === 0) {
                message.success(rsp.msg);
                actionRef.current.reload();
            } else {
                message.error(rsp.msg);
            }
        });
    };
    const components = {
        body: {
            row: DragableBodyRow,
        },
    };
    const addItem = () => {
        addForm.validateFields().then(async (data: any) => {
            console.log('data::;', data);
            const res = await addPermItem(data);
            if (res.err === 0) {
                actionRef.current.reload();
                addForm.resetFields();
                message.success('添加成功');
                setAddVisible(false);
            } else {
                message.error('添加失败');
            }
        });
    };
    const moveRow = useCallback(
        async (dragIndex, hoverIndex, key) => {
            if (dragIndex === hoverIndex) {
                return;
            }
            findArray(data, key, dragIndex, hoverIndex);
        },
        [data],
    );
    const findArray = (data: any, id: any, dragIndex: number, hoverIndex: number) => {
        data.map( async (item: any) => {
            if (item.id === id) {
                const dragRow = data[dragIndex];
                let newArr = update(data, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragRow],
                    ],
                });
                newArr.map((item: any, index: number) => {
                    item.order = index;
                });
                let params = {
                    data: JSON.stringify(newArr)
                };
                setLoading(true);
                let rsp = await updatePermItemOrder(params);
                setLoading(false);
                if (rsp.err === 0) {
                    if (actionRef.current) {
                        actionRef.current.reload();
                    }
                }
                return;
            }
            if (item.children) {
                findArray(item.children, id, dragIndex, hoverIndex);
            }
        });
    };
    const tableOperationsBarRender = () => ([
        <Button type={'primary'} onClick={() => {
            setAddVisible(true);
        }}> 添加</Button>
    ]);
    return (
        <div className="content-big">
            <DndProvider backend={HTML5Backend}>
                <GSTable
                    operationsBarRender={tableOperationsBarRender()}
                    actionRef={actionRef}
                    components={components}
                    loading={loading}
                    onRow={(record: any, index: any) => ({
                        index,
                        moveRow,
                    })}
                    columns={columns}
                    request={getData}
                    rowKey={'id'}/>
            </DndProvider>
            <Modal forceRender={true} visible={updateVisible} onCancel={() => setUpdateVisible(false)} title={'编辑'} onOk={updatePermInfo}>
                <Form {...layout} ref={updateForm}>
                    <FormItem hidden={true} name={'id'}/>
                    <FormItem name={'name'} label={'权限名'}>
                        <Input/>
                    </FormItem>
                    <FormItem name={'permKey'} label={'permKey'}>
                        <Input/>
                    </FormItem>
                    <FormItem name={'parentId'} label={'上级权限'}>
                        <Select showSearch={true} optionFilterProp="children">
                            <Select.Option value={0}>无</Select.Option>
                            {
                                permList.map((item) => {
                                    return <Select.Option value={item.value}>{item.name}</Select.Option>;
                                })
                            }
                        </Select>
                    </FormItem>
                    <FormItem name={'type'} label={'类型'}>
                        <Select>
                            <Select.Option value={'menu'}>
                                菜单
                            </Select.Option>
                            <Select.Option value={'button'}>
                                按钮
                            </Select.Option>
                        </Select>
                    </FormItem>
                    <FormItem name={'url'} label={'路径'}>
                        <Input/>
                    </FormItem>
                    <FormItem label={'排序值'} name={'sortby'}>
                        <Input/>
                    </FormItem>
                </Form>
            </Modal>
            <Modal title={'添加'} visible={addVisible} onCancel={() => {
                setAddVisible(false);
            }} onOk={addItem}>
                <Form {...layout} form={addForm}>
                    <FormItem name={'name'} label={'权限名'}>
                        <Input/>
                    </FormItem>
                    <FormItem name={'permKey'} label={'permKey'}>
                        <Input/>
                    </FormItem>
                    <FormItem name={'parentId'} label={'上级权限'}>
                        <Select showSearch={true} optionFilterProp="children">
                            <Select.Option value={0}>无</Select.Option>
                            {
                                permList.map((item) => {
                                    return <Select.Option value={item.value}>{item.name}</Select.Option>;
                                })
                            }
                        </Select>
                    </FormItem>
                    <FormItem name={'type'} label={'类型'}>
                        <Select>
                            <Select.Option value={'menu'}>
                                菜单
                            </Select.Option>
                            <Select.Option value={'button'}>
                                按钮
                            </Select.Option>
                        </Select>
                    </FormItem>
                    <FormItem name={'url'} label={'路径'}>
                        <Input/>
                    </FormItem>
                    <FormItem label={'排序值'} name={'sortby'}>
                        <Input/>
                    </FormItem>
                </Form>
            </Modal>
        </div>
    );
};
export default PermManager;
