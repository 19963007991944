import React, { useRef, useState} from "react";
import GSTable from "../../components/GSTable";
import { ActionType } from "@ant-design/pro-table";
import {
    addPartnerCash,
     deletePartnerCash,
     getPartnerCashList,
     updatePartnerCash,
     withDrawIncome,
} from "../../services/PartnerService";
import { Button, Col, Form, Input, InputNumber, Modal, Row, Space, Tag ,Select, message} from "antd";
import { FormInstance } from "antd/es/form";

const PartnerCashed:React.FC = () =>{
    const actionRef = useRef<ActionType>()
    const FormItem = Form.Item;
    const [form] = Form.useForm<FormInstance>()
    const [visible, setVisible] = useState(false)
    const [add,setAdd] = useState(false)
    const columns = [
        {
            title: '订单号',
            dataIndex: 'orderNo',
            key: 'orderNo',
            hideInSearch: true
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            hideInSearch: true,
            render: (text: string, item: any) => {
                return <>
                    <Tag color={item.status == 1 ? 'gold' : item.status == 2 ? 'green' : ''}>
                        {item.status == 1 ?'处理中':item.status == 2?'完成':''}
                    </Tag>
                </>
            }
        },
        {
            title: '收款人',
            dataIndex: 'userName',
            key: 'userName',
            hideInSearch: true
        },
        {
            title: '收款手机号',
            dataIndex: 'userPhone',
            key: 'userPhone',
            hideInSearch: true
        },
        {
            title: '操作金额',
            dataIndex: 'operateAmount',
            key: 'operateAmount',
            hideInSearch: true,
            render: (text: string) => <span>{(parseInt(text) / 100).toFixed(2)}</span>,
        },
        {
            title: '手续费',
            dataIndex: 'handFee',
            key: 'handFee',
            hideInSearch: true,
            render: (text: string) => <span>{(parseInt(text) / 100).toFixed(2)}</span>,
        },
        {
            title: '提现金额',
            dataIndex: 'cashAmount',
            key: 'cashAmount',
            hideInSearch: true,
            render: (text: string) => <span>{(parseInt(text) / 100).toFixed(2)}</span>,
        },
        {
            title: '到账时间',
            dataIndex: 'arrivalTime',
            key: 'arrivalTime',
            valueType:'dateTime',
            hideInSearch: true,
        },
        {
            title: '操作',
            hideInSearch: true,
            render: (text: string, item: any) => {
                return <>
                    {item.status != 2 && 
                        (
                        <>
                             <a onClick={() => { openUpdateModal(item) }}>编辑</a>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <a onClick={() => { deleteItem(item) }}>删除</a>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <a onClick={() => { updateStatus(item,2) }}>提现</a>
                        </>
                    )
                   }
                </>
            }
        },
        
    ];
    const deleteItem = (item: any) => {
        Modal.confirm({
            content: '你是否要删除'+item.userPhone+"的提现记录？",
            okType: 'danger',
            onOk: async () => {
                const res = await deletePartnerCash({id:item.id,userPhone:item.userPhone})
                if (res.err === 0) {
                    message.success("删除成功")
                    actionRef.current?.reload()
                } else {
                    message.error("删除失败")
                }
            },
            onCancel() {
              console.log('Cancel');
            },
          });
    }
    const updateStatus = (item: any,status:number) => {
        Modal.confirm({
            content: '你是否提现？',
            onOk: async () => {
                const res = await withDrawIncome({id:item.id,userPhone:item.userPhone,status})
                if (res.err === 0) {
                    message.success("提现成功")
                    actionRef.current?.reload()
                } else {
                    message.error("提现失败")
                }
            },
            onCancel() {
              console.log('Cancel');
            },
          });
    }
    const openUpdateModal = (item: any) => {
        const formData:any = {
            id: item.id,
            userPhone: item.userPhone,
            handFee: (parseInt(item.handFee) / 100).toFixed(2),
            payType: item.payType,
            cashAmount: (parseInt(item.cashAmount) / 100).toFixed(2),
            operateAmount: (parseInt(item.operateAmount) / 100).toFixed(2),
        }
        form.setFieldsValue(formData)
        setAdd(false)
        setVisible(true)
    }

    // @ts-ignore
    const  getList = async (param: any) => {
        let rsp = await getPartnerCashList({
            ...param,
            page: param.current,
            size: param.pageSize})
        if (rsp.err === 0) {
            return {
                data: rsp.data,
                total: rsp.total
            };
        } else {
            return {
                data: [],
                total: 0
            };
        }
    };
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };
    const addOpenModal = () => {
        form.resetFields()
        setAdd(true)
        setVisible(true)
    }
    const tableOperationsBarRender = () => [
        <Space>
            <Button onClick={addOpenModal} type="primary">添加</Button>
        </Space>
    ]
    const saveItem = () => {
        if (add) {
            addItem()
        } else {
            updateItem()
        }
    }
    const addItem = () => {
        form.validateFields().then(async (data: any) => {
            const formData: any = {
                userPhone: data.userPhone,
                payType: data.payType,
                operateAmount: Math.floor(data.operateAmount * 100),
                handFee: Math.floor(data.handFee * 100),
                cashAmount: Math.floor(data.cashAmount * 100) ,
                orderNo: data.orderNo,
            }
            const res = await addPartnerCash(formData)
            if (res.err === 0) {
                message.success("添加成功")
                setVisible(false)
                setAdd(false)
                actionRef.current?.reload()
            } else {
                message.error("添加失败")
            }
        })
    }
    const updateItem = () => {
        form.validateFields().then(async (data: any) => {
            const formData: any = {
                id:data.id,
                userPhone: data.userPhone,
                payType: data.payType,
                operateAmount: Math.floor(data.operateAmount * 100),
                handFee: Math.floor(data.handFee * 100),
                cashAmount: Math.floor(data.cashAmount * 100) ,
                orderNo: data.orderNo,
            }
            const res = await updatePartnerCash(formData)
            if (res.err === 0) {
                message.success("修改成功")
                setVisible(false)
                setAdd(false)
                actionRef.current?.reload()
            } else {
                message.error("修改失败")
            }
        })
    }
    return(<>
        <Row gutter={8} justify={'center'} align={'middle'}>
            <Col span={24}>
                <GSTable  operationsBarRender={tableOperationsBarRender()}  actionRef={actionRef}  columns={columns}  request={(params:any)=>{return getList(params)}}/>
            </Col>
        </Row>
        <Modal title={add ? '添加' : '修改'} visible={visible} onOk={saveItem} onCancel={()=>{setVisible(false)} }>
            <Form form={form} {...formItemLayout}>
                <FormItem hidden={true} name="id">
                    <Input></Input>
                </FormItem>
                <FormItem label="手机号" name="userPhone" rules={[{ required: true }]}>
                    <InputNumber style={ {width:'100%'}}/>
                </FormItem>
                <FormItem label="支付类型" name="payType" rules={[{required:true}]}>
                    <Select>
                        <Select.Option value="微信" >微信</Select.Option>
                        <Select.Option value="支付宝" >支付宝</Select.Option>
                    </Select>
                </FormItem>
                <FormItem name="operateAmount" label="操作金额" rules={[{required:true}]}>
                    <InputNumber style={{ width: '100%' }} onChange={(value: any) => {
                        const fValue = value * 100
                        const fhandFee = Math.ceil(fValue * 0.006)
                        const fcashAmount = fValue - fhandFee
                        const handFee = (fhandFee/100).toFixed(2)
                        const cashAmount = (fcashAmount / 100).toFixed(2)
                        form.setFieldsValue({
                            //@ts-ignore
                            handFee: handFee,
                            cashAmount: cashAmount
                        })
                    }}  step={0.01}></InputNumber>
                </FormItem>
                <FormItem name="handFee" label="手续费" rules={[{required:true}]}>
                    <InputNumber style={ {width:'100%'}}  step={0.01}></InputNumber>
                </FormItem>
                <FormItem name="cashAmount" label="提现金额" rules={[{required:true}]}>
                    <InputNumber  style={ {width:'100%'}} step={0.01}></InputNumber>
                </FormItem>
                <FormItem label="订单号" name="orderNo" >
                    <Input style={ {width:'100%'}}/>
                </FormItem>
            </Form>
        </Modal>
    </>)
}
export default PartnerCashed