import React, { useEffect, useState } from 'react';
import { NavLink, Redirect } from 'react-router-dom';
import { Route, Switch } from 'react-router';
import { Affix,  Menu, Result } from 'antd';

export interface MiniAppMenu {
	name: string;
	path: string;
	authority?: string;
	component: React.ComponentType;
}

export interface MiniAppRoute {
	path: string;
	authority?: string;
	component: React.ComponentType;
}

export interface MiniAppProps {
	miniAppName: string;
	rootUrl: string;
	menus: MiniAppMenu[];
	routes?: MiniAppRoute[];
}

export default function MiniApp(props: MiniAppProps) {
	// /**
	//  * 过滤用户权限后的菜单项目
	//  */
	// const filteredMenus: MiniAppMenu[] = props.menus.filter((menu, index, menus) => {
	// 	return DAL.checkPermission(menu.authority || '');
	// });
	//
	// /**
	//  * 过滤用户权限后的菜单项目
	//  */
	// const filteredRoutes: MiniAppRoute[] = (props.routes || []).filter((menu, index, menus) => {
	// 	return DAL.checkPermission(menu.authority || '');
	// });

	const [curMenuPath, setCurMenuPath] = useState('');

	useEffect(() => {
		let curMenu = window.location.pathname.split('/')[2];
		console.log('curMenu::', curMenu);
		setCurMenuPath(curMenu);
	}, []);

	const switchMenu = (e: any) => {
		setCurMenuPath(e.key);
	};

	return (
		<div>
			{/*
            <Affix offsetTop={0}>
                <AppHeader isSidebarCollapsed={false} toggleSidebar={() => {}}/>
            </Affix>
			*/}
			<Affix offsetTop={48}>
				<Menu onClick={switchMenu} selectedKeys={[curMenuPath]} mode="horizontal" style={{ textAlign: 'left' }}>
					{props.menus.map((item: MiniAppMenu) => (
						<Menu.Item key={item.path}>
							<NavLink className={'TextDecora'} to={props.rootUrl + '/' + item.path}>
								{item.name}
							</NavLink>
						</Menu.Item>
					))}
				</Menu>
			</Affix>
			<div className="content-big">
				<Switch>
					{props.menus.map((item: MiniAppMenu) => (
						<Route path={props.rootUrl + '/' + item.path} component={item.component} />
					))}

					{(props.routes || []).map((item: MiniAppRoute) => (
						<Route path={props.rootUrl + '/' + item.path} component={item.component} />
					))}
					{/* 重定向根url路径到第一个菜单项 */}
					{(props.menus || []).length > 0 ? (
						<Redirect from={props.rootUrl} to={`${props.rootUrl}/${props.menus[0].path}`} />
					) : null}

					<Route path="*">
						<Result status="404" title="无权限" subTitle="您无权限访问该页面请联系管理员" />
					</Route>
				</Switch>
			</div>
		</div>
	);
}
