import * as React from 'react';
import { Route, Switch, Redirect} from 'react-router-dom';
import DataTransmissionList from './DataTransmissionList';


class DataTransmissionManage extends React.Component {

    state: any;
    constructor(props: any) {
        super(props);
        this.state = {url: window.location.pathname, current: 'dataTransmission-manager'};
    }

    handleClick = (e: any) => {
        console.log('click ', e);
        this.setState({
            current: e.key,
        });
    }

    render() {
        return (
            <div>
                <div className="content-big">
                    <Switch>
                        <Route path="/data/dataTransmission-manager" component={DataTransmissionList} />
                        <Redirect to="/data/dataTransmission-manager" />
                        <Route render={() => <DataTransmissionList/>} />
                    </Switch>
                </div>

            </div>
        );
    }
}

export default DataTransmissionManage;