import * as React from 'react';
import { Route, Switch, NavLink, Redirect} from 'react-router-dom';
import { Menu } from 'antd';
import PartnerDeviceList from "./PartnerDeviceList";
import PartnerIncomeList from "./PartnerIncomeList";
import PartnerCashed from './PartnerCashed';


class PartnerManager extends React.Component {

	state: any;
	constructor(props: any) {
		super(props);
		this.state = {url: window.location.pathname, current: '/partner/partner-device'};
	}
	handleClick = (e: any) => {
		console.log('click ', e);
		this.setState({
			current: e.key,
		});
	}

	render() {
		return (
			<div>
				<div role="navigation">
					<div className="container-fluid">
						<Menu
							onClick={this.handleClick}
							selectedKeys={[this.state.current]}
							mode="horizontal"
							style={{textAlign: 'left'}}
						>
							<Menu.Item key="/partner/partner-device">
								<NavLink to={'/partner/partner-device'}>合伙人设备</NavLink>
							</Menu.Item>
							<Menu.Item key="/partner/partner-income">
								<NavLink to={'/partner/partner-income'}>合伙人收益</NavLink>
							</Menu.Item>
							<Menu.Item key="/partner/partner-cashed">
								<NavLink to={'/partner/partner-cashed'}>提现记录</NavLink>
							</Menu.Item>
						</Menu>
					</div>
				</div>
				<div className="content-big">
					<Switch>
						<Route path="/partner/partner-device" component={PartnerDeviceList} />
						<Route path="/partner/partner-income" component={PartnerIncomeList} />
						<Route path="/partner/partner-cashed" component={PartnerCashed} />
						<Redirect to="/partner/partner-device" />
						<Route render={() => <PartnerDeviceList/>} />
					</Switch>
				</div>

			</div>
		);
	}
}

export default PartnerManager;