import React from 'react';
import ProTable from '@ant-design/pro-table';
import {Space } from "antd";


export default function GSTable(props: any) {

    const {pagination = {}, ...rest} = props;
    const finalPagination = !pagination
        ? false
        : {
            defaultCurrent: 1,
            defaultPageSize: 10,
            // current: 1,
            // pageSize: 10,
            size: 'small',
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: (total: string) => <span>共 {total} 条记录</span>,
            ...pagination,
        };

    /**
     * 渲染表格顶部的工具栏，融合搜索、操作、默认按钮；
     * 当前样式：筛选/搜索等查询操作左对齐；数据增删改等操作右对齐，默认工具按钮（刷新、设置、全屏）右对齐
     *
     * 可以创建其它样式渲染方法供用户选择
     */
    const toolBarRender = () => {
        // 查询操作
        let a;
        if (props.searchBarRender?.length > 0) {
            a =
                <Space className='gstable-search-bar'>
                    {
                        props.searchBarRender.map((m: any) => m)
                    }
                </Space>
        }

        // 增删改操作
        let b: any = [];
        if (props.operationsBarRender?.length > 0) {
            // b =
            //     <Space className='gstable-operations-bar'>
            //         {
            //             props.operationsBarRender.map(m => {
            //                 b
            //             })
            //         }
            //     </Space>
            {
                props.operationsBarRender.map((m: any) => {
                    b.push(m)
                })
            }
        }
        return {
            search: a,
            actions: b,
        };
    };
    return (
        <ProTable
            search={props.search ? props.search : false}
            options={props.options ? props.options : {
                density: false
            }}
            toolbar={toolBarRender()}
            size="middle"
            // bordered={true}
            pagination={finalPagination}
            {...rest}
        />
    );
}
