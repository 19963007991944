import Api from "../data/API";

export async function getAdminWuyeProject(params:any) {
    return Api.postWithAuth('/api/wuye/get_admin_wuye_project', params);
}
export async function addProject(params:any) {
    return Api.postWithAuth('/api/wuye/add_project', params);
}
export async function updataProject(params:any) {
    return Api.postWithAuth('/api/wuye/updata_project', params);
}

export async function updateApplication(params:any) {
    return Api.postWithAuth('/api/wuye/updateApplication', params);
}
