import * as React from 'react';
import { Route, Switch, NavLink, Redirect} from 'react-router-dom';
import { Menu } from 'antd';
import FaceDeviceList from './FaceDeviceList';
import AddFaceDevice from './AddFaceDevice';
import DetectRecord from './DetectRecord';


class FaceDeviceManage extends React.Component {

    state: any;
    constructor(props: any) {
        super(props);
        this.state = {url: window.location.pathname, current: 'device-manager'};
    }

    handleClick = (e: any) => {
        console.log('click ', e, this.state.current);
        this.setState({
            current: e.key,
        });
    }

    render() {
        return (
            <div>
                <div role="navigation">
                    <div className="container-fluid">
                        <Menu
                            onClick={this.handleClick}
                            selectedKeys={[this.state.current]}
                            mode="horizontal"
                            style={{textAlign: 'left'}}
                        >
                            <Menu.Item key="device-manager">
                                <NavLink to={'/device/device-manager'}>人脸设备</NavLink>
                            </Menu.Item>
                            <Menu.Item key="detect-record">
                                <NavLink to={'/device/detect-record'}>识别日志</NavLink>
                            </Menu.Item>
                        </Menu>
                    </div>
                </div>
                <div className="content-big">
                    <Switch>
                        <Route path="/device/device-manager" component={FaceDeviceList} />
                        <Route path="/device/add-device" component={AddFaceDevice} />
                        <Route path="/device/detect-record" component={DetectRecord} />
                        <Redirect to="/device/device-manager" />
                        <Route render={() => <FaceDeviceList/>} />
                    </Switch>
                </div>

            </div>
        );
    }
}

export default FaceDeviceManage;