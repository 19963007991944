import GSTable from "../../components/GSTable";
import { ActionType } from "@ant-design/pro-table";
import API from "../../data/API";
import React, { useRef, useState } from "react";
import moment from "moment"
import { DatePicker, message, Modal } from "antd";
const FeedBackList:React.FC = () =>{
    const actionRef = useRef<ActionType>()
    let { RangePicker } = DatePicker;
    const [startDay, setStartDay] = useState<any>( moment().subtract(30, "d").format("YYYY-MM-DD"));
    const [endDay, setEndDay] = useState<any>( moment().format("YYYY-MM-DD"));
    const  columns = [
        {
            title: '手机号',
            dataIndex: 'phone',
            key: 'phone',
        }, {
            title: '内容',
            dataIndex: 'feeBack',
            key: 'feeBack',
        },{
            title: '类型',
            dataIndex: 'typeCode',
            key: 'typeCode',
            hideInSearch: true
        },{
            title: '创建时间',
            dataIndex: 'time',
            key: 'time',
            hideInSearch: true,
            render: (text: string, record: any) =>  <span>
                {moment(text).format("YYYY-MM-DD HH:ss:mm")}
            </span>,
        },
        {
            title: '创建时间',
            dataIndex: 'time1',
            hideInTable:true,
            renderFormItem: (text: string, record: any) =>  <span>
               {startDay === null || startDay === undefined || startDay === '' ? (
                   <RangePicker
                       format="YYYY-MM-DD"
                       placeholder={['请选择开始时间', '请选择结束时间']}
                       onChange={dateTime}
                       style={{  width: '100%'}}
                   />
               ) : (
                   <RangePicker
                       format="YYYY-MM-DD"
                       placeholder={['请选择开始时间', '请选择结束时间']}
                       onChange={dateTime}
                       value={[moment(startDay, 'YYYY-MM-DD'), moment(endDay, 'YYYY-MM-DD')]}
                       style={{ width: '100%' }}
                   />
               )}
            </span>,
        },
        {
            title: '操作',
            key: 'action',
            hideInSearch: true,
            render: (text: string, record: any) => {
                return (
                    <div>
                        <a onClick={()=>{deleteItem(record)}}>删除</a>
                    </div>
                );
            }
        }
    ];
    const dateTime = (date: any, dataToString: any) => {
        setStartDay(dataToString[0]);
        setEndDay(dataToString[1]);
    };

    const deleteItem =  (item:any) =>{
        Modal.confirm({
            content: '您确定要删除'+item.phone +'吗？',
            okType: 'danger',
            onOk:async () =>{
                const res = await API.postWithAuth('/adminapi/feedBack/deleteFeedBack',{
                    id:item.id,
                    userUuid:item.userUuid
                })
                if(res.err === 0){
                    message.success("删除成功")
                    if(actionRef.current){
                        actionRef.current.reload()
                    }
                }else{
                    message.error("删除失败")
                }
            },
            onCancel() {
                console.log('Cancel');
            },
        });

    }
    const  getAllDevice = async (param: any) => {
        let rsp = await API.postWithAuth('/adminapi/feedBack/getFeedBackList', {
            ...param,
            page: param.current,
            size: param.pageSize,
            phone:param.phone,
            feeBack:param.feeBack,
            startTime:startDay!==''&&startDay!==undefined?moment(startDay).format("YYYY-MM-DD"):'' ,
            endTime:endDay!==''&&endDay!==undefined?moment(endDay).format("YYYY-MM-DD"):''
        });
        if (rsp.err === 0) {
            return {
                data: rsp.data,
                total: rsp.total
            };
        } else {
            return {
                data: [],
                total: 0
            };
        }
    };

    return(<>
        <GSTable actionRef={actionRef}  columns={columns} search={true} request={(params:any)=>{return getAllDevice(params)}}/>
    </>)
}
export default FeedBackList