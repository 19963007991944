import * as React from 'react';
import { Route, Switch, NavLink, Redirect} from 'react-router-dom';
import { Menu } from 'antd';
import CompanyList from "./CompanyList";


class ProjectManage extends React.Component {

    state: any;
    constructor(props: any) {
        super(props);
        this.state = {url: window.location.pathname, current: 'company-manager'};
    }

    handleClick = (e: any) => {
        console.log('click ', e);
        this.setState({
            current: e.key,
        });
    }

    render() {
        return (
            <div>
                <div role="navigation">
                    <div className="container-fluid">
                        <Menu
                            onClick={this.handleClick}
                            selectedKeys={[this.state.current]}
                            mode="horizontal"
                            style={{textAlign: 'left'}}
                        >
                            <Menu.Item key="company-manager">
                                <NavLink to={'/company/company-manager'}>公司</NavLink>
                            </Menu.Item>
                        </Menu>
                    </div>
                </div>
                <div className="content-big">
                    <Switch>
                        <Route path="/company/company-manager" component={CompanyList} />
                        <Redirect to="/company/company-manager" />
                        <Route render={() => <CompanyList/>} />
                    </Switch>
                </div>

            </div>
        );
    }
}

export default ProjectManage;