import * as React from 'react';
import { Route, Switch, NavLink, Redirect} from 'react-router-dom';
import { Menu } from 'antd';
import BlueDeviceList from './BlueDeviceList';


class BlueDeviceManage extends React.Component {

    state: any;
    constructor(props: any) {
        super(props);
        this.state = {url: window.location.pathname, current: 'lanya-device-manager'};
    }

    handleClick = (e: any) => {
        console.log('click ', e);
        this.setState({
            current: e.key,
        });
    }

    render() {
        return (
            <div>
                <div role="navigation">
                    <div className="container-fluid">
                        <Menu
                            onClick={this.handleClick}
                            selectedKeys={[this.state.current]}
                            mode="horizontal"
                            style={{textAlign: 'left'}}
                        >
                            <Menu.Item key="lanya-device-manager">
                                <NavLink to={'/lanya-device/lanya-device-manager'}>蓝牙设备</NavLink>
                            </Menu.Item>
                        </Menu>
                    </div>
                </div>
                <div className="content-big">
                    <Switch>
                        <Route path="/lanya-device/lanya-device-manager" component={BlueDeviceList} />
                        <Redirect to="/lanya-device/lanya-device-manager" />
                        <Route render={() => <BlueDeviceList/>} />
                    </Switch>
                </div>

            </div>
        );
    }
}

export default BlueDeviceManage;