import React, { useEffect, useRef, useState } from "react";
import moment from 'moment'
import {
    Col,
    Form,
    Input,
    InputNumber,
    message,
    Modal,
    Row,
    Select,
    Switch,
    Menu,
    Button,
    TimePicker,
    Space
} from "antd";
import {PlusOutlined} from '@ant-design/icons'
import { FormInstance } from "antd/es/form";
import GSTable from "../../components/GSTable";
import { ActionType } from "@ant-design/pro-table";
import API from "../../data/API";
import CustomUploadImage from '../../components/CustomUploadImage';
import { sendAdminKey } from "../../services/FaceDeviceService";
let projectMnum: any = {};
const BlueDeviceList:React.FC = () =>{
    const typeCodeEnum: any = {
        '0201': '蓝牙门禁',
        '0111': '智能锁',
        '0113': '联网锁',
        '0601': '蓝牙门锁',
        '0701': '人脸识别门禁',
        '0801': '联网门禁',
        '0901': '玻璃门智能锁',
        '1001': '智能垃圾桶'
    };
    useEffect(()=>{
        getProject()
    },[])
    const [project,setProject] = useState([])
    const FormItem = Form.Item;
    const [faceForm] = Form.useForm<FormInstance>()
    const [form] = Form.useForm<FormInstance>()
    const Option = Select.Option
    const actionRef = useRef<ActionType>()
    const [paramsVisible,setParamsVisible] = useState(false)
    const [updateVisible,setUpdateVisible] = useState(false)
    const [menuKey, setMenuKey] = useState<any>("1");
    let [fileList, setFileList] = useState<any[]>([]);
    const [faceDeviceName, setFaceDeviceName] = useState("");
    const [sendVisible, setSendVisible] = useState(false)
    const [deviceId, setDeviceId] = useState("")
    const [phone, setPhone] = useState("")
    const  columns = [{
        title: '设备名称',
        dataIndex: 'description',
        key: 'description',
        render: (text: string, record: any) => {
            return <a onClick={()=>{showModel(record)}}>{text}</a>;
        }
    }, {
        title: '设备ID',
        dataIndex: 'deviceid',
        key: 'deviceid',
        hideInSearch: true
    }, {
        title: '设备类型',
        dataIndex: 'typecode',
        key: 'typecode',
        valueEnum: typeCodeEnum
    }, {
        title: '物业项目',
        dataIndex: 'wuyeid',
        valueType: 'select',
        valueEnum: projectMnum,
        renderFormItem: () => {
            return <Select
                allowClear
                placeholder={'请选择'}>
                {project.map((item:any)=>{
                    return(
                        <Option value={item.uuid}>{item.name}</Option>
                    )
                })}
            </Select>;
        }
    }, {
        title: '设备电量',
        hideInSearch: true,
        dataIndex: 'devicebattery',
        key: 'devicebattery',
    }, {
        title: '设备型号',
        hideInSearch: true,
        dataIndex: 'devicemodel',
        key: 'devicemodel',
    }, {
        title: '固件版本',
        hideInSearch: true,
        dataIndex: 'deviceversion',
        key: 'deviceversion',
    }, {
        title: '是否需要绑定',
        dataIndex: 'isuseneedreceive',
        hideInSearch: true,
        hideInTable: true,
        renderFormItem: () => {
            return <Switch/>;
        }
    }, {
        title: '是否需要绑定IMSI卡',
        dataIndex: 'needbindimsi',
        hideInSearch: true,
        hideInTable: true,
        renderFormItem: () => {
            return <Switch/>;
        }
    }, {
        title: '是否需要绑定手机',
        dataIndex: 'needbindphone',
        hideInSearch: true,
        hideInTable: true,
        renderFormItem: () => {
            return <Switch/>;
        }
    }, {
        title: '是否需要实名认证',
        dataIndex: 'needusercertified',
        hideInSearch: true,
        hideInTable: true,
        renderFormItem: () => {
            return <Switch/>;
        }
    }, {
        title: '小程序是否可用',
        dataIndex: 'xcxavailable',
        hideInSearch: true,
        hideInTable: true,
        renderFormItem: () => {
            return <Switch/>;
        }
    }, {
        title: '绑定次数',
        dataIndex: 'bindnum',
        hideInSearch: true,
        hideInTable: true,
        valueType: 'digit'

    },
        {
            title: '添加时间',
            dataIndex: 'createdtime',
            hideInSearch: true,
            // hideInTable: true,
            hideInForm:true,
            render: (text: string, record: any) => {
                return <span>{moment(record.createdtime).format('YYYY-MM-DD HH:mm:ss')}</span>;
            }
        },
        {
            title: '操作',
            dataIndex: 'active',
            render: (text: string, item: any) => {
                return <Space>
                    <a onClick={() => {
                        setSendVisible(true)
                        setDeviceId(item.deviceid)
                    }}>发送钥匙</a>
                </Space>
            }
        }
    ];
    const showModel = (record: any) => {
        console.log('record:::::', record);
        if (record.typecode === '0701') {
            setFaceDeviceName(record.faceDeviceName)
            faceForm.setFieldsValue({...record, restarttime: moment(record.restarttime || "00:00", "HH:mm")})
            setParamsVisible(true)
        } else {
            console.log("form::",form)
            form.setFieldsValue({...record})
            setUpdateVisible(true)
        }
    };

    const getProject = () => {
        API.postWithAuth('/api/wuye/get_wuye_project', {}).then(res => {
            if (res.err === 0) {
                console.log('获得物业列表', res);

                res.data.map((item: any) => {
                    projectMnum[item.uuid] = item.name;
                });
                setProject(res.data)
            }
        });
    };
    const  getAllDevice = async (param: any) => {
        let rsp = await API.postWithAuth('/admin/api/device/getDeviceDList', {
            ...param,
            page: param.current,
            size: param.pageSize
        });
        if (rsp.err === 0) {
            return {
                data: rsp.data,
                total: rsp.count
            };
        } else {
            return {
                data: [],
                total: 0
            };
        }
    };
    const   updateDevice =  () => {
        form.validateFields().then(async (data: any) => {
            let rsp = await API.postWithAuth('/admin/api/device/updateDeviceDList', data);
            setUpdateVisible(false)
            if (rsp.err === 0) {
                message.success(rsp.msg);
                if(actionRef.current){
                    actionRef.current.reload()
                }
            } else {
                message.error(rsp.msg);
            }
        })

    };
    const  setFaceParams = () => {
        faceForm.validateFields().then(async (data: any) => {
            console.log("data::::::", data);
            // if (!data.online) {
            //     message.warn("该设备处于离线状态，无法设置参数");
            //     return;
            // }
            let params = {};

            // let params = {
            //     deviceId: data.deviceid,
            //     threshold: data.threshold,
            //     isVerifyLive: data.isverifylive,
            //     livenessScore: 0,
            //     isRestart: data.isrestart,
            //     recognitionMode: data.isverifylive ? 1 : 0,
            //     restartTime: data.restarttime.format("HH:mm"),
            //     companyName: data.companyname,
            //     minimumFace: data.minimumface,
            //     keepScreenOn: data.keepscreenon,
            //     isTemperatureMeasurement: data.istemperaturemeasurement,
            //     isHealth: data.ishealth,
            //     activeType: data.activetype,
            //     temperatureScore: data.temperaturescore,
            //     deviceName: data.description
            // };
            if (data.threshold != undefined) {
                params = {
                    ...params,
                    threshold: data.threshold,
                    livenessScore: data.threshold / 100.0
                };
            }
            if (data.isverifylive != undefined) {
                params = {
                    ...params,
                    recognitionMode: data.isverifylive ? 1 : 0
                };
            }
            if (data.restarttime != undefined) {
                params = {
                    ...params,
                    restartTime: data.restarttime.format("HH:mm")
                };
            }
            if (data.isrestart != undefined) {
                params = {
                    ...params,
                    isRestart: data.isrestart
                };
            }
            if (data.companyname != undefined) {
                params = {
                    ...params,
                    companyName: data.companyname
                };
            }
            if (data.minimumface != undefined) {
                params = {
                    ...params,
                    minimumFace: data.minimumface
                };
            }
            if (data.recognitionmode != undefined ) {
                params = {
                    ...params,
                    recognitionMode: data.recognitionmode
                };
            }
            if (data.keepcreenson != undefined ) {
                params = {
                    ...params,
                    keepScreenOn: data.keepcreenson
                };
            }
            if (data.istemperaturemeasurement != undefined ) {
                params = {
                    ...params,
                    isTemperatureMeasurement: data.istemperaturemeasurement
                };
            }
            if (data.ishealth != undefined ) {
                params = {
                    ...params,
                    isHealth: data.ishealth
                };
            }
            if (data.activetype != undefined ) {
                params = {
                    ...params,
                    activeType: data.activetype
                };
            }
            if (data.temperaturescore != undefined ) {
                params = {
                    ...params,
                    temperatureScore: data.temperaturescore
                };
            }
            if (data.description != undefined ) {
                params = {
                    ...params,
                    deviceName: data.description
                };
            }
            if (data.successtips != undefined ) {
                params = {
                    ...params,
                    successTips: data.successtips
                };
            }
            if (data.wenduyichangshifoutongguo != undefined ) {
                params = {
                    ...params,
                    wenDuYiChangShiFouTongGuo: data.wenduyichangshifoutongguo
                };
            }
            if (data.jiankangmayichangshifoutongguo != undefined ) {
                params = {
                    ...params,
                    jianKangMaYiChangShiFouTongGuo: data.jiankangmayichangshifoutongguo
                };
            }
            if (data.openlock != undefined ) {
                params = {
                    ...params,
                    openLock: data.openlock
                };
            }
            if (data.logo != undefined ) {
                params = {
                    ...params,
                    logo: data.logo
                };
            }
            if (data.password != undefined ) {
                params = {
                    ...params,
                    password: data.password
                };
            }
            if (data.qualitycontrol != undefined ) {
                params = {
                    ...params,
                    qualityControl: data.qualitycontrol
                };
            }
            if (data.blur != undefined ) {
                params = {
                    ...params,
                    blur: data.blur
                };
            }
            if (data.illumination != undefined ) {
                params = {
                    ...params,
                    illumination: data.illumination
                };
            }
            if (data.pitch != undefined ) {
                params = {
                    ...params,
                    pitch: data.pitch
                };
            }
            if (data.roll != undefined ) {
                params = {
                    ...params,
                    roll: data.roll
                };
            }
            if (data.yaw != undefined ) {
                params = {
                    ...params,
                    yaw: data.yaw
                };
            }
             if (data.lefteye != undefined ) {
                params = {
                    ...params,
                    leftEye: data.lefteye
                };
            }
            if (data.righteye != undefined ) {
                params = {
                    ...params,
                    rightEye: data.righteye
                };
            }
            if (data.nose != undefined ) {
                params = {
                    ...params,
                    nose: data.nose
                };
            }
            if (data.mouth != undefined ) {
                params = {
                    ...params,
                    mouth: data.mouth
                };
            }
            if (data.leftcheek != undefined ) {
                params = {
                    ...params,
                    leftCheek: data.leftcheek
                };
            }
            if (data.rightcheek != undefined ) {
                params = {
                    ...params,
                    rightCheek: data.rightcheek
                };
            }
            if (data.chincontour != undefined ) {
                params = {
                    ...params,
                    chinContour: data.chincontour
                };
            }
            if (data.signaltime != undefined) {
                params = {
                    ...params,
                    signalTime: data.signaltime
                };
            }
            if (data.offlineautoclosehealth != undefined) {
                params = {
                    ...params,
                    offlineAutoCloseHealth: data.offlineautoclosehealth
                };
            }
            if (data.uploaddetectrecord != undefined) {
                params = {
                    ...params,
                    uploadDetectRecord: data.uploaddetectrecord
                };
            }
            console.log("params::::::", params);
            let rsp = await API.postWithAuth('/api/op/set_face_device_params', params);
            if (rsp.err === 0) {
                message.success(rsp.msg);
                if (actionRef.current) {
                    actionRef.current.reload();
                }
            } else {
                message.error(rsp.msg);
            }
            setParamsVisible(false)
        });
    }
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };
    const uploadButton = (
        <div>
            <PlusOutlined/>
            <div style={{ marginTop: 8 }}>上传</div>
        </div>
    );
    const handleChange = (list: any) => {
        if (list.file.status === "done") {
            setFileList(list.fileList);
            console.log(`${list.file.name}`);
            console.log("企业图标", list.file.response.url, list.file.response.data[0]);
            let data: any = {logo: list.file.response.data[0]}
            faceForm.setFieldsValue(data);
        } else if (list.file.status === "error") {
            message.error(`${list.file.name} 上传文件失败`)
        } else if (list.file.status === "removed") {
            fileList = fileList.filter((item: any) => item.status !== "removed");
            setFileList([]);
        }
    };
    const render = () => (fileList.length >= 1 ? null : uploadButton);
    const renderFaceDeviceSetting = (e: string) => {
        switch (e) {
            case "1":
                return <>
                    <Row className="margin-top--10" gutter={16} justify={"start"} align={"top"}>
                        <Col span={10}>
                            <FormItem label={"设备名称"} name="description" rules={[{ required: true }]}>
                                <Input style={{ width: "100%" }} placeholder="请输入"/>
                            </FormItem>
                        </Col>
                        <Col span={10}>
                            <FormItem
                                label={"设备密码"}
                                name="password"
                                rules={[{ required: true }]}
                            >
                                <Input/>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row className="margin-top--10" gutter={16} justify={"start"} align={"top"}>
                        <Col span={10}>
                            <FormItem
                                valuePropName={"checked"}
                                label={"定时重启"}
                                name="isrestart"
                                rules={[{ required: true }]}
                            >
                                <Switch/>
                            </FormItem>
                        </Col>
                        <Col span={10}>
                            <FormItem label={"重启时间"} name="restarttime" rules={[{ required: true }]}>
                                <TimePicker format={"HH:mm"}/>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row className="margin-top--10" gutter={16} justify={"start"} align={"middle"}>
                        <Col span={10}>
                            <FormItem label={"公司名称"} name="companyname" rules={[{ required: true }]}>
                                <Input/>
                            </FormItem>
                        </Col>
                        <Col span={10}>
                            <FormItem valuePropName={"checked"} label={"logo"} name="logo">
                                <CustomUploadImage
                                    key={new Date().getTime()}
                                    cdn={"public"}
                                    listType="picture-card"
                                    defaultFileList={fileList.length > 0 ? fileList : null}
                                    onChange={handleChange}
                                    render={render()}
                                />
                            </FormItem>
                        </Col>
                    </Row>
                    <Row className="margin-top--10" gutter={16} justify={"start"} align={"top"}>
                        <Col span={10}>
                            <FormItem valuePropName={"checked"} label={"是否上传检测日志"} name="uploaddetectrecord" >
                                <Switch/>
                            </FormItem>
                        </Col>
                        <Col span={10}>
                            <FormItem label={"通过提示音"} name="successtips" rules={[{ required: true }]}>
                                <Input/>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row className="margin-top--10" gutter={16} justify={"start"} align={"middle"}>
                        <Col span={10}>
                            <Button type={"primary"} onClick={setFaceParams}>保存</Button>
                        </Col>
                    </Row>
                </>;
            case "2":
                return <>
                    <Row className="margin-top--10" gutter={16} justify={"start"} align={"top"}>
                        <Col span={12}>
                            <FormItem label={"识别模式"} name="activetype" rules={[{ required: true }]}>
                                <Select>
                                    <Select.Option value={0}
                                                   disabled={faceDeviceName !== "GW-S8TC"}>人证识别</Select.Option>
                                    <Select.Option value={1}>人脸识别</Select.Option>
                                    <Select.Option value={2}
                                                   disabled={faceDeviceName !== "GW-S8TC"}>人证或人脸</Select.Option>
                                    <Select.Option value={3}
                                                   disabled={faceDeviceName !== "GW-S8TC"}>人脸+人证</Select.Option>
                                </Select>
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem
                                valuePropName={"checked"}
                                label={"活体检测"}
                                name="isverifylive"
                                rules={[{ required: true }]}
                            >
                                <Switch/>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row className="margin-top--10" gutter={16} justify={"start"} align={"top"}>
                        <Col span={12}>
                            <FormItem label={"识别阈值"} name="threshold" rules={[{ required: true }]}>
                                <InputNumber min={50} max={100} step={5}/>
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem label={"最小人脸"} name="minimumface" rules={[{ required: true }]}>
                                <InputNumber min={30} max={100} step={5}/>
                            </FormItem>
                        </Col>
                    </Row>

                    <Row className="margin-top--10" gutter={16} justify={"start"} align={"top"}>
                        <Col span={12}>
                            <FormItem
                                valuePropName={"checked"}
                                label={"关联开启门禁"}
                                tooltip={'开启后，识别成功后将联动输出开门信号'}
                                name="openlock"
                                rules={[{ required: true }]}
                            >
                                <Switch/>
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem
                                label={"信号时间(秒)"}
                                tooltip={'开门信号输出时间'}
                                name="signaltime"
                                rules={[{ required: true }]}
                            >
                                <InputNumber min={1} step={1}  />
                            </FormItem>
                        </Col>
                    </Row>
                    <Row className="margin-top--10" gutter={16} justify={"start"} align={"top"}>
                        <Col span={12}>
                            <FormItem
                                valuePropName={"checked"}
                                label={"健康宝验证"}
                                name="ishealth"
                                rules={[{ required: true }]}
                            >
                                <Switch/>
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem
                                valuePropName={"checked"}
                                label={"无网络关闭健康码验证"}
                                name="offlineautoclosehealth"
                                rules={[{ required: true }]}
                            >
                                <Switch/>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row className="margin-top--10" gutter={16} justify={"start"} align={"top"}>
                        <Col span={12}>
                            <FormItem
                                valuePropName={"checked"}
                                label={"健康码异常可通过"}
                                name="jiankangmayichangshifoutongguo"
                                rules={[{ required: true }]}
                            >
                                <Switch/>
                            </FormItem>
                        </Col>
                    </Row>

                    <Row className="margin-top--10" gutter={16} justify={"start"} align={"top"}>
                        <Col span={12}>
                            <FormItem
                                valuePropName={"checked"}
                                label={"测温"}
                                tooltip={'仅对测温版设备有效，开启后识别同时将测温'}
                                name="istemperaturemeasurement"
                                rules={[{ required: true }]}
                            >
                                <Switch/>
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem label={"正常温度阈值"} name="temperaturescore" rules={[{ required: true }]}>
                                <InputNumber min={35} max={38} step={0.1}/>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row className="margin-top--10" gutter={16} justify={"start"} align={"top"}>
                        <Col span={12}>
                            <FormItem
                                valuePropName={"checked"}
                                label={"体温异常可通过"}
                                name="wenduyichangshifoutongguo"
                                rules={[{ required: true }]}
                            >
                                <Switch/>
                            </FormItem>
                        </Col>

                    </Row>
                    <Row className="margin-top--10" gutter={16} justify={"start"} align={"top"}>
                        <Col span={12}>
                            <Button type={"primary"} onClick={setFaceParams}>保存</Button>
                        </Col>
                    </Row>
                </>;
            case "3":
                return <>
                    <Row className="margin-top--10" gutter={16} justify={"start"} align={"top"}>
                        <Col span={24}>
                            <FormItem
                                valuePropName={"checked"}
                                label={"质量检测"}
                                tooltip={'开启质量检测后，对抓拍人脸质量要求较高，请谨慎开启'}
                                name="qualitycontrol"
                                rules={[{ required: true }]}
                            >
                                <Switch/>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row className="margin-top--10" gutter={16} justify={"start"} align={"top"}>
                        <Col span={12}>
                            <FormItem
                                label={"模糊度阈值"}
                                name="blur"
                                tooltip={"人脸图片的模糊度范围，取值范围[0~1]，0 是最清晰，1 是最模糊。可根据场景要求适当减小数值，减少模糊图片的采集情况"}
                                rules={[{ required: true }]}
                            >
                                <InputNumber min={0} max={1} step={0.1}/>
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem
                                label={"光照阈值"}
                                tooltip={"人脸面部的光线要求，取值范围[0~255]，脸部光照的灰度值，0 表示光照不好。对应 SDK 中，YUV 的 Y 分量"}
                                name="illumination"
                                rules={[{ required: true }]}
                            >
                                <InputNumber min={0} max={255} step={1}/>
                            </FormItem>
                        </Col>

                    </Row>
                    <Row className="margin-top--10" gutter={16} justify={"start"} align={"top"}>
                        <Col span={12}>
                            <FormItem
                                label={"三维旋转之俯仰角度"}
                                name="pitch"
                                rules={[{ required: true }]}
                            >
                                <InputNumber min={-90} max={90} step={1}/>
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem
                                label={"三维旋转之左右旋转角"}
                                name="yaw"
                                rules={[{ required: true }]}
                            >
                                <InputNumber min={-90} max={90} step={1}/>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row className="margin-top--10" gutter={16} justify={"start"} align={"top"}>
                        <Col span={24}>
                            <FormItem
                                label={"平面内旋转角度"}
                                name="roll"
                                rules={[{ required: true }]}
                            >
                                <InputNumber min={-90} max={90} step={1}/>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row className="margin-top--10" gutter={16} justify={"start"} align={"top"}>
                        <Col span={12}>
                            <FormItem
                                label={"左眼被遮挡阈值"}
                                tooltip={"左右眼睛、左右脸颊、鼻子、嘴巴、下巴的遮挡情况，取值范围 [0~1]，0 为无遮挡，1 是完全遮挡。反映采集的人脸图片可接受的各部位的遮挡情况。"}
                                name="lefteye"
                                rules={[{ required: true }]}
                            >
                                <InputNumber min={0} max={1} step={0.1}/>
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem
                                tooltip={"左右眼睛、左右脸颊、鼻子、嘴巴、下巴的遮挡情况，取值范围 [0~1]，0 为无遮挡，1 是完全遮挡。反映采集的人脸图片可接受的各部位的遮挡情况。"}
                                label={"右眼被遮挡阈值"}
                                name="righteye"
                                rules={[{ required: true }]}
                            >
                                <InputNumber min={0} max={1} step={0.1}/>
                            </FormItem>
                        </Col>
                    </Row>

                    <Row className="margin-top--10" gutter={16} justify={"start"} align={"top"}>
                        <Col span={12}>
                            <FormItem
                                label={"鼻子被遮挡阈值"}
                                tooltip={"左右眼睛、左右脸颊、鼻子、嘴巴、下巴的遮挡情况，取值范围 [0~1]，0 为无遮挡，1 是完全遮挡。反映采集的人脸图片可接受的各部位的遮挡情况。"}
                                name="nose"
                                rules={[{ required: true }]}
                            >
                                <InputNumber min={0} max={1} step={0.1}/>
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem
                                label={"嘴巴被遮挡阈值"}
                                tooltip={"左右眼睛、左右脸颊、鼻子、嘴巴、下巴的遮挡情况，取值范围 [0~1]，0 为无遮挡，1 是完全遮挡。反映采集的人脸图片可接受的各部位的遮挡情况。"}
                                name="mouth"
                                rules={[{ required: true }]}
                            >
                                <InputNumber min={0} max={1} step={0.1}/>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row className="margin-top--10" gutter={16} justify={"start"} align={"top"}>
                        <Col span={12}>
                            <FormItem
                                label={"左脸颊被遮挡阈值"}
                                tooltip={"左右眼睛、左右脸颊、鼻子、嘴巴、下巴的遮挡情况，取值范围 [0~1]，0 为无遮挡，1 是完全遮挡。反映采集的人脸图片可接受的各部位的遮挡情况。"}
                                name="leftcheek"
                                rules={[{ required: true }]}
                            >
                                <InputNumber min={0} max={1} step={0.1}/>
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem
                                label={"右脸颊被遮挡阈值"}
                                tooltip={"左右眼睛、左右脸颊、鼻子、嘴巴、下巴的遮挡情况，取值范围 [0~1]，0 为无遮挡，1 是完全遮挡。反映采集的人脸图片可接受的各部位的遮挡情况。"}
                                name="rightcheek"
                                rules={[{ required: true }]}
                            >
                                <InputNumber min={0} max={1} step={0.1}/>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row className="margin-top--10" gutter={16} justify={"start"} align={"top"}>
                        <Col span={24}>
                            <FormItem
                                label={"下巴被遮挡阈值"}
                                tooltip={"左右眼睛、左右脸颊、鼻子、嘴巴、下巴的遮挡情况，取值范围 [0~1]，0 为无遮挡，1 是完全遮挡。反映采集的人脸图片可接受的各部位的遮挡情况。"}
                                name="chincontour"
                                rules={[{ required: true }]}
                            >
                                <InputNumber min={0} max={1} step={0.1}/>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row className="margin-top--10" gutter={16} justify={"start"} align={"top"}>
                        <Col span={12}>
                            <Button type={"primary"} onClick={setFaceParams}>保存</Button>
                        </Col>
                    </Row>
                </>;
            default:
                return <></>;
        }
    };
    return(<>
        <Row gutter={8} justify={'center'} align={'middle'}>
            <Col span={24}>
                <GSTable actionRef={actionRef}  columns={columns} search={true} request={(params:any)=>{return getAllDevice(params)}}/>
            </Col>
        </Row>
        <Modal width={500} title="设置参数"  visible={updateVisible} onCancel={() => {
            setUpdateVisible(false)
        }} onOk={updateDevice}>
            <Form form={form} {...formItemLayout}>
                <FormItem label={'设备名称'} name={'description'}>
                    <Input/>
                </FormItem>
                <FormItem label={'设备ID'} name={'deviceid'}>
                    <Input/>
                </FormItem>
                <FormItem label={'设备类型'} name={'typecode'}>
                    <Select>
                        <Option value={'0201'}>蓝牙门禁</Option>
                        <Option value={'0111'}>智能锁</Option>
                        <Option value={'0113'}>联网锁</Option>
                        <Option value={'0601'}>蓝牙门锁</Option>
                        <Option value={'0701'}>人脸识别门禁</Option>
                        <Option value={'0801'}>联网门禁</Option>
                        <Option value={'0901'}>玻璃门智能锁</Option>
                        <Option value={'1001'}>智能垃圾桶</Option>
                    </Select>
                </FormItem>
                <FormItem label={'物业项目'} name={'wuyeid'}>
                    <Select>
                        {project.map((item:any)=>{
                            return<Option value={item.uuid}>{item.name}</Option>
                        })}
                    </Select>
                </FormItem>
                <FormItem label={'设备电量'} name={'devicebattery'}>
                    <Input/>
                </FormItem>
                <FormItem label={'设备型号'} name={'devicemodel'}>
                    <Input/>
                </FormItem>
                <FormItem label={'固件版本'} name={'deviceversion'}>
                    <Input/>
                </FormItem>
                <FormItem label={'是否需要绑定'} name={'isuseneedreceive'} valuePropName={'checked'}>
                    <Switch/>
                </FormItem>
                <FormItem label={'是否需要绑定IMSI卡'} name={'needbindimsi'} valuePropName={'checked'}>
                    <Switch/>
                </FormItem>
                <FormItem label={'是否需要绑定手机'} name={'needbindphone'} valuePropName={'checked'}>
                    <Switch/>
                </FormItem>
                <FormItem label={'是否需要实名认证'} name={'needusercertified'} valuePropName={'checked'}>
                    <Switch/>
                </FormItem>
                <FormItem label={'小程序是否可用'} name={'xcxavailable'} valuePropName={'checked'}>
                    <Switch/>
                </FormItem>
                <FormItem label={'绑定次数'} name={'bindnum'} >
                    <InputNumber min={0}/>
                </FormItem>
            </Form>
        </Modal>

        <Modal visible={paramsVisible} title="设置参数" width={800} onOk={() => {setParamsVisible(false)}}
               onCancel={() => {
                   setParamsVisible(false)
               }} footer={null}>
            <Form form={faceForm}>
                <FormItem hidden={true} name={"deviceid"}/>
                <FormItem hidden={true} name={"online"}/>


                <Row>
                    <Col span={4} style={{ borderRight: "10px solid #f0f2f5" }}>
                        {/*<div style={{padding: '20px 10px 20px 10px', fontSize: 15, color: 'rgba(0, 0, 0, 0.65)', width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{data.projectName}</div>*/}
                        <Menu
                            onClick={(e: any) => {
                                setMenuKey(e.key);
                            }}
                            style={{ width: "100%" }}
                            key={menuKey}
                            defaultSelectedKeys={[...menuKey]}
                        >
                            <Menu.Item key="1">
                                基础设置
                            </Menu.Item>

                            <Menu.Item key="2">
                                识别设置
                            </Menu.Item>
                            <Menu.Item key="3">
                                质量检测
                            </Menu.Item>
                        </Menu>
                    </Col>
                    <Col span={20} style={{ padding: 20 }}>
                        {renderFaceDeviceSetting(menuKey)}
                    </Col>
                </Row>
            </Form>
        </Modal>
        <Modal visible={sendVisible} title={'发送钥匙'} destroyOnClose={true} onCancel={() => {setSendVisible(false)}} onOk={async ()=> {
            let params = {
                deviceId: deviceId,
                phone: phone
            }
           let rsp = await sendAdminKey(params)
            if (rsp.err === 0) {
                message.success('发送成功')
                setSendVisible(false)
            } else {
                message.error(rsp.msg);
            }
            console.log('params', params)
        }} >
            <Row gutter={8} align={"middle"}>
                <Col>
                    手机号：
                </Col>
                <Col>
                    <Input onChange={(e) => {setPhone(e.target.value)}}></Input>
                </Col>
            </Row>

        </Modal>
    </>)
}
export default BlueDeviceList
