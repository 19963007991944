import React, { useEffect, useState } from "react";
import { Line } from "@ant-design/charts";
import moment from "moment";
import { Card, Col, DatePicker, message, Row, Select } from "antd";
import API from "../../../data/API";
let { RangePicker } = DatePicker;
const NewAddUserD: React.FC = () => {
    useEffect(()=>{
        getData()
    },[])

    let startTime = moment().subtract(7, "d").format("YYYY-MM-DD")
    let endTime = moment().format("YYYY-MM-DD")
    const [loading,setLoading] = useState(false)
    const [selectType, setSelectType] = useState(1);
    const [selectState, setSelectState] = useState(1);
    const [data,setData] = useState([])
    const changeSelectType = (e: number) => {
        setSelectType(e);
        console.log(e)
        switch (e) {
            case 1:
                startTime =moment().subtract(7, "d").format("YYYY-MM-DD")
                endTime = moment().format("YYYY-MM-DD")
                setSelectState(1)
                getData()
                break;
            case 2:
                console.log("jinrul2")
                startTime =moment().subtract(14, "d").format("YYYY-MM-DD")
                endTime = moment().format("YYYY-MM-DD")
                setSelectState(1)
                getData()
                break;
            case 4:
                setSelectState(2)
                break;
        }

    };
    const dateTime = (date: any, dataToString: any) => {
        startTime =dataToString[0]
        endTime = dataToString[1]
        if(startTime!=="" && startTime!==undefined && endTime!=="" && endTime!==undefined ){
            getData()
        }
    }
    const getData = async () =>{
        setLoading(true)
        if(startTime === "" || startTime === undefined){
            message.warning("请选择起始时间")
            return;
        }
        if(endTime === "" || endTime === undefined){
            message.warning("请选择结束时间")
            return;
        }

        console.log("startTime",startTime)
        console.log("endTime",endTime)
        const formData:any = {
            startTime:startTime,
            endTime:endTime
        }
        API.postWithAuth('/api/statistice/getAddNewUser' , formData).then(res => {
            if (res.err === 0) {
                setData(res.data)
                console.log(res)
            }
            setLoading(false)
        });


    }

    const tabBarExtrContent = () =>(
        <div style={{display:"flex",justifyContent:'start',alignItems:'center'}}>
            <Select style={{marginLeft:20}} defaultValue={selectType} onChange={changeSelectType}>
                <Select.Option value={1}>最近7天</Select.Option>
                <Select.Option value={2}>最近14天</Select.Option>
                <Select.Option value={4}>自定义</Select.Option>
            </Select>
            <div style={{marginLeft: 20 }} hidden={selectState === 1}>
                <RangePicker
                    format="YYYY-MM-DD"
                    placeholder={['请选择开始时间', '请选择结束时间']}
                    onChange={dateTime}
                />
            </div>

        </div>
    );

    const config:any = {
        data: data,
        xField: 'type',
        yField: 'count',
        seriesField: 'appType',
        label: {
            position: 'middle',
            style: {
                fill: '#FFFFFF',
                opacity: 0.6
            }
        },
        meta: {
            type: { alias: '时间' },
            sales: { alias: '用户' }
        }
    };

    return (
        <Row gutter={16}>
            <Col span={24}>
                <Card
                    loading={loading}
                    title="新增用户趋势-来源 "
                    extra={tabBarExtrContent()}
                    headStyle={{display:'flex',justifyContent:'start',alignItems:'center'}}
                    bodyStyle={{ paddingLeft: 15}}
                >
                    <Line {...config} />
                </Card>
            </Col>
        </Row>

    )

};

export default NewAddUserD;