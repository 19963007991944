import appConfig from '../appConfig';
import axios from 'axios';
let apiRootPath = appConfig.apiRootPath;

class Api {
    static api = new Api();
    pageSize = ['15', '30', '50', '100'];
    userId: any;
    allPerm: Array<any> = [];
    pd: any;
    token: any;
    companyUuid: any;
    wuyeUuid: any;
    userInfo: any;

    public apiRootPath = appConfig.apiRootPath;
    public cdnRootPath = appConfig.cdnRootPath;


    constructor() {
        console.log('运行环境：', process.env.NODE_ENV, appConfig);
        this.userId = localStorage.getItem('userId');
        this.pd = localStorage.getItem('password');
        this.token = localStorage.getItem('token');
        //this.companyUuid = localStorage.getItem('companyUuid');
        //this.wuyeUuid = localStorage.getItem('wyUuid');
        this.getAllPerm();
    }

    public post(uri: String, params: {}) {
        let url = apiRootPath + uri;
        return new Promise<any>((resolve, reject) => {
            axios.post(url, params).then(res => {
                resolve(res.data);
                if (res.data.err === 401) {
                    alert('未登录');
                    localStorage.clear();
                    window.location.href = '/wy/login';
                } else if (res.data.err === 403) {
                    alert('没有权限');
                }
                console.log(res);
            }).catch(err => {
                reject(err.data);
                //msg.suspensionMsg('网络请求异常');
                console.log(err);
            });
        });
    }

    public postWithAuth(uri: String, params: any) {
        let url = apiRootPath + uri;
        params.access_token = this.token;
        return new Promise<any>((resolve, reject) => {
            axios.post(url, params).then(res => {
                resolve(res.data);
                if (res.data.err === 401) {
                    alert('未登录');
                    localStorage.clear();
                    window.location.href = '/wy/login';
                } else if (res.data.err === 403) {
                    alert('没有权限');
                }
                console.log(res);
                console.log(res.data);
            }).catch(err => {
                reject(err.data);
                //msg.suspensionMsg('网络请求异常');
                console.log(err);
            });
        });
    }

    public getWithAuth(uri: String, params: any) {
        let url = this.cdnRootPath + uri;
        return new Promise<any>((resolve, reject) => {
            axios.get(url, params).then(res => {
                resolve(res.data);
                console.log(res);
                console.log(res.data);
            }).catch(err => {
                reject(err.data);
                //msg.suspensionMsg('网络请求异常');
                console.log(err);
            });
        });
    }

    /**
     * 设置用户名密码
     */
    public setUserState(userId: string, password: string, token: string , wyYeShortName: string, wuyeSlogan: string, wyUuid: string, companyUuid: string) {
        console.log('设置用户名' + userId + password);
        localStorage.setItem('userId', userId);
        localStorage.setItem('password', password);
        localStorage.setItem('token', token);
        localStorage.setItem('wyYeShortName', wyYeShortName);
        localStorage.setItem('logintime', new Date().getTime().toString());
        localStorage.setItem('wuyeSlogan', wuyeSlogan);
        //localStorage.setItem('wyUuid', wyUuid);
        //localStorage.setItem('companyUuid', companyUuid);
        this.userId = userId;
        this.pd = password;
        this.token = token;
        this.companyUuid = companyUuid;
        this.wuyeUuid = wyUuid;
        this.getAllPerm();
    }

    /**
     * 是否登陆
     */
    public getLoginState(): boolean {
        let endTime1 = new Date().getTime();
        let loginTime = localStorage.getItem('logintime');
        let free = localStorage.getItem('free');
        console.log('是否免登' + free);
        console.log('登录时间' + loginTime);
        let expirationTime = 2592000000;
        if (free === undefined || free === null ) {
            console.log('没有免登');
            expirationTime = 7200000;
            localStorage.setItem('logintime', endTime1.toString());
        }
        //勾选30 2592000000  1分钟 59999
        console.log('时间', endTime1, loginTime, + expirationTime);
        if (endTime1 - Number(loginTime) > expirationTime) {
            console.log('登陆过期', endTime1 - Number(loginTime));
            localStorage.clear();
            return false;
        }
        return (this.userId != null && this.pd != null && this.token != null);
    }

    getUserInfo(): Promise<any> {
        if (this.userInfo !== null && this.userInfo !== undefined) {
            return new Promise<any>(resolve => {
                resolve(this.userInfo);
            });
        }
        return new Promise<any[]>(resolve => {
           this.postWithAuth('/api/wuye/get_user_info', {
                user_id: Api.api.userId,
                password: Api.api.pd
            }).then(res => {
                console.log('获得请求的值userinfo: ', res.data);
                if (res.err === 0) {
                    this.userInfo = res.data;
                    localStorage.setItem('wyUuid', res.data.wyUuid);
                    resolve(res.data);
                } else {
                    resolve([]);
                }
            });
        });
    }


   getAccessToken(): String {
        return this.token;
    }

    getUserId(): String {
        return this.userId;
    }

    getAllPerm() {
        let localAllPerm = localStorage.getItem('allPerm');
        if (localAllPerm != null && localAllPerm.length>0) {
            this.allPerm = JSON.parse(localAllPerm);
        } else {
           this.postWithAuth('/api/op/get_all_perm', {}).then((res: any) => {
                if (res.err === 0) {

                    this.allPerm = res.data;
                    localStorage.setItem('allPerm', JSON.stringify(res.data));
                }

            });
        }
        return this.allPerm;
    }
    getPermList () {
        this.postWithAuth('/api/op/get_all_perm', {}).then((res: any) => {
            if (res.err === 0) {

                this.allPerm = res.data;
                localStorage.setItem('allPerm', JSON.stringify(res.data));
            }

        });
    }

}

let ApiImp = new Api();
export default ApiImp;
