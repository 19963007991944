import React, {useEffect, useState} from 'react';
import {Col, Row, Select, Divider, message, Form, Button} from 'antd';
import API from '../../data/API';
import {getAdConfig, setAdConfig} from '../../services/OpService';

const FormItem = Form.Item;

const AdManage: React.FC = () => {
    const [project, setProject] = useState([]);
    const [form] = Form.useForm();

    // const [shieldChannelShowAd, setShieldChannelShowAd] = useState('');
    // const [shieldAd, setShieldAd] = useState('');
    useEffect(() => {
        getProject();
        getAdConfigData().then();
    }, []);
    const getProject = () => {
        API.postWithAuth('/api/wuye/get_wuye_project', {}).then(res => {
            if (res.err === 0) {
                console.log('获得物业列表', res);

                setProject(res.data);
            }
        });
    };
    const getAdConfigData = async () => {
        let rsp = await getAdConfig({});
        if (rsp.err === 0) {
            form.setFieldsValue({shieldChannelShowAd: rsp.data.shieldChannelShowAd ? (rsp.data.shieldChannelShowAd || '').split(',') : [],
                                    shieldAd: rsp.data.shieldAd ? (rsp.data.shieldAd || '').split(',') : []});

        } else {
            message.error(rsp.msg);
        }
    };
    const layout = {
        labelCol: {span: 4},
        wrapperCol: {span: 8},
    };
    const onFinish = async (values: any) => {
        console.log('values:', values);
        let data = {
            shieldChannelShowAd: values.shieldChannelShowAd.toString(),
            shieldAd: values.shieldAd.toString()
        };
        let rsp = await setAdConfig(data);
        if (rsp.err === 0) {
            message.success(rsp.msg);
            await getAdConfigData();
        } else {
            message.error(rsp.msg);
        }
    };
    return (
        <div className="content-big" style={{backgroundColor: '#fff'}}>
            <Row align='middle' style={{height: 50}}>
                <Col>
                    广告屏蔽设置
                </Col>
            </Row>
            <Divider type={'horizontal'}/>
            <Form {...layout} form={form} onFinish={onFinish}>
                <FormItem name={'shieldChannelShowAd'} label={'按渠道屏蔽广告'}>
                    <Select mode={'multiple'}>
                        <Select.Option value={'qq'}>应用宝</Select.Option>
                        <Select.Option value={'oppo'}>OPPO应用商店</Select.Option>
                        <Select.Option value={'vivo'}>Vivo应用商店</Select.Option>
                        <Select.Option value={'xiaomi'}>小米应用商店</Select.Option>
                        <Select.Option value={'huawei'}>华为应用商店</Select.Option>
                    </Select>
                </FormItem>
                <FormItem name={'shieldAd'} label={'按物业屏蔽广告'}>
                    <Select mode={'multiple'}>
                        {project.map((item: any) => {
                            return (
                                <Select.Option value={item.uuid}>{item.name}</Select.Option>
                            );
                        })}
                    </Select>
                </FormItem>
                <Form.Item wrapperCol={{offset: 4, span: 8}}>
                    <Button type="primary" htmlType="submit">
                        保存
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};
export default AdManage;