import React, { useRef} from "react";
import GSTable from "../../components/GSTable";
import { ActionType } from "@ant-design/pro-table";
import {
    getIncomeList,
} from "../../services/PartnerService";
import { Col, Row } from "antd";

const PartnerIncomeList:React.FC = () =>{
    const actionRef = useRef<ActionType>()
    const  columns = [{
        title: '合伙人姓名',
        dataIndex: 'userName',
        key: 'userName',
    }, {
        title: '时间',
        dataIndex: 'dateTime',
        key: 'dateTime',
        valueType:'date',
        hideInSearch: true
    }, {
        title: '收益',
        dataIndex: 'income',
        key: 'income',
        valueType:'money',
        hideInSearch: true
    }
    ];
    // @ts-ignore
    const  getList = async (param: any) => {
        let rsp = await getIncomeList({
            ...param,
            page: param.current,
            size: param.pageSize})
        if (rsp.err === 0) {
            return {
                data: rsp.data,
                total: rsp.count
            };
        } else {
            return {
                data: [],
                total: 0
            };
        }
    };
    return(<>
        <Row gutter={8} justify={'center'} align={'middle'}>
            <Col span={24}>
                <GSTable  search={true}  actionRef={actionRef}  columns={columns}  request={(params:any)=>{return getList(params)}}/>
            </Col>
        </Row>

    </>)
}
export default PartnerIncomeList