import * as React from "react";
import Api from "../../data/API";
import { Redirect } from "react-router-dom";
import { Button, Checkbox, Col, Input, message, Row } from "antd";
//var msg = require('../../util/SuspensionMsg');
require("./login.css");

let userId: any;
let password: any;
let phoneCode: any;

class Login extends React.Component {
    state: any;
    constructor(props: any) {
        super(props);
        this.state = {
            obtainCaptche: "获取验证码",
            value: "",
            isShow: false,
            height: 33,
            showLoadingIcon: false,
            isLoggedOn: Api.getLoginState(),
            redirectTo: "/qiye-guanli/employee-guanli",
            captcha: Api.cdnRootPath + "/captcha",
            showLogin: false,
            showRegister: true,
            forget: true,
            expression: "",
            validate: "",
            validateInput: "",
            currentTime: 61,
            codeTag: false,
            code: "",
            text: "",
            logoUrl: "",
            bannerTitle: "",
        };
        this.login = this.login.bind(this);
        this.captche = this.captche.bind(this);
        this.renderCode = this.renderCode.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.Blue = this.Blue.bind(this);
        this.Register = this.Register.bind(this);
        this.userBlur = this.userBlur.bind(this);
        this.SendOut = this.SendOut.bind(this);
        this.forget = this.forget.bind(this);
        this.GoLogin = this.GoLogin.bind(this);
        this.CodeBlur = this.CodeBlur.bind(this);
        this.GoRegister = this.GoRegister.bind(this);
        this.Retrieve = this.Retrieve.bind(this);
        //this.callback = this.callback.bind(this);
    }

    Register() {
        this.setState({
            showLogin: true,
            showRegister: false,
            forget: true,
        });
    }

    GoLogin() {
        this.setState({
            showLogin: false,
            showRegister: true,
            forget: true,
        });
    }

    componentDidMount() {
        this.renderCode();
        //lgymove.tuodong('drag', this.callback);
    }

    // callback(text: any) {
    //     this.setState({
    //         text: text
    //     });
    // }

    captche() {
        let data = new Date();
        this.setState({
            captcha: Api.cdnRootPath + "/captcha?" + data.getTime(),
        });
    }

    componentWillMount() {
        console.log("域名", window.location.hostname);

        let height = window.innerHeight;
        this.setState({
            height: height,
        });
        this.setState({
            logoUrl: require("../../img/logo-guosim.png"),
            companyName: "",
            bannerTitle: "智慧社区",
        });

        let search = window.location.search;
        console.log("search=", search);
        let token = search.split("token=")[1];
        if (token !== undefined) {
            Api.post("/api/user/token_sign_in", {
                access_token: token,
            }).then((res) => {
                console.log("登陆结果", res);
                this.setState({
                    isShow: false,
                });
                if (res.err === 0) {
                    Api.setUserState(
                        "",
                        "",
                        res.token,
                        res.wyYeShortName,
                        res.wuyeSlogan,
                        res.wyUuid,
                        res.companyUuid
                    );
                } else {
                    message.error(res.msg);
                }
            });
        }
    }

    loadingIcon() {
        if ((this.state as any).showLoadingIcon) {
            return <i className="icon icon-spin icon-spinner" />;
        } else {
            return <span />;
        }
    }

    render() {
       
        return Api.getLoginState() ? (
            <Redirect to={{ pathname: "/home" }} />
        ) : (
                <>
                <style>{`
            .ant-col {
                padding: 8px;
            }
        
        `}</style>
            <div
                className="login-bg"
                style={{
                    height: (this.state as any).height,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <div className="login-div-bg">
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#FFFFFF",
                            fontSize: 30,
                            paddingTop: 20,
                        }}
                    >
                        GRock运营管理中心
                    </div>
                    {/*登录*/}
                    <div hidden={(this.state as any).showLogin}>
                        <div style={{ padding: "20px 40px 40px 40px" }}>
                            <Row gutter={[16, 16]}>
                                <Col flex={1}>
                                    <Input
                                        name="userId"
                                        onInput={this.userNumber}
                                        placeholder="账号"
                                    />
                                </Col>
                            </Row>

                            <Row gutter={[16, 16]}>
                                <Col flex={1}>
                                    <Input
                                        type="password"
                                        name="password"
                                        onInput={this.passwordNumber}
                                        placeholder="密码"
                                    />
                                </Col>
                            </Row>

                            <Row gutter={[16, 16]}>
                                <Col flex={4}>
                                    <Input
                                        value={
                                            (this.state as any).validateInput
                                        }
                                        placeholder="请输入验证码"
                                        onBlur={this.Blue}
                                        onInput={this.handleChange}
                                    />
                                </Col>
                                <Col flex={1}>
                                    <Button
                                        style={{ width: "100%" }}
                                        onClick={this.renderCode}
                                    >
                                        {(this.state as any).expression}
                                    </Button>
                                </Col>
                            </Row>

                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <div>
                                    <Checkbox
                                        style={{ color: "#fff" }}
                                        name={"free"}
                                    >
                                        30天免登录
                                    </Checkbox>
                                </div>
                                <div>
                                    <a
                                        style={{ color: "#fff" }}
                                        onClick={this.forget}
                                    >
                                        忘记密码?
                                    </a>
                                </div>
                            </div>
                            <Row gutter={[16, 16]} style={{ marginTop: 10 }}>
                                <Col flex={1}>
                                    <Button
                                        style={{
                                            width: "100%",
                                            background: "#1890FF",
                                            border: "1px solid #707070",
                                            color: "#fff",
                                        }}
                                        onClick={this.login}
                                    >
                                        {this.loadingIcon()}
                                        登录
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div hidden={(this.state as any).forget}>
                        <div style={{ padding: "20px 40px 40px 40px" }}>
                            <Row gutter={[16, 16]}>
                                <Col flex={1}>
                                    <Input
                                        name="userId"
                                        onInput={this.userNumber}
                                        placeholder="登录账号/手机号"
                                    />
                                </Col>
                            </Row>

                            <Row gutter={[16, 16]}>
                                <Col span={10}>
                                    <Input
                                        value={
                                            (this.state as any).validateInput
                                        }
                                        placeholder="请输入验证码"
                                        onBlur={this.Blue}
                                        onInput={this.handleChange}
                                    />
                                </Col>
                                <Col span={6}>
                                    <Button onClick={this.renderCode}>
                                        {" "}
                                        {(this.state as any).expression}
                                    </Button>
                                </Col>
                                <Col span={8}>
                                    <Button
                                        disabled={(this.state as any).codeTag}
                                        onClick={this.SendOut}
                                    >
                                        {/*获取验证码*/}
                                        {(this.state as any).obtainCaptche}
                                    </Button>
                                </Col>
                            </Row>
                            <Row gutter={[16, 16]}>
                                <Col flex={1}>
                                    <Input
                                        onBlur={this.CodeBlur}
                                        name="phoneCode"
                                        onInput={this.phoneCode}
                                        placeholder="请输入收到的验证码"
                                    />
                                </Col>
                            </Row>
                            <Row gutter={[16, 16]}>
                                <Col flex={1}>
                                    <Input
                                        type="password"
                                        name="password"
                                        onInput={this.passwordNumber}
                                        placeholder="设置密码"
                                    />
                                </Col>
                            </Row>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <div></div>
                                <div>
                                    <a
                                        style={{ color: "#fff" }}
                                        onClick={this.GoLogin}
                                    >
                                        去登录
                                    </a>
                                </div>
                            </div>

                            <Row gutter={[16, 16]} style={{ marginTop: 10 }}>
                                <Col flex={1}>
                                    <Button
                                        style={{
                                            width: "100%",
                                            background: "#1890FF",
                                            border: "1px solid #707070",
                                            color: "#fff",
                                        }}
                                        onClick={this.Retrieve}
                                    >
                                        找回密码
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    {/*找回密码*/}
                    <div hidden={true}>
                        <Row gutter={[16, 16]}>
                            <Col flex={1}>
                                <input
                                    className=" form-control-home "
                                    name="userId"
                                    onInput={this.userNumber}
                                    placeholder="登录账号/手机号"
                                />
                            </Col>
                        </Row>
                        <div className="login-div-count-div login-div-count-div-captcha">
                            {/*<input className=" form-control-home-captcha " name="captche" onInput={this.captcheNumber} placeholder="输入验证码" />
                                    <img className="form-control-captcha"  onClick={this.captche} src={(this.state as any).captcha}/>*/}
                            <div className="captcha-input">
                                <input
                                    className=" form-control-home-captcha-ssm "
                                    value={(this.state as any).validateInput}
                                    placeholder="请输入验证码"
                                    onBlur={this.Blue}
                                    onInput={this.handleChange}
                                />
                                <div className="form-control-captcha">
                                    <button
                                        type="button"
                                        className="btn form-control-captcha"
                                        onClick={this.renderCode}
                                    >
                                        {(this.state as any).expression}
                                    </button>
                                </div>
                                <div className="form-control-captcha">
                                    <button
                                        type="button"
                                        disabled={(this.state as any).codeTag}
                                        className="btn form-control-captcha"
                                        onClick={this.SendOut}
                                    >
                                        {/*获取验证码*/}
                                        {(this.state as any).obtainCaptche}
                                    </button>
                                </div>
                            </div>
                            {/*<Captche color={'red'} size={4} captchaType={'Normal'} userId={(this.state as any).userId} password={(this.state as any).password}/>*/}
                        </div>
                        <div className="login-div-count-div">
                            <input
                                className=" form-control-home "
                                onBlur={this.CodeBlur}
                                name="phoneCode"
                                onInput={this.phoneCode}
                                placeholder="请输入收到的验证码"
                            />
                        </div>
                        <div className="login-div-count-div">
                            <input
                                className=" form-control-home "
                                type="password"
                                name="password"
                                onInput={this.passwordNumber}
                                placeholder="设置密码"
                            />
                        </div>
                        <div className="login-div-count-div">
                            <button
                                className="btn-home"
                                onClick={this.Retrieve}
                            >
                                找回密码
                            </button>
                        </div>
                        <div className="login-div-forget">
                            <a onClick={this.GoLogin}>去登录</a>
                        </div>
                    </div>
                </div>
                {/*    </Col>*/}
                {/*</Row>*/}
                {/*<Row justify="center" style={{paddingTop: '10%'}}>*/}
                {/*    <Col>*/}
                {/*        <div className="text">*/}
                {/*            <h3 style={{color: 'white'}}>{(this.state as any).companyName}</h3>*/}
                {/*            /!*<span>关于我们&nbsp;&nbsp;|&nbsp;&nbsp;联系我们</span> <span>官方网站:<a href="http://www.zgcinnotown.com/" target="_blank">www.zgcinnotown.com</a></span> <span>咨询热线：010-62457320</span>*!/*/}
                {/*        </div>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
            </div></>
        );
    }

    //获得账户
    userNumber(ev: any) {
        userId = ev.target.value.toString();
    }

    passwordNumber(ev: any) {
        password = ev.target.value.toString();
    }

    phoneCode(ev: any) {
        phoneCode = ev.target.value.toString();
    }

    forget() {
        userId = "";
        password = "";
        this.setState({
            forget: false,
            showLogin: true,
            showRegister: true,
        });
    }

    CodeBlur() {
        if (phoneCode !== null && phoneCode !== "" && phoneCode !== undefined) {
            if (phoneCode !== (this.state as any).code) {
                message.warning("验证码不正确");
                return;
            }
        }
    }

    SendOut() {
        if (userId === undefined || userId === "") {
            message.warning("请输入账号");
            return;
        }
        if (
            (this.state as any).validateInput === undefined ||
            (this.state as any).validateInput === ""
        ) {
            message.warning("请输入验证码");
            return;
        }
        this.setState({
            codeTag: true,
        });
        let url = "/api/user/saas_register";
        Api.postWithAuth(url, {
            userId: userId,
        }).then((res) => {
            console.log("", res);
            if (res.err === 0) {
                this.setState({
                    code: res.code,
                });
            } else {
                message.warning("验证码发送失败");
                return;
            }
        });
    }

    GoRegister() {
        if (userId !== null && userId !== undefined && userId !== "") {
            let url = "/api/user/saas_user_register";
            // var data = new FormData();
            // data.append('userId', userId);
            // data.append('password', password);
            // this.dal.getNetWorkData(data, url).then(res => {
            //
            // });

            Api.postWithAuth(url, {
                userId: userId,
                password: password,
            }).then((res) => {
                console.log("", res);
                if (res.err === 0) {
                    message.warning("注册成功，请去登录");
                    return;
                } else {
                    message.warning("注册失败");
                    return;
                }
            });
        }
    }

    Retrieve() {
        console.log("userId", userId);
        console.log("password", password);
        console.log("phoneCode", phoneCode);
        if (userId === undefined || userId === null || userId === "") {
            message.warning("请输入手机号或账号");
            return;
        }
        if (password === undefined || password === null || password === "") {
            message.warning("请输入密码");
            return;
        }
        if (phoneCode === undefined || phoneCode === null || phoneCode === "") {
            message.warning("请输入手机验证码");
            return;
        }

        if (userId !== undefined && userId !== null) {
            let url = "/api/user/saas_retrieve";
            Api.postWithAuth(url, {
                userId: userId,
                password: password,
            }).then((res) => {
                console.log("", res);
                if (res.err === 0) {
                    message.warning("密码找回成功，请去登录");
                    return;
                } else {
                    message.warning("密码找回失败");
                    return;
                }
            });
        }
    }

    userBlur() {
        if (userId !== undefined || userId !== "") {
            let url = "/api/user/judge_account";
            Api.postWithAuth(url, {
                userId: userId,
            }).then((res) => {
                console.log("", res);
                if (res.err === 0) {
                    message.warning("此账号已经注册");
                } else {
                    return;
                }
            });
        }
    }

    login() {
        if (userId === undefined || userId === "") {
            message.warning("账号异常");
            return;
        }
        if (password === undefined || password === "") {
            message.warning("密码异常");
            return;
        }

        if (
            (this.state as any).validateInput === undefined ||
            (this.state as any).validateInput === ""
        ) {
            message.warning("请输入验证码");
            return;
        }
        var validateInput = (this.state as any).validateInput;
        var expression = (this.state as any).expression;
        if (validateInput.toLowerCase() !== expression.toLowerCase()) {
            message.warning("验证码输入不正确");
            return;
        }

        this.setState({
            isShow: true,
            showLoadingIcon: true,
        });

        let isFree = (document.getElementsByName("free") as any)[0].checked;
        if (isFree) {
            console.log("免登30天");
            localStorage.setItem("free", "true");
            localStorage.setItem("logintime", new Date().getTime().toString());
        }
        Api.post("/api/user/getOpToken", {
            user_id: userId,
            password: password,
        }).then((res) => {
            console.log("登陆结果", res);
            this.setState({
                isShow: false,
            });
            if (res.err === 0) {
                Api.setUserState(
                    userId,
                    password,
                    res.token,
                    res.wyYeShortName,
                    res.wuyeSlogan,
                    res.wyUuid,
                    res.companyUuid
                );
                window.location.replace("/home");
            } else {
                message.warning(res.msg);
            }
        });
    }

    renderCode() {
        //定义expression和result，expression是字符串，result可能是字符串也可能是数字
        var expression = "",
            result;
        //判断验证码类型
        result = "";
        let size = 4;
        var codeNormal =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"; //字母库
        for (var j = 0; j < size; j++) {
            result =
                result +
                codeNormal[Math.round(Math.random() * (codeNormal.length - 1))];
        } //随机获取字母四个
        expression = result.toLowerCase(); //忽略大小写
        this.setState({
            //设置更新状态
            expression: expression,
            validate: result,
        });
    }

    handleChange(ev: any) {
        this.setState({
            validateInput: ev.target.value,
        });
    }

    Blue() {
        var thisInput = (this.state as any).validateInput;
        var validateCode = (this.state as any).expression;
        if (thisInput !== null || thisInput !== "") {
            if (
                thisInput.toLowerCase() ===
                validateCode.toString().toLowerCase()
            ) {
                return "success";
            } else if (thisInput !== "") {
                message.warning("验证码输入错误");
                return "error";
            } else {
                return;
            }
        }
        return;
    }
}

export default Login;

// helpers
