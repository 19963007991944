import * as React from 'react';
import API from '../data/API';
import {Button, Col, Dropdown, Input, Menu, message, Modal, Row} from 'antd';

class MYHeader extends React.Component {

    state: any;
    constructor(props: any) {
        super(props);
        this.state = {
            password: '',
            pwd: '',
            pwds: '',
            userName: '',
            wuyeName: '',
            account: '',
            userHead: '',
            visible: false,
            project: [],
        };
        this.passwordInput = this.passwordInput.bind(this);
        this.pwdInput = this.pwdInput.bind(this);
        this.pwdsInput = this.pwdsInput.bind(this);
        this.updatePwd = this.updatePwd.bind(this);
    }

    componentWillMount() {
        this.getUserInfo();
    }

    getUserInfo = () => {
        API.getUserInfo().then(res => {
            this.setState({
                userName: res.userName,
                wuyeName: res.wyYeName,
                account: res.account,
                userHead: res.userHead,
                project: res.project
            });
        });
    }


    logout() {
        localStorage.clear();
        window.location.href = '/wy/login';
    }

    passwordInput(ev: any) {
        console.log(`原密码${ev.target.value}`);
        this.setState({
            password: ev.target.value
        });
    }

    pwdInput(ev: any) {
        console.log(`密码${ev.target.value}`);
        this.setState({
            pwd: ev.target.value
        });
    }

    pwdsInput(ev: any) {
        console.log(`重复密码${ev.target.value}`);
        this.setState({
            pwds: ev.target.value
        });
    }

    updatePwd() {
        if ((this.state as any).password === undefined || (this.state as any).password === null || (this.state as any).password === '') {
            message.warning('请输入原密码');
            return;
        }
        if ((this.state as any).pwd === undefined || (this.state as any).pwd === null || (this.state as any).pwd === '') {
            message.warning('请输入密码');
            return;
        }
        if ((this.state as any).pwds === undefined || (this.state as any).pwds === null || (this.state as any).pwds === '') {
            message.warning('请输入重复密码');
            return;
        }
        if ((this.state as any).pwds !== (this.state as any).pwd) {
            message.warning('两次密码输入不一致');
            return;
        }
        API.postWithAuth('/api/user/updatePwd', {
            password: (this.state as any).password,
            pwd: (this.state as any).pwd,
            pwds: (this.state as any).pwds
        }).then(res => {
            if (res.err === 0) {
                message.success(res.msg);
            } else {
                message.error(res.msg);
            }
            this.handleCancel();
        });
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    }

    handleOk = () => {
        this.setState({
            visible: false,
        });
    }

    handleCancel = () => {
        this.setState({
            visible: false,
        });
    }

    render() {
        const menu = (
            <Menu>
                <Menu.Item>
                    <a rel="noopener noreferrer" href="#">账号：{(this.state as any).account}</a>
                </Menu.Item>
                <Menu.Item>
                    <a rel="noopener noreferrer" onClick={this.showModal} href="#">修改密码</a>
                </Menu.Item>
                <Menu.Item>
                    <a rel="noopener noreferrer" onClick={this.logout} href="#">退出</a>
                </Menu.Item>
            </Menu>
        );
        return (
            <div>
                <Row style={{ alignItems: 'center', height: '50px'}}>
                    <Col>
                        <Dropdown overlay={menu}>
                            <Button style={{backgroundColor: '#fff', borderRadius: 0, border: 0}}>
                                <img style={{height: '30px', width: '30px', marginRight: '5px',borderRadius:'50%'}} src={(this.state as any).userHead} alt=""/>
                                {(this.state as any).userName}<b className="caret"/>
                            </Button>
                        </Dropdown>
                    </Col>
                </Row>

                <Modal
                    title="修改密码"
                    visible={this.state.visible}
                    onOk={this.updatePwd}
                    onCancel={this.handleCancel}
                >
                    <div className="container">
                        <div className="row updatePwd">
                            <div className="col-xs-4 qiyeguanli-name-num">
                                <label htmlFor="exampleInputAccount1">原密码</label>
                            </div>
                            <div className="col-xs-6 qiyeguanli-name-num-input">
                                <Input type="password" onInput={this.passwordInput} placeholder=""/>
                            </div>
                        </div>
                        <div className="row updatePwd">
                            <div className="col-xs-4 qiyeguanli-name-num">
                                <label htmlFor="exampleInputAccount1">密码</label>
                            </div>
                            <div className="col-xs-6 qiyeguanli-name-num-input">
                                <Input type="password" onInput={this.pwdInput} placeholder=""/>
                            </div>
                        </div>

                        <div className="row updatePwd">
                            <div className="col-xs-4 qiyeguanli-name-num">
                                <label htmlFor="exampleInputAccount1">请重复密码</label>
                            </div>
                            <div className="col-xs-6 qiyeguanli-name-num-input">
                                <Input type="password" onInput={this.pwdsInput} placeholder=""/>
                            </div>
                        </div>
                    </div>
                </Modal>

            </div>
        );
    }
}

export default MYHeader;
