import React from 'react';
import NewDeviceBar from './newDeviceBar';
import NewAddUserD from './newAddUserD';
import NewAddUserByPhoneBrand from './newAddUserByPhoneBrand';
import NewAddUserAddType from './newAddUserAddType';
import {Col, Row} from 'antd';
import NewAddUserDByBrandLine from "./newAddUseDByBrandLine";

const Home: React.FC = () => {


    return (
        <div className="content-big">
            <Row gutter={8}>
                <Col span={24}>
                    <NewDeviceBar/>
                </Col>
            </Row>
            <Row >
                <Col span={24}>
                <NewAddUserD/>
                </Col>
            </Row>
            <Row >
                <Col span={24}>
                    <NewAddUserDByBrandLine/>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <NewAddUserByPhoneBrand/>
                </Col>
                <Col span={12}>
                    <NewAddUserAddType/>
                </Col>
            </Row>
        </div>
    );
};
export default Home;