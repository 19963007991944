import React, {useEffect, useRef, useState} from 'react';
import {message, Button, Input, Modal, Tag, Form, Switch, Space, Row, Col, Select} from 'antd';
import {getList, add, update} from '../../../services/TimedTaskService'
import GSTable from "../../../components/GSTable";
import {MinusCircleOutlined,PlusOutlined} from '@ant-design/icons'
import { ActionType } from "@ant-design/pro-table";
import {getAdminWuyeProject} from '../../../services/WuyeService';

const TimedTaskList: React.FC = () => {
    const [addForm] = Form.useForm();
    const [updateForm] = Form.useForm();
    const actionRef = useRef<ActionType>();
    const [search, setSearch] = useState('');
    const [addVisible, setAddVisible] = useState(false);
    const [updateVisible, setUpdateVisible] = useState(false);
    const [projList,setProjList] = useState([])
    const [projId, setProjId] = useState('');

    const valueEnum = {
        true: <Tag color={'green'}>开启</Tag>,
        false: <Tag color={'red'}>关闭</Tag>
    }
    const [projEnums, setProjEnums] = useState<any>();


    useEffect(() => {
        getProjList().then()
    }, [])

    const getProjList = async () =>{
        const res = await getAdminWuyeProject({})
        if(res.err === 0){
            console.log('data::::', res.data)
            setProjList(res.data)
            let data: any = {}
            res.data.forEach((item:any)=>{
                data[item.uuid] = item.name
            })
            setProjEnums(data)
            localStorage.setItem("projList",res.data)
            if(actionRef.current){
                actionRef.current.reload()
            }
        }
    }
    const columns = [{
        title: '标识',
        key: 'taskKey',
        dataIndex: 'taskKey'
    }, {
        title: '项目名称',
        key: 'projUuid',
        dataIndex: 'projUuid',
        valueEnum: projEnums
    }, {
        title: '名称',
        key: 'name',
        dataIndex: 'name'
    },{
        title: '描述',
        key: 'msg',
        dataIndex: 'msg'
    },{
        title: '状态',
        key: 'status',
        valueEnum: valueEnum,
        dataIndex: 'status'
    }, {
        title: '参数',
        key: 'param',
        dataIndex: 'param'
    }, {
        title: '操作',
        key: 'active',
        dataIndex: 'active',
        render: (text: string, record:any) => {
            return <Space>
                <Button type={"link"} onClick={() =>{updateItem(record)}} >编辑</Button>
            </Space>
        }
    }]

    const updateItem = (record: any) => {
        setUpdateVisible(true)
        let data = {
            ...record,
            key: record.taskKey,
            proj_id: record.projUuid,
            param: JSON.parse(record.param || [])
        };
        updateForm.setFieldsValue(data)
    }

    const loadList = async (data: any) => {
        let rsp = await getList(data);
        if (rsp.err !== 0) {
            message.error(rsp.msg);
            return
        }
        return {
            data: rsp.data,
            total:rsp.total
        }
    }
    const tableSearchBarRender = () => [
        <Select style={{width: 150}} defaultValue={projId} onChange={(e: any) => setProjId(e)}>
            <Select.Option value={''}>全部项目</Select.Option>
            {
                projList.map((item: any) => {
                    return <Select.Option key={item.uuid} value={item.uuid}>{item.name}</Select.Option>
                })
            }
        </Select>,
        <Input
            placeholder="请输入"
            onChange={(value: any) => {
                setSearch(value.target.value);
            }}
        />,
        <Button type={"primary"} onClick={() => {
            if (actionRef.current) {
                (actionRef.current as any).reloadAndRest();
            }
        }}>查询</Button>
    ];
    const tableOperationsBarRender = () => [
        <Button type={"primary"} onClick={() => {setAddVisible(true); }}>
            添加
        </Button>
    ];
    const addTimedTaskConfig = async ()=> {
        let data = await addForm.validateFields();
        console.log('data:::', data);
        let param = {
            ...data,
            param: JSON.stringify(data.param)
        }
        let rsp = await add(param);
        if (rsp.err === 0) {
            message.success(rsp.msg)
            setAddVisible(false);
            addForm.resetFields()
            if (actionRef.current) {
                (actionRef.current as any).reloadAndRest()
            }
        } else {
            message.error(rsp.msg);
        }

    };
    const updateTimedTaskConfig = async () => {
        let data = await updateForm.validateFields();
        let params = {
            ...data,
            param: JSON.stringify(data.param)
        };
        let rsp = await update(params);
        if (rsp.err === 0) {
            message.success(rsp.msg)
            setUpdateVisible(false);
            updateForm.resetFields()
            if (actionRef.current) {
                (actionRef.current as any).reloadAndRest()
            }
        }else {
            message.error(rsp.msg);
        }
    }
    return <>
        <GSTable
            actionRef={actionRef}
            searchBarRender={tableSearchBarRender()}
            operationsBarRender={tableOperationsBarRender()}
            columns={columns}
            request={async (params: any) => {
            let data = {
                page: params.current,
                size: params.pageSize,
                search: search,
                proj_id: projId
            };
            return loadList(data);
        }}/>
        <Modal destroyOnClose={true} title={'添加'} visible={addVisible} onCancel={() => {setAddVisible(false)}} onOk={addTimedTaskConfig}>
            <Form form={addForm} labelCol={{span: 4}}>
                <Form.Item label={'标识'} name={'key'} rules={[{required: true, message: '请填写标识'}]}>
                    <Input/>
                </Form.Item>
                <Form.Item label={'项目'} name={'proj_id'} rules={[{required: true, message: '请项目ID'}]}>
                    <Select style={{width: 150}}>
                        {
                            projList.map((item: any) => {
                                return <Select.Option key={item.uuid} value={item.uuid}>{item.name}</Select.Option>
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item label={'名称'} name={'name'} rules={[{required: true, message: '请填写名称'}]}>
                    <Input/>
                </Form.Item>
                <Form.Item  valuePropName="checked" label={'状态'}  name={'status'} >
                    <Switch checkedChildren="开启" unCheckedChildren="关闭" />
                </Form.Item>
                <Row gutter={4} justify={'end'} align={'middle'} >
                    <Col >
                        参数：
                    </Col>
                    <Col span={20}>
                        <Form.List name="param">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                                        <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'key']}
                                                fieldKey={[fieldKey, 'key']}
                                                rules={[{ required: true, message: '参数标识' }]}
                                            >
                                                <Input placeholder={'请输入参数标识'} />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'name']}
                                                fieldKey={[fieldKey, 'name']}
                                                rules={[{ required: true, message: '参数名称' }]}
                                            >
                                                <Input placeholder={'请输入参数参数名称'} />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'value']}
                                                fieldKey={[fieldKey, 'value']}
                                                rules={[{ required: true, message: '参数值' }]}
                                            >
                                                <Input placeholder={'请输入参数值'}/>
                                            </Form.Item>
                                            <MinusCircleOutlined onClick={() => remove(name)} />
                                        </Space>
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            添加参数
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Col>
                </Row>
                <Form.Item label={'描述'} name={'msg'} >
                    <Input.TextArea />
                </Form.Item>
            </Form>
        </Modal>
        <Modal destroyOnClose={true} title={'编辑'} visible={updateVisible} onCancel={() => {setUpdateVisible(false)}} onOk={updateTimedTaskConfig}>
            <Form form={updateForm} labelCol={{span: 4}}>
                <Form.Item name={'id'} hidden={true}>
                    <Input/>
                </Form.Item>
                <Form.Item label={'标识'} name={'key'} rules={[{required: true, message: '请填写标识'}]}>
                    <Input  disabled={true} />
                </Form.Item>
                <Form.Item label={'名称'} name={'name'} rules={[{required: true, message: '请填写名称'}]}>
                    <Input/>
                </Form.Item>
                <Form.Item label={'项目'} name={'proj_id'} rules={[{required: true, message: '请项目ID'}]}>
                    <Select style={{width: 150}}>
                        {
                            projList.map((item: any) => {
                                return <Select.Option key={item.uuid} value={item.uuid}>{item.name}</Select.Option>
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item  valuePropName="checked" label={'状态'}  name={'status'} >
                    <Switch checkedChildren="开启" unCheckedChildren="关闭" />
                </Form.Item>
                <Row gutter={4} justify={'end'} align={'middle'} >
                    <Col >
                        参数：
                    </Col>
                    <Col span={20}>
                        <Form.List name="param">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                                        <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'key']}
                                                fieldKey={[fieldKey, 'key']}
                                                rules={[{ required: true, message: '参数标识' }]}
                                            >
                                                <Input  placeholder={'请输入参数标识'} />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'name']}
                                                fieldKey={[fieldKey, 'name']}
                                                rules={[{ required: true, message: '参数名称' }]}
                                            >
                                                <Input placeholder={'请输入参数参数名称'} />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'value']}
                                                fieldKey={[fieldKey, 'value']}
                                                rules={[{ required: true, message: '参数值' }]}
                                            >
                                                <Input placeholder={'请输入参数值'}/>
                                            </Form.Item>
                                        </Space>
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            添加参数
                                        </Button>
                                    </Form.Item>
                                </>
                            )}

                        </Form.List>
                    </Col>
                </Row>
                <Form.Item label={'描述'} name={'msg'} >
                    <Input.TextArea />
                </Form.Item>
            </Form>
        </Modal>
    </>
}
export default TimedTaskList;
