import Api from "../data/API";

export async function getWuyeCompanyList(params:any) {
    return Api.postWithAuth('/api/company/getWuyeCompanyList', params);
}
export async function addWuyeCompnay(params:any) {
    return Api.postWithAuth('/api/company/addWuyeCompnay', params);
}

export async function updateInfo(params:any) {
    return Api.postWithAuth('/api/company/updateInfo', params);
}


