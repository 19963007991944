import Api from "../data/API";
export async function getParnerDeviceList(params:any) {
    return Api.postWithAuth('/adminapi/pertner/getParnerDeviceList', params);
}

export async function addPartnerDevice(params:any) {
    return Api.postWithAuth('/adminapi/pertner/addPartnerDevice', params);
}

export async function updatePartnerDevice(params:any) {
    return Api.postWithAuth('/adminapi/pertner/updatePartnerDevice', params);
}

export async function deletePartnerDevice(params:any) {
    return Api.postWithAuth('/adminapi/pertner/deletePartnerDevice', params);
}

export async function getIncomeList(params:any) {
    return Api.postWithAuth('/adminapi/pertner/getPartnerIncome', params);
}
export async function getPartnerCashList(params:any) {
    return Api.postWithAuth('/adminapi/pertner/getPartnerCashList', params);
}
export async function addPartnerCash(params:any) {
    return Api.postWithAuth('/adminapi/pertner/addPartnerCash', params);
}
export async function updatePartnerCash(params:any) {
    return Api.postWithAuth('/adminapi/pertner/updatePartnerCash', params);
}
export async function deletePartnerCash(params:any) {
    return Api.postWithAuth('/adminapi/pertner/deletePartnerCash', params);
}

export async function withDrawIncome(params:any) {
    return Api.postWithAuth('/adminapi/pertner/withDrawIncome', params);
}

