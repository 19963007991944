import Api from "../data/API";

export async function getDataTranList(params: any) {
    return Api.postWithAuth('/adminapi/dataTransmission/getDataTranList', params);
}
export async function addDataTran(params: any) {
    return Api.postWithAuth('/adminapi/dataTransmission/addDataTran', params);
}
export async function updateDataTran(params: any) {
    return Api.postWithAuth('/adminapi/dataTransmission/updateDataTran', params);
}
export async function deleteDataTran(params: any) {
    return Api.postWithAuth('/adminapi/dataTransmission/deleteDataTran', params);
}