import React, { useEffect, useRef, useState } from "react";
import GSTable from "../../components/GSTable";
import { ActionType } from "@ant-design/pro-table";
import {
    addPartnerDevice,
    deletePartnerDevice,
    getParnerDeviceList,
    updatePartnerDevice
} from "../../services/PartnerService";
import { Button, Col, Form, Input, InputNumber, message, Modal, Row, Select, Tooltip } from "antd";
import API from "../../data/API";
import { FormInstance } from "antd/es/form";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { getAdminWuyeProject } from "src/services/WuyeService";
const PartnerDeviceList:React.FC = () =>{

    useEffect(()=>{
        getAllDevice()
        getProductList()
    },[])

    const [deviceList,setDeviceList] = useState([])
    const [visible,setVisible] = useState(false)
    const FormItem = Form.Item;
    const [form] = Form.useForm<FormInstance>()
    const Option = Select.Option
    const [add, setAdd] = useState(false)
    const [productList, setProductList] = useState([])
    const [productSearch,setProductSearch] = useState("")
    const [devices,setDevices] = useState([])
    const actionRef = useRef<ActionType>()

    const getProductList =async () => {
        const res = await getAdminWuyeProject({})
        if (res.err === 0) {
            setProductList(res.data)
        }
    }

    const  columns = [{
        title: '姓名',
        dataIndex: 'userName',
        key: 'userName',
    }, {
        title: '手机号',
        dataIndex: 'userPhone',
        key: 'userPhone',
    },
    {
        title: '设备Id',
        dataIndex: 'deviceUuid',
        key: 'deviceUuid',
        hideInSearch: true
    }, {
        title: '设备名称',
        dataIndex: 'deviceName',
        key: 'deviceName',
        hideInSearch: true
    }, {
        title: '项目',
        dataIndex: 'wuyeName',
        key: 'wuyeName',
        hideInSearch: true
    }, {
        title: '千次开门收益',
        dataIndex: 'shareRate',
        key: 'shareRate',
        hideInSearch: true,
        render:(text:any,item:any)=>{
            return<>
                <div>{item.shareRate *10 }元</div>
            </>
        }

    },
    {
        title: '操作',
        hideInSearch: true,
        render:(text:any,item:any)=>{
            return<>
                <a onClick={()=>{showModel(item)}}>编辑</a>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <a onClick={()=>{deleteItem(item)}}>删除</a>
            </>
        }
    },

    ];

    const deleteItem =  (item:any) =>{
        Modal.confirm({
            content: '您确定要删除用户：'+item.userName+"设备："+item.deviceName +'吗？',
            okType: 'danger',
            onOk:async () =>{
                const res = await deletePartnerDevice({userUuid:item.userUuid,deviceUuid:item.deviceUuid})
                if(res.err === 0){
                    message.success("删除成功")
                    if(actionRef.current){
                        actionRef.current.reload()
                    }
                }else{
                    message.error("删除失败")
                }
            },
            onCancel() {
                console.log('Cancel');
            },
        });

    }

    const showModel = (item:any) =>{
        console.log("item:::",item)
        const formData:any = {
            id:item.id,
            deviceIds:item.deviceUuid,
            userPhone:item.userPhone,
            shareRate:item.shareRate
        }

        form.setFieldsValue(formData)
        setProductSearch("")
        setDevices([...deviceList])
        setAdd(false)
        setVisible(true)
    }

    const  getList = async (param: any) => {
        let rsp = await getParnerDeviceList({...param})
        if (rsp.err === 0) {
            return {
                data: rsp.data,
                total: rsp.count
            };
        } else {
            return {
                data: [],
                total: 0
            };
        }
    };
    const  getAllDevice = async () => {
        let rsp = await API.postWithAuth('/admin/api/device/getDeviceDList', {});
        if (rsp.err === 0) {
            setDeviceList(rsp.data)
            setDevices(rsp.data)
        }else{
            setDeviceList([])
            setDevices([])
        }

    };

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };
    const tableOperationsBarRender = () =>[
        <Button type={"primary"} onClick={openAddModal}>添加</Button>
    ]
    const openAddModal = () =>{
        form.resetFields()
        const formData:any = {
            shareRate:1
        }

        form.setFieldsValue(formData)
        setProductSearch("")
        setDevices([...deviceList])
        setAdd(true)
        setVisible(true)
    }
    const saveItem = () =>{
        if (add){
            addItem()
        }else{
            updateItem()
        }
    }

    const addItem =() =>{
        form.validateFields().then(async (data:any)=>{
            const  formData:any = {
                userPhone:data.userPhone,
                deviceIds:data.deviceIds,
                shareRate:data.shareRate
            }
            const res = await addPartnerDevice(formData)
            if(res.err === 0){
                message.success("添加成功")
                setVisible(false)
                if(actionRef.current){
                    actionRef.current.reload()
                }
            }else{
                message.error("添加失败")
            }
        })
    }
    const updateItem =() =>{
        form.validateFields().then(async (data:any)=>{
            const  formData:any = {
                userPhone:data.userPhone,
                deviceUuid:data.deviceIds,
                shareRate:data.shareRate
            }
            const res = await updatePartnerDevice(formData)
            if(res.err === 0){
                message.success("修改成功")
                setVisible(false)
                if(actionRef.current){
                    actionRef.current.reload()
                }
            }else{
                message.error("修改失败")
            }
        })
    }

    const tableSearchBarRender = () => [
        <Tooltip title="每天夜里1~2点 计算前一天的收益">
            <QuestionCircleOutlined style={{cursor:"pointer"}} />
        </Tooltip>,
    ]
    const changeDevice = (value: any) => {
        if (value == "" || value == undefined || value == null) {
            setDevices([...deviceList])
        } else {
            const list = deviceList.filter((item: any) => item.wuyeid == value)
            setDevices([...list])
         }
        
    }

    return(<>
        <Row gutter={8} justify={'center'} align={'middle'}>
            <Col span={24}>
                <GSTable  searchBarRender={tableSearchBarRender()} rowKey="id" search={true}   operationsBarRender={tableOperationsBarRender()} actionRef={actionRef}  columns={columns}  request={(params:any)=>{return getList(params)}}/>
            </Col>
        </Row>
        <Modal title={add?'添加':'修改'} visible={visible} onCancel={()=>{setVisible(false)}} onOk={saveItem}>
            <Form form={form} {...formItemLayout}>
                <FormItem name={'id'} hidden={true}>
                    <Input/>
                </FormItem>
                <FormItem name={'userPhone'} label={'用户手机号'} rules={[{required:true}]}>
                    <Input disabled={!add} />
                </FormItem>
                <FormItem tooltip={"根据项目过滤设备"} name={'product'} label={'项目'} >
                    <Select value={productSearch}  disabled={!add} onChange={(value) => { changeDevice(value) }} allowClear showSearch
                        optionFilterProp="children">
                            {productList.length>0?
                                productList.map((item:any)=>{
                                return <Option value={item.uuid}>{item.name}</Option>
                            }):''
                        }
                    </Select>
                </FormItem>
                <FormItem name={'deviceIds'}  label={'设备'} rules={[{required:true}]}>
                    <Select allowClear showSearch disabled={!add}  mode={add?"multiple":undefined}  optionFilterProp="children">
                        {devices.length>0?
                            devices.map((item:any)=>{
                                return <Option value={item.deviceid}>{item.description}-{ item.wuyename}</Option>
                            }):''
                        }
                    </Select>
                </FormItem>
                <FormItem label={'百次开门收益'}  name={'shareRate'}   rules={[{required:true,}]}>
                    <InputNumber  /> 
                 </FormItem>    
            </Form>
        </Modal>
    </>)
}
export default PartnerDeviceList