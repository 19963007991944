import React, { useEffect, useRef, useState } from "react";
import { ActionType } from "@ant-design/pro-table";
import GSTable from "../../components/GSTable";
import API from "../../data/API";
import AddFaceDevice from "./AddFaceDevice";
import { Button, Col, Form, Input, message, Modal, Row, Select, Space } from "antd";
import { asyncFaceDeviceImage } from "../../services/OpService";
import { batchUpdateJKM, cpEkey } from "../../services/FaceDeviceService";
import { FormInstance } from "antd/es/form";

const FaceDeviceList: React.FC = () => {
    const [form] = Form.useForm<FormInstance>();
    const [cpForm] = Form.useForm<FormInstance>();

    const actionRef = useRef<ActionType>();
    const [add, setAdd] = useState(false);
    const [info, setInfo] = useState<any>([]);
    const [visible, setVisible] = useState(false);
    const [project, setProject] = useState([]);
    const [data, setData] = useState([]);
    const [updateJKMVisible, setUpdateJKMVisible] = useState(false);
    const [targetDeviceId, setTargetDeviceId] = useState("");
    const [targetDeviceName, setTargetDeviceName] = useState("");
    const [cpModalVisible, setCpModalVisible] = useState(false);
    const [projUuid, setProjUuid] = useState("")
    const [search, setSearch] = useState("")
    useEffect(() => {
        getProject();
    }, []);
    const columns = [{
        title: "设备名称",
        dataIndex: "description",
        key: "description"
    }, {
        title: "设备ID",
        dataIndex: "rel_device_id",
        key: "rel_device_id"
    }, {
        title: "激活码",
        dataIndex: "code",
        key: "code"
    }, {
        title: "所属物业",
        dataIndex: "name",
        key: "name"
    }, {
        title: "设备密码",
        dataIndex: "password",
        key: "password"
    }, {
        title: "WIFI密码",
        dataIndex: "wifi_password",
        key: "wifi_password"
    }, {
        title: "ICID",
        dataIndex: "icid",
        key: "icid"
    }, {
        title: "操作",
        dataIndex: "active",
        key: "active",
        render: (text: string, record: any) => {
            return <Space>
                <a onClick={() => {
                    openUpdateModal(record);
                }}>修改</a>
                {
                    record.type === "2" && <a onClick={() => asyncFaceImage(record.rel_device_id)}>同步所有照片</a>
                }
                <a onClick={() => ShowCpEkey(record)}>复制钥匙</a>
            </Space>;
        }
    }];
    const ShowCpEkey = (record: any) => {
        setTargetDeviceId(record.rel_device_id);
        setCpModalVisible(true);
        setTargetDeviceName(record.description);
    };
    const openUpdateModal = (value: any) => {
        setAdd(false);
        setInfo(value);
        setVisible(true);
    };
    const openAddModal = () => {
        setAdd(true);
        setInfo({});
        setVisible(true);
    };
    const tableOperationsBarRender = () => [
        <Button type={"primary"} onClick={openAddModal}>添加</Button>
    ];
    const tableSearchBarRender = () => {
        return [
            <Row align={"middle"}>
                <Col>
                    项目名称：
                </Col>
                <Col>
                    <Select
                        style={{width: 200}}
                        allowClear
                        onChange={(e: string) => {
                            setProjUuid(e)
                        }}
                        placeholder={"请选择"}>
                        {project.map((item: any) => {
                            return (
                                <Select.Option key={item.uuid} value={item.uuid}>{item.name}</Select.Option>
                            );
                        })}
                    </Select>
                </Col>
            </Row>,
            <Input placeholder={'设备名称'}  onChange={(e) => {
                setSearch(e.target.value)
            }}/>,
            <Button type={"primary"} onClick={() => {
                if (actionRef.current) {
                    actionRef.current.reload();
                }
            }}>查询</Button>
            ];
    };
    const save = () => {
        setVisible(false);
        if (actionRef.current) {
            actionRef.current.reload();
        }
    };

    const getProject = () => {
        API.postWithAuth("/api/wuye/get_wuye_project", {}).then(res => {
            if (res.err === 0) {
                console.log("获得物业列表", res);
                setProject(res.data);
            }
        });
    };
    /**
     * 同步人脸照片
     **/
    const asyncFaceImage = (deviceId: any) => {
        Modal.confirm({
            width: 600,
            title: "提示",
            content: (
                <>
                    <p>同步权限操作说明：</p>
                    <p>
                        1.
                        同步权限只对人脸识别设备（离线版）有效，将把该设备未同步的所有用户权限（权限有效期、人脸识别照片等）同步到设备上
                    </p>
                    <p>2. 如果已经同步过，则不会同步</p>
                    <p>3. 如果设备当前离线，则不会同步权限</p>
                    <p>
                        4.
                        同步权限操作成功，表示同步命令的发送成功，并不代表实际同步成功，查看实际是否同步成功，请到钥匙授权列表页面查看
                    </p>
                </>
            ),
            onOk: async () => {
                let data = {
                    deviceId: deviceId
                };
                let rsp = await asyncFaceDeviceImage(data);
                if (rsp.err === 0) {
                    message.success(rsp.msg);
                } else {
                    message.success(rsp.msg);
                }
            }
        });
    };
    const showUpdateJKMModal = (selectedRowKeys: any) => {
        let data: any = {
            id: selectedRowKeys.toString()
        };
        form.setFieldsValue(data);
        setUpdateJKMVisible(true);
    };
    const updateJKM = async () => {
        let data = await form.getFieldsValue();
        console.log("data:::", data);
        let rsp = await batchUpdateJKM(data);
        setUpdateJKMVisible(false);
        if (rsp.err === 0) {
            message.success(rsp.msg);
            if (actionRef.current) {
                actionRef.current.reload();
            }
        } else {
            message.error(rsp.msg);
        }
    };
    const cpEkeyOk = async () => {
        let data = await cpForm.getFieldsValue();
        Modal.confirm({
            title: "确认",
            content: `您确认要将该设备钥匙复制给：${targetDeviceName}吗`,
            onOk: async () => {
                let rsp = await cpEkey(data);
                if (rsp.err === 0) {
                    setCpModalVisible(false);
                    message.success(rsp.msg);
                } else {
                    message.error(rsp.msg);
                }

            }
        });
    };
    return (<>
        <GSTable
            columns={columns}
            rowKey={"id"}
            rowSelection={{
                fixed: true,
                columnWidth: 20,
                preserveSelectedRowKeys: true
            }}
            tableAlertRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
                return <Space size={24}>
								<span>
									已选 {selectedRowKeys.length} 项
									<a style={{ marginLeft: 8 }} onClick={onCleanSelected}>
										取消选择
									</a>
								</span>
                </Space>;
            }}
            tableAlertOptionRender={({ selectedRowKeys, selectedRows, onCleanSelected }: any) => {
                return (
                    <Space size={16}>
                        <a onClick={() => {
                            showUpdateJKMModal(selectedRowKeys);
                        }}>修改健康码</a>
                    </Space>
                );
            }}
            actionRef={actionRef}
            searchBarRender={tableSearchBarRender()}
            operationsBarRender={tableOperationsBarRender()}
            request={async (params: any = {}, sort: any, filter: any) => {
                const res = await API.postWithAuth("/api/device/get_all_face_device", {projUuid: projUuid, search});
                if (res.err === 0) {
                    setData(res.data);
                    return {
                        data: res.data
                    };
                } else {
                    return {
                        data: []
                    };
                }
            }}
        />
        <Modal maskClosable={false} title={"修改健康码"} visible={updateJKMVisible} onOk={updateJKM} onCancel={() => {
            setUpdateJKMVisible(false);
        }}>
            <Form form={form}>
                <Form.Item label={"ID"} hidden={true} name="id" rules={[{ required: false }]}>
                    <Input placeholder={"ID"} />
                </Form.Item>
                {/*<Form.Item label={"健康码APPID"} name="jianKangMaAppId"*/}
                {/*           initialValue={"775bfb31-dcee-4743-95fc-2d66fe5864a2"}>*/}
                {/*    <Input placeholder={"健康码APPID"} />*/}
                {/*</Form.Item>*/}
                {/*<Form.Item label={"健康码SECRET"} name="jianKangMaSecret"*/}
                {/*           initialValue={"d985e4aed9f5d57f0f80cc8b91ce1877"}>*/}
                {/*    <Input placeholder={"健康码SECRET"} />*/}
                {/*</Form.Item>*/}
                {/*<Form.Item label={"健康码设备号"} name="jianKangMaDeviceNo" initialValue={"HC-233174915286144"}>*/}
                {/*    <Input placeholder={"健康码设备号"} />*/}
                {/*</Form.Item>*/}
                {/*<Form.Item label={"健康码IP"} name="jianKangMaIp" initialValue={"192.100.1.18"}>*/}
                {/*    <Input placeholder={"健康码IP"} />*/}
                {/*</Form.Item>*/}
                {/*<Form.Item label={"健康码MAC"} name="jianKangMaMac" initialValue={"d4:12:43:ff:04:80"}>*/}
                {/*    <Input placeholder={"健康码MAC"} />*/}
                {/*</Form.Item>*/}
                {/*<Form.Item label={"健康码位置"} name="jianKangMaPosition" initialValue={"(116.397316,39.907199)"}>*/}
                {/*    <Input placeholder={"健康码位置"} />*/}
                {/*</Form.Item>*/}
                <Form.Item label={"mqtt服务地址"} name="mqttServerUrl" initialValue={"tcp://api.guosim.com:1883"}>
                    <Input placeholder={"mqtt服务地址"} />
                </Form.Item>
                <Form.Item label={"mqtt服务账号"} name="mqttUserName" initialValue={"admin"}>
                    <Input placeholder={"mqtt服务账号"} />
                </Form.Item>
                <Form.Item label={"mqtt服务密码"} name="mqttPassword" initialValue={"xA5iUF2N"}>
                    <Input placeholder={"mqtt服务密码"} />
                </Form.Item>
                <Form.Item label={"通知地址"} name="service" initialValue={"api.guosim.com:80"}>
                    <Input placeholder={"通知地址"} />
                </Form.Item>
                <Form.Item label={"物业ID"} name="projUuid" rules={[{ required: true }]}>
                    <Select placeholder={"选择物业"}>
                        {project.map((item: any) => {
                            return <Select.Option key={item.uuid} value={item.uuid}>
                                {item.name}
                            </Select.Option>;
                        })}
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
        <Modal title={"选择要复制的设备"} destroyOnClose={true} visible={cpModalVisible} onCancel={() => {
            setCpModalVisible(false);
        }} onOk={cpEkeyOk}>
            <Form form={cpForm} preserve={false}>
                <Form.Item label={"ID"} name="targetDeviceId" rules={[{ required: false }]}
                           initialValue={targetDeviceId}>
                    <Input placeholder={"ID"} />
                </Form.Item>
                <Form.Item label={"选择设备："} name="srcDeviceId" rules={[{ required: false }]}>
                    <Select showSearch={true} optionFilterProp={"children"}>
                        {
                            data.map((item: any) => {
                                if (item.rel_device_id !== targetDeviceId) {
                                    return <Select.Option value={item.rel_device_id}>{item.description}</Select.Option>;
                                }
                                return;
                            })
                        }

                    </Select>
                </Form.Item>
            </Form>

        </Modal>
        <AddFaceDevice onSubmit={save} key={JSON.stringify(info)} visible={visible} add={add} info={info}
                       onCancel={() => {
                           setVisible(false);
                       }} />
    </>);
};
export default FaceDeviceList;
