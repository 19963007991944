import Api from "../data/API";

export async function getOpSmsTemplate(params:any) {
    return Api.postWithAuth('/adminapi/sms/getOpSmsTemplate', params);
}
export async function addSmsTemplate(params:any) {
    return Api.postWithAuth('/adminapi/sms/addSmsTemplate', params);
}
export async function updateTemplate(params:any) {
    return Api.postWithAuth('/adminapi/sms/updateTemplate', params);
}
export async function deleteTemplate(params:any) {
    return Api.postWithAuth('/adminapi/sms/deleteTemplate', params);
}
