import React, { useEffect, useRef, useState } from "react";
import GSTable from "../../components/GSTable";
import { ActionType } from "@ant-design/pro-table";
import { Button, Col, Form, Input, InputNumber, message, Modal, Row, Select } from "antd";
import { addSmsTemplate, deleteTemplate, getOpSmsTemplate, updateTemplate } from "../../services/sms";
import { getAdminWuyeProject } from "../../services/WuyeService";
import { FormInstance } from "antd/es/form";

const SmsList:React.FC = () =>{
    let projEnums = {}
    useEffect(()=>{
        getProjList()
    },[])
    const getProjList = async () =>{
        const res = await getAdminWuyeProject({})
        if(res.err === 0){
            setProjList(res.data)
            res.data.forEach((item:any)=>{
             projEnums[item.uuid] = item.name
            })
            localStorage.setItem("projList",res.data)
            if(actionRef.current){
                actionRef.current.reload()
            }
        }
    }
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };
    const [wyUuid,setWyUuid] = useState("")
    const [visible,setVisible] = useState(false)
    const [form] = Form.useForm<FormInstance>()
    const FormItem = Form.Item
    const [add,setAdd] = useState(false)
    const [projList,setProjList] = useState([])
    const actionRef = useRef<ActionType>()
    const  columns = [{
        title: 'ID',
        dataIndex: 'templateCode',
        key: 'templateCode',
        hideInSearch: true,
        render:(_:any,item:any)=>(
            <a onClick={()=>{openUpdateModal(item)}}> {item.templateCode}</a>
        )
    }, {
        title: '模板类别',
        dataIndex: 'templateType',
        key: 'dateTime',
        hideInSearch: true,
    },
    {
        title: '项目',
        dataIndex: 'wuyeName',
        key: 'wuyeNam',
        hideInSearch: true,
    },
        {
        title: '模板名称',
        dataIndex: 'templateName',
        key: 'templateName',
        hideInSearch: true,

    },
    {
        title: '项目',
        dataIndex: 'wuyeUuid',
        key: 'wuyeUuid',
        ellipsis: true,
        hideInTable: true,
        renderFormItem: (text: string, record: any) =>
            <Select
                style={{width:200}}
                showSearch
                allowClear
                onChange={(value:any)=>{setWyUuid(value)
                    if(actionRef.current){
                        // @ts-ignore
                        actionRef.current.reloadAndRest()
                    }
                }}
                placeholder={'项目'}
                optionFilterProp="children">
                {projList.length>0&&projList.map((item: any) => {
                    return <Select.Option key={item.uuid} value={item.uuid}>
                        {item.name}
                    </Select.Option>;
                })}
            </Select>
    },
    {
        title: '模板类型',
        dataIndex: 'templateCate',
        key: 'templateCate',
        hideInSearch: true
    },{
        title: '内容',
        dataIndex: 'content',
        key: 'content',
            ellipsis:true,
        hideInSearch: true
    },{
        title: '签名',
        dataIndex: 'signName',
        key: 'signName',
        hideInSearch: true
    },{
        title: '供应商',
        dataIndex: 'smsVendor',
        key: 'smsVendor',
        hideInSearch: true
    },
    {
        title: 'appId',
        dataIndex: 'appId',
        key: 'appId',
        hideInSearch: true
    },
    {
        title: '操作',
        hideInSearch: true,
        render:(_:any,item:any)=>{
            return<>
                <a onClick={()=>{deleteItem(item)}}>删除</a>
            </>
        }
    },
    ];
    const deleteItem = (item:any) =>{
        Modal.confirm({
            content: '你确定删除'+ item.templateName +"吗？",
            okType: 'danger',
            onOk:async ()=> {
                const res = await deleteTemplate({id:item.id,wyUuid:item.wyUuid})
                if(res.err == 0){
                    message.success("删除成功");
                    if(actionRef.current){
                        actionRef.current.reload()
                    }
                }
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    // @ts-ignore
    const  getList = async (param: any) => {
            let rsp = await getOpSmsTemplate({
                ...param,
                page: param.current,
                size: param.pageSize,
                wuyeUuid:wyUuid
            })
            if (rsp.err === 0) {
                return {
                    data: rsp.data,
                    total: rsp.count
                };
            } else {
                return {
                    data: [],
                    total: 0
                };
            }

    };
    const openUpdateModal = (item:any) =>{
        form.setFieldsValue({...item})
        setAdd(false)
        setVisible(true)
    }

    const tableOperationsBarRender = () =>([
        <Button type="primary"  onClick={openAddModal}>添加模板</Button>
    ])
    const openAddModal = () =>{
        form.resetFields()
        setAdd(true)
        setVisible(true)
    }
    const saveItem = () =>{
        if(add){
            addItem()
        }else{
            updateItem()
        }
    }
    const addItem = () =>{
        form.validateFields().then(async (data:any)=>{
            data.id = undefined
            const res = await addSmsTemplate({...data})
            if(res.err == 0){
                message.success("添加成功")
                setVisible(false)
                setAdd(false)
                if(actionRef.current){
                    actionRef.current.reload()
                }
            }else{
                message.error("添加失败")
                setVisible(false)
                setAdd(false)
            }
        })
    }
    const updateItem = () =>{
        form.validateFields().then(async (data:any)=>{
            const res = await updateTemplate({...data})
            if(res.err == 0){
                message.success("修改成功")
                setVisible(false)
                setAdd(false)
                if(actionRef.current){
                    actionRef.current.reload()
                }
            }else{
                message.error("修改成功")
                setVisible(false)
                setAdd(false)

            }
        })
    }
    // const tableSearchBarRender = () =>[
    //
    // ]
    return(<>
        <div className="content-big">
        <Row gutter={8} justify={'center'} align={'middle'}>
            <Col span={24}>
                <GSTable  search={true} operationsBarRender={tableOperationsBarRender()}  actionRef={actionRef}  columns={columns}  request={(params:any)=>{return getList(params)}}/>
            </Col>
        </Row>
        </div>
        <Modal title={add?'添加':'修改'} visible={visible} onCancel={() =>{setVisible(false)}} onOk={saveItem}>
            <Form {...formItemLayout} form={form}>
                <FormItem name={'id'} hidden={true} >
                    <Input/>
                </FormItem>
                <FormItem name={'templateName'} label={'模板名称'}>
                    <Input/>
                </FormItem>
                <FormItem name={'templateCode'} label={'模板Id'}>
                    <Input/>
                </FormItem>
                <FormItem name={'templateType'} label={'模板类别'}>
                    <Input/>
                </FormItem>
                <FormItem name={'templateCate'} label={'模板类型'}>
                    <Select>
                        <Select.Option value={'system'}>系统</Select.Option>
                        <Select.Option value={'user'}>用户</Select.Option>
                    </Select>
                </FormItem>
                <FormItem name={'content'} label={'内容'}>
                    <Input/>
                </FormItem>
                <FormItem name={'paramNum'} label={'参数个数'}>
                    <InputNumber style={{width:'100%'}}/>
                </FormItem>
                <FormItem name={'signName'} label={'签名'}>
                    <Input/>
                </FormItem>
                <FormItem name={'wyUuid'} label={'项目'}>
                    <Select
                            showSearch
                            optionFilterProp="children">
                        {projList.length>0&&projList.map((item: any) => {
                            return <Select.Option key={item.uuid} value={item.uuid}>
                                {item.name}
                            </Select.Option>;
                        })}
                    </Select>
                </FormItem>
                <FormItem name={'smsVendor'} label={'供应商'}>
                    <Select>
                        <Select.Option value={'阿里云'}>阿里云</Select.Option>
                        <Select.Option value={'腾讯云'}>腾讯云</Select.Option>
                        <Select.Option value={'助通'}>助通</Select.Option>
                    </Select>
                </FormItem>
                <FormItem name={'appId'} label={'appId'}>
                    <Input/>
                </FormItem>
            </Form>

        </Modal>

    </>)
}
export default SmsList