import React, {  useRef, useState } from "react";
import { Col, Modal, Row,  Form, Input, Upload, message, Button } from "antd";
import { FormInstance } from "antd/es/form";
import Api from "../../data/API";
import { PlusOutlined } from "@ant-design/icons/lib";
import { ActionType } from "@ant-design/pro-table";
import GSTable from "../../components/GSTable";
import { addWuyeCompnay, getWuyeCompanyList, updateInfo } from "../../services/CompanyService";

const CompanyList: React.FC = () =>{
    const uploadUrl = Api.apiRootPath + '/api/picture/upload_qiniu_two';
    const [form] = Form.useForm<FormInstance>();
    const FormItem = Form.Item
    const [visiable,setVisiable] = useState(false)
    const [updateLoading,setUpdateLoading] = useState(false)
    const [companyIcon,setCompanyIcon] = useState("")
    const [add,setAdd] = useState(false);
    const actionRef = useRef<ActionType>()
    const columns = [
        {
            title: '公司名称',
            dataIndex: 'companyName',
            key: 'companyName',
            render: (text: string, record: any) => <a onClick={()=>{updateItem(record)}}>{text}</a>,
        },

        {
            title: '状态',
            dataIndex: 'companyStatus',
            key: 'companyStatus',
        },
        {
            title: '联系人',
            dataIndex: 'connectName',
            key: 'connectName',
        },
        {
            title: '联系人电话',
            dataIndex: 'connectPhone',
            key: 'connectPhone',
        },
        {
            title: '联系人邮箱',
            dataIndex: 'connectEmail',
            key: 'connectEmail',
        },
        {
            title: '座机',
            dataIndex: 'connectTel',
            key: 'connectTel',
        },
        {
            title: '公司地址',
            dataIndex: 'companyAddress',
            key: 'companyAddress',
        },
    ]
    const formItemLayout = {
        labelCol: {
            xs: { span: 12 },
            sm: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 12 },
            sm: { span: 16 },
        },
    };
    const formItemLayout1 = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 3 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 20 },
        },
    };
    const updateItem = (record:any) =>{
        setVisiable(true)
        form.setFieldsValue(record)
        setCompanyIcon(record.companyIcon)
        console.log("数据：：：",record)
    }

    const updateCompany = () =>{
        form.validateFields().then(async (data:any) =>{
            setUpdateLoading(true)
            const formData:any = {
                companyUuid:data.uuid,
                companyIcon:companyIcon,
                companyName:data.companyName,
                companyStatus:data.companyStatus,
                connectName:data.connectName,
                connectEmail:data.connectEmail,
                connectPhone:data.connectPhone,
                connectTel:data.connectTel,
                companyAddress:data.companyAddress
            }
            console.log("修改的额数据",data)
            console.log("修改的额数据",formData)
            const res = await updateInfo(formData)
                if (res.err === 0) {
                    setVisiable(false)
                    setUpdateLoading(false)
                    if(actionRef.current){
                        actionRef.current.reload()
                    }
                    message.success("修改成功")
                }else{
                    setUpdateLoading(false)
                    setVisiable(false)
                    message.error("修改失败")
                }
        })
    }

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>上传</div>
        </div>
    )
    const getFileName = (fileList: any) => {
        console.log('fileList', fileList);
        if (fileList.file.status === 'done') {
            setCompanyIcon(fileList.file.response.data[0])
            console.log(`${fileList.file.name}`);
            console.log('公司上传', fileList.file.response.url);
            console.log('公司上传1', fileList.file.response.data[0]);
        } else if (fileList.file.status === 'error') {
            message.error(`${fileList.file.name} 上传文件失败`);
        }
    }
    const addModal = () =>{
        setAdd(true)
        setVisiable(true)
        setCompanyIcon("")
        form.resetFields()
    }

    const clickOk = () =>{
        if(add){
            addCompany()
        }else{
            updateCompany()
        }
    }
    const addCompany  = () =>{
        form.validateFields().then(async (data:any)=>{
            setUpdateLoading(true)
            const formData:any = {
                companyIcon:companyIcon,
                companyName:data.companyName,
                companyStatus:data.companyStatus,
                connectName:data.connectName,
                connectEmail:data.connectEmail,
                connectPhone:data.connectPhone,
                connectTel:data.connectTel,
                companyAddress:data.companyAddress
            }
            const res = await addWuyeCompnay(formData)
            if (res.err === 0) {
                setVisiable(false)
                setUpdateLoading(false)
                if(actionRef.current){
                    actionRef.current.reload()
                }
                message.success("添加成功")
            }else{
                setUpdateLoading(false)
                setVisiable(false)
                message.error("添加失败")
            }
        })
    }
    const tableOperationsBarRender = () =>([
        <Button type="primary" onClick={addModal}>添加公司</Button>
    ])

    return(
        <div>
            <GSTable
                columns={columns}
                actionRef={actionRef}
                operationsBarRender={tableOperationsBarRender()}
                pagination={false}
                request={async (params:any = {}, sort:any, filter:any) => {
                    const res = await getWuyeCompanyList({})
                    if (res.err === 0) {
                        return{
                            data:res.data,
                            total:res.data.length
                        }
                    }else{
                        return{
                            data: [],
                            total: 0
                        }
                    }

                }}
            />


            <Modal
                title={add?'添加公司':'修改公司'}
                visible={visiable}
                onCancel={() => {
                   setVisiable(false)
                }}
                confirmLoading={updateLoading}
                onOk={clickOk}
                okText={'保存'}
                width={800}
            >
                <div>
                    <Form form={form}  layout="horizontal">
                        <FormItem   label={'uuid'} name={'uuid'} hidden={true}>
                          <Input/>
                        </FormItem>
                        <Row   justify={'start'} style={{alignItems: 'center'}}>
                            <Col span={12}>
                                <FormItem   {...formItemLayout} label={'公司名称'} name={'companyName'} rules={[{required:true}]}>
                                    <Input placeholder={'请输入公司名称'}/>
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem   {...formItemLayout} label={'状态'} name={'companyStatus'} rules={[{required:true}]}>
                                    <Input placeholder={'请输入状态'}/>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row  justify={'start'} >
                            <Col span={12}>
                                <FormItem  {...formItemLayout}  label={'联系人姓名'} name="connectName" rules={[{required:true}]}>
                                    <Input  placeholder={'请输入联系人名称'}/>
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout}  label={'联系人邮箱'} name="connectEmail" >
                                    <Input placeholder={'请输入联系人邮箱'}/>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row justify={'start'} style={{alignItems: 'center'}}>
                            <Col span={12}>
                                <FormItem  {...formItemLayout} label={'联系人电话'} name="connectPhone" >
                                    <Input  placeholder={'请输入联系人电话'}/>
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label={'座机'} name="connectTel">
                                    <Input placeholder={'请输入座机号'} />
                                </FormItem>
                            </Col>
                        </Row>
                        <Row  justify={'start'}  style={{alignItems: 'center'}}>
                            <Col flex={1}>
                                <FormItem {...formItemLayout1} name="companyAddress" label={"公司地址"}>
                                    <Input  width={'100%'} placeholder={'请输入公司地址'}/>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row justify={'start'} style={{alignItems: 'center'}}>
                            <Col span={12}>
                                <FormItem  {...formItemLayout} label={'企业图标'} name="companyIcon" >
                                    <Upload
                                        name="image"
                                        listType="picture-card"
                                        className="avatar-uploader"
                                        showUploadList={false}
                                        action = {uploadUrl}
                                        onChange={getFileName}
                                    >
                                        { (companyIcon!==null && companyIcon!==undefined &&companyIcon!=="")  ?  <img alt="avatar" style={{ width: '100%' }} src={companyIcon} /> : uploadButton}
                                    </Upload>
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Modal>
        </div>
    )
}
export default CompanyList;