import Api from "../data/API";

export async function getAllPerm(params:any) {
    return Api.postWithAuth('/api/op/get_all_perm', params);
}
export async function getPermList(params:any) {
    return Api.postWithAuth('/api/op/get_all_perm', params);
}
export async function getAllPermByProject(params:any) {
    return Api.postWithAuth('/api/op/get_all_perm_by_project', params);
}
export async function updatePermItemProject(params:any) {
    return Api.postWithAuth('/api/op/update_perm_item_project', params);
}
export async function updatePermItem(params: any) {
    return Api.postWithAuth('/api/op/update_perm_item', params)
}
export async function addPermItem(params: any) {
    return Api.postWithAuth('/api/op/add_perm_item', params)
}
export async function updatePermItemOrder(params: any) {
    return Api.postWithAuth('/api/op/update_perm_item_order', params)
}
export async function asyncFaceDeviceImage( params: any) {
    return Api.postWithAuth('/api/device/asyncAllFace', params);

}
export async function getVersionController( params: any) {
    return Api.postWithAuth('/admin/api/foundation/getVersionController', params);
}
export async function getAdConfig(params: any) {
    return Api.postWithAuth('/api/op/get_ad_config', params);
}
export async function setAdConfig(params: any) {
    return Api.postWithAuth('/api/op/set_ad_config', params);
}
export async function batchUpdateSmsVendor(params: any) {
    return Api.postWithAuth('/api/op/batchUpdateSmsVendor', params);
}
export async function getSmsSendSuccessCount(params: any) {
    return Api.postWithAuth('/api/op/getSmsSendSuccessCount', params);
}
