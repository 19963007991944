import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import { unregister as unregisterServiceWorker } from './registerServiceWorker';
import {BrowserRouter} from 'react-router-dom';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
import {ConfigProvider} from "antd";
moment.locale('zh-cn');
ReactDOM.render(
    <BrowserRouter>
        <div>
            <ConfigProvider locale={zh_CN}><App /></ConfigProvider>
        </div>
    </BrowserRouter>,
    document.getElementById('root') as HTMLElement
);
//registerServiceWorker();

// TODO 快速开发阶段，关闭缓存
unregisterServiceWorker();
