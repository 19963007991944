import React, { useEffect, useState } from "react";
import {Button, Col, Form, Input, message, Modal, Row, Select, Switch} from 'antd';
import { FormInstance } from "antd/es/form";
import API from "../../data/API";
interface AddFaceDevicePro {
    add:boolean,
    info:any,
    onSubmit?: () => void;
    onCancel: () => void;
    visible?: boolean;
}
const AddFaceDevice:React.FC<AddFaceDevicePro>=(props:any)=>{
    useEffect(()=>{
        getProject()
        initialValue()
        setType(props.info.type || "2")
        if (props.info.is_own_platform) {
            setIsOwnPlatform('true')
        } else {
            setIsOwnPlatform( 'false')
        }
        if (props.info.is_own_platform === undefined) {
            setIsOwnPlatform( 'true')

        }

    },[])
    const [form]= Form.useForm<FormInstance>()
    const [project,setProject] = useState([])
    const [type, setType] = useState("2");
    const [isOwnPlatform, setIsOwnPlatform] = useState("true");

    const Option = Select.Option
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };
    const getProject = () => {
        API.postWithAuth('/api/wuye/get_wuye_project' , {
        }).then(res => {
            if (res.err === 0) {
                console.log('获得物业列表', res);
                setProject(res.data)
            }
        });
    }
    const createDevice = () => {
        let s: any = [];
        let hexDigits = "0123456789abcdef";
        for (let i = 0; i < 36; i++) {
            s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01

        let uuid = s.join("");
        let deviceId = 'Skface' + uuid.substring(0,12);
        // @ts-ignore
        form.setFieldsValue({device_id: deviceId})
    }
    const initialValue =() =>{
        console.log("props:::",props)
        if(!props.add){
            const record = props.info
            const formData :any={
                ...props.info,
                device_id:record.rel_device_id,
                type:record.type ||"2",
                app_id:record.app_id,
                baidu_app_key:record.baidu_app_key,
                baidu_secret_key:record.baidu_secret_key,
                mac:record.rel_device_mac,
                product_key:record.product_key,
                product_secret:record.product_secret,
                state: record.state+"",
                is_own_platform: record.is_own_platform + ""

            }
            form.setFieldsValue(formData)
        }else{
            const formData:any={
                type:"2",
                app_id:"17644120",
                baidu_app_key:"NRDyjOvDtR0bWGOeFSEB2VZE",
                baidu_secret_key:"USyRf5ll28SGtUv6IzrvjSOeKjIFgjz7",
                mac:'',
                product_key:'a1v6ebpgiLE',
                product_secret:"z4pEHB9pouOk8SbD",
                is_own_platform: "true"
            }
            form.setFieldsValue(formData)
        }
    }
    const companyInfoHandleSubmit = (e: any) => {
            e.preventDefault();
            form.validateFields().then(async( data1:any) => {
                console.log("提交 的数据：：： ",data1)
                commitData(data1);
            });
        }
    const  commitData = (values: any) =>  {
        console.log('提交数据', values);
        let newValues = {
            ...values,
            id: props.info === null ? null : props.info.id
        };
        API.postWithAuth('/api/device/add_face_device' , newValues).then(res => {

            if (res.err === 0) {
                console.log('添加设备', res);

                if (props.add) {
                    message.success('添加成功');
                    if (props.onSubmit) {
                        props.onSubmit();
                    }
                } else {
                    message.success('修改成功');
                    if (props.onSubmit) {
                        props.onSubmit();
                    }
                }
            } else {
                message.error(res.msg);
            }
        });
    }

    return(<>
        <Modal title={props.add?'添加':'修改'} maskClosable={false} destroyOnClose={true}  width={900} visible={props.visible} onOk={companyInfoHandleSubmit} onCancel={props.onCancel}>

            <Form form={form} {...formItemLayout}>
                <Row >
                    <Col span={12}>
                        <Form.Item label={'设备ID'} name="device_id" rules={[{ required: false }]}>
                            <Input disabled={!props.add} placeholder={'设备ID'} />
                        </Form.Item>

                    </Col>
                    <Col span={2} >  </Col>
                    <Col span={8}><Button hidden={!props.add} onClick={createDevice}>生成设备ID</Button></Col>
                </Row>
                <Row >
                    <Col span={12}>
                        <Form.Item label={'设备型号'} name="deviceName" rules={[{ required: true }]} initialValue={'GS-X8'}>
                            <Input width={'100%'} placeholder={'设备型号'} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row >
                    <Col span={12}>
                        <Form.Item label={'设备名称'} name="description" rules={[{ required: true }]}>
                            <Input width={'100%'} placeholder={'设备名称'} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row >
                    <Col span={12}>
                        <Form.Item label={'是否激活'}  name="state" rules={[{ required: true }]} initialValue={"false"}>
                            <Select>
                                <Select.Option value={"false"}>未激活</Select.Option>
                                <Select.Option value={"true"}>已激活</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row >
                    <Col span={12}>
                        <Form.Item label={'设备类型'}  name="type" rules={[{ required: true }]}>
                            <Select onChange={(value: any) => setType(value)}>
                                <Select.Option value={"0"}>在线识别</Select.Option>
                                <Select.Option value={"2"}>离线识别</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row >
                    <Col span={12}>
                        <Form.Item label={'通信平台'}  name="is_own_platform" rules={[{ required: true }]} initialValue={'true'}>
                            <Select onChange={(value: any) => setIsOwnPlatform(value)}>
                                <Select.Option key={"false"} value={"false"}>阿里平台</Select.Option>
                                <Select.Option key={"true"} value={"true"}>自有平台</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row >
                    <Col span={12}>
                        <Form.Item label={'mqttServerUrl'} hidden={isOwnPlatform !== 'true'  }  name="mqtt_server_url" initialValue={'tcp://api.guosim.com:1883'}>
                            <Input width={'100%'}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row >
                    <Col span={12}>
                        <Form.Item label={'mqttUserName'} hidden={isOwnPlatform !== 'true' }  name="mqtt_user_name" initialValue={'admin'}>
                            <Input width={'100%'}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row >
                    <Col span={12}>
                        <Form.Item label={'mqttPassWord'} hidden={isOwnPlatform !== 'true' }  name="mqtt_pass_word" initialValue={'xA5iUF2N'}>
                            <Input width={'100%'}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row >
                    <Col span={12}>
                        <Form.Item label={'通知服务器地址'} hidden={type !== "2"}  name="service" initialValue={'api.guosim.com:80'}>
                            <Input width={'100%'}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row >
                    <Col span={12}>
                        <Form.Item label={'离线版激活码'} hidden={type !== "2"}  name="baidu_offline_serial_number" >
                            <Input width={'100%'}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row >
                    <Col span={12}>
                        <Form.Item label={'百度APPID'} hidden={type !== "2"}    name="app_id" >
                            <Input width={'100%'}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row >
                    <Col span={12}>
                        <Form.Item label={'百度APPKEY'} hidden={type === "2"}    name="baidu_app_key" >
                            <Input width={'100%'}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row >
                    <Col span={12}>
                        <Form.Item label={'百度SECRETKEY'} hidden={props.info.type === "2"}  name="baidu_secret_key" >
                            <Input width={'100%'}/>
                        </Form.Item>
                    </Col>
                </Row>

                <Row >
                    <Col span={12}>
                        <Form.Item label={'设备SN'} name='rel_device_key' rules={[{required:true}]}>
                            <Input width={'100%'} placeholder={'设备SN'} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row >
                    <Col span={12}>
                        <Form.Item label={'设备MAC'}   name='mac' >
                            <Input width={'100%'} placeholder={'设备MAC'} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row >
                    <Col span={12}>
                        <Form.Item label={'阿里产品KEY'}  name='product_key' rules={[{required:true}]}>
                            <Input width={'100%'} placeholder={'阿里产品KEY'} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row >
                    <Col span={12}>
                        <Form.Item label={'阿里产品Secret'}  name='product_secret' rules={[{required:false}]}>
                            <Input width={'100%'} placeholder={'阿里产品Secret'} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row >
                    <Col span={12}>
                        <Form.Item label={'阿里设备Secret'}  name='device_secret' rules={[{required:true}]} initialValue={'aliSecret'}>
                            <Input width={'100%'} placeholder={'阿里设备Secret'} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row >
                    <Col span={12}>
                        <Form.Item   valuePropName={"checked"} label={'显示健康码Logo'} name='show_jkb_logo' initialValue={true}>
                            <Switch />
                        </Form.Item>
                    </Col>
                </Row>
                <Row >
                    <Col span={12}>
                        <Form.Item label={'健康码APPID'} name='jian_kang_ma_appid' initialValue={'d7c98d23-a49d-4503-b95c-0802a0c2842c'}>
                            <Input width={'100%'} placeholder={'健康码APPID'} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row >
                    <Col span={12}>
                        <Form.Item label={'健康码SECRET'}  name='jian_kang_ma_secret' initialValue={'60f3dca1f98c614a95ad490e9737127a'}>
                            <Input width={'100%'} placeholder={'健康码SECRET'} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row >
                    <Col span={12}>
                        <Form.Item label={'健康码设备号'} name='jian_kang_ma_device_no' initialValue={'LIUDIAO2021003DF'}>
                            <Input width={'100%'} placeholder={'健康码设备号'} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row >
                    <Col span={12}>
                        <Form.Item label={'健康码IP'} name='jian_kang_ma_ip' initialValue={'124.205.36.147'}>
                            <Input width={'100%'} placeholder={'健康码IP'} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row >
                    <Col span={12}>
                        <Form.Item label={'健康码MAC'}  name='jian_kang_ma_mac' initialValue={'5C-F3-FC-DD-C5-80'}>
                            <Input width={'100%'} placeholder={'健康码MAC'} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row >
                    <Col span={12}>
                        <Form.Item label={'健康码位置'}  name='jian_kang_ma_position'initialValue={'(116.438616,39.870137)'} >
                            <Input width={'100%'} placeholder={'健康码位置'} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row >
                    <Col span={12}>
                        <Form.Item label={'百度语音合成序列号'}  name='baidu_yu_ying_he_cheng_sn' >
                            <Input width={'100%'} placeholder={'百度语音合成序列号'} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row >
                    <Col span={12}>
                        <Form.Item label={'物业ID'} name='wuye_uuid' rules={[{required:true}]}>
                            <Select placeholder={'选择物业'}>
                                {project.map((item: any) => {
                                    return <Option key={item.uuid} value={item.uuid}>
                                        {item.name}
                                    </Option>;
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row >
                    <Col span={12}>
                        <Form.Item label={'物联卡puk'}  name='puk' rules={[{required:false}]}>
                            <Input width={'100%'} placeholder={'物联卡puk'} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row >
                    <Col span={12}>
                        <Form.Item label={'物联卡ICID'}  name='icid' rules={[{required:false}]}>
                            <Input width={'100%'} placeholder={'物联卡ICID'} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    </>)

}
export default AddFaceDevice
