import * as React from 'react';
import { Route, Switch, Redirect} from 'react-router-dom';
import FeedBackList from './FeedBackList';


class FeedBackManage extends React.Component {

    state: any;
    constructor(props: any) {
        super(props);
        this.state = {url: window.location.pathname, current: 'feedBack-manager'};
    }

    handleClick = (e: any) => {
        console.log('click ', e);
        this.setState({
            current: e.key,
        });
    }

    render() {
        return (
            <div>
                {/*<div role="navigation">*/}
                {/*    <div className="container-fluid">*/}
                {/*        <Menu*/}
                {/*            onClick={this.handleClick}*/}
                {/*            selectedKeys={[this.state.current]}*/}
                {/*            mode="horizontal"*/}
                {/*            style={{textAlign: 'left'}}*/}
                {/*        >*/}
                {/*            <Menu.Item key="feedBack-manager">*/}
                {/*                <NavLink to={'/user/feedBack-manager'}>用户反馈</NavLink>*/}
                {/*            </Menu.Item>*/}
                {/*        </Menu>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="content-big">
                    <Switch>
                        <Route path="/user/feedBack-manager" component={FeedBackList} />
                        <Redirect to="/user/feedBack-manager" />
                        <Route render={() => <FeedBackList/>} />
                    </Switch>
                </div>

            </div>
        );
    }
}

export default FeedBackManage;