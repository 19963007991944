import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Input, message, Modal, Row, Select, Switch, Tooltip } from "antd";
import { ActionType } from '@ant-design/pro-table';
import { CloseCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons/lib';
import { addDataTran, deleteDataTran, getDataTranList, updateDataTran } from "../../services/dataTransmission";
import GSTable from "../../components/GSTable";
import { getAdminWuyeProject } from "../../services/WuyeService";

class ParamData {
    name: string = "";

    type: string = "string";

    label: string = "";

    required: boolean = false;

    note: string = "";
    children: Array<ParamData> | null = null;

    setParamData (data: any) {
        if (data.name) {
            this.name = data.name;
        }
        if (data.type) {
            this.type = data.type;
        }
        if (data.label) {
            this.label = data.label;
        }
        if (data.required) {
            this.required = data.required;
        }
        if (data.note) {
            this.note = data.note;
        }
        if (data.children) {
            this.children = data.children;
        }
    }
}

const DataTransmissionList: React.FC = () => {
    useEffect(()=>{
        getAllproject()
    },[])
    const actionRef = useRef<ActionType>();
    const Option = Select.Option;
    const [params, setParams] = useState<Array<ParamData>>([new ParamData()]);
    const [name, setName] = useState('');
    const [url, setUrl] = useState('');
    const [requestMethod, setRequestMethod] = useState('POST');
    const [add, setAdd] = useState(false);
    const [type,setType] = useState("自有")
    const [visible, setVisible] = useState(false);
    const [note,setNote] = useState("")
    const [successName,setSuccessName] = useState("")
    const [successValue,setSuccessValue] = useState("")
    const [msgName,setMsgName] = useState("")
    const [id,setId] = useState("")
    const [flag,setFlag] = useState(false)
    const [projects,setProjects] = useState([])
    const [wuyeUuid,setWuyeUuid]  = useState("")
    const [search,setSearch] = useState("")
    let projectEnum = {}
    const columns = [
        {
            title: '名称',
            dataIndex: 'name',
            key: 'name',
            hideInSearch: true,
            render: (text: string, record: any) => (
                <>
                    <a
                        onClick={() => {
                            openUpdateModal(record);
                        }}
                    >
                        {text}
                    </a>
                </>
            ),
        },
        {
            title: '类型',
            dataIndex: 'type',
            key: 'type',
            hideInSearch: true,
        },
        {
            title: '项目',
            dataIndex: 'wuyeUuid',
            key: 'wuyeUuid',
            ellipsis: true,
            // valueEnum:projectEnum,
            render:(text:string,item:any)=>{
                return<span>{filteItem(item.wuyeUuid)}</span>
            },
            renderFormItem: (text: string, record: any) =>
                <Select style={{width:'100%'}} defaultValue={search}
                        allowClear
                        onChange={(value:any)=>{setSearch(value);// @ts-ignore
                            actionRef.current.reloadAndRest()}}>
                    {projects.length>0?
                        projects.map((item:any)=>{
                            return  <Select.Option value={item.uuid}>{item.name}</Select.Option>
                        })
                        :""}
                </Select>
        },
        {
            title: '描述',
            dataIndex: 'note',
            key: 'note',
            ellipsis: true,
            hideInSearch: true,
        },
        {
            title: '操作',
            key: 'action',
            hideInSearch: true,
            render: (text: string, record: any) => (
                <>
                    <a
                        onClick={() => {
                            deleteItem(record);
                        }}
                    >
                        删除
                    </a>
                </>
            ),
        },
    ];

    const getAllproject = async ()=>{
        const res = await getAdminWuyeProject({})
        if (res.err === 0) {
            res.data.map((item:any)=>{
                projectEnum[item.uuid] = item.name
            })
            setFlag(true)
            setProjects(res.data)
            if(actionRef.current){
                actionRef.current.reload()
            }
            console.log("projectEnum",projectEnum)

        }
    }
    const filteItem = (string:any)=>{
        const  datas = projects.filter((item:any)=>{
            return item.uuid === string
        })
        const data:any = datas[0]
        return data ?data.name: ""

    }
    const openUpdateModal = (item:any) =>{
        setNote(item.note)
        const returnParam = JSON.parse(item.returnParam)
        const errs = returnParam.filter((item:any)=>{
            return item.key === 'err'
        })
        const errItem:any = errs[0]
        setSuccessName(errItem.name)
        setSuccessValue(errItem.value)
        const msgs = returnParam.filter((item:any)=>{
            return item.key === 'msg'
        })
        const msgItem:any = msgs[0]
        setType(item.type)
        setMsgName(msgItem.name)
        setParams([...JSON.parse(item.param)])
        setUrl(item.url)
        setNote(item.note)
        setWuyeUuid(item.wuyeUuid)
        setRequestMethod(item.requestMethod)
        setName(item.name)
        setAdd(false)
        setId(item.id)
        setVisible(true)
    }
    const getList = async (param:any) => {
        if(flag){
            let params = {
                wuyeUuid: search,
            };
            let rsp = await getDataTranList(params);
            if (rsp.err === 0) {
                return {
                    data: rsp.data,
                };
            } else {
                return {
                    data: [],
                };
            }
        }else{
            return {
                data: [],
            };
        }

    };
    const deleteItem = (item: any) => {
        Modal.confirm({
            title: '你确定要删除' + item.name + '吗？',
            okType: 'danger',
            onOk: async () => {
                const data: any = {
                    id: item.id,
                    wuyeUuid: item.wuyeUuid,
                    projType:'op'
                };
                const res = await deleteDataTran(data);
                if (res.err === 0) {
                    message.success('删除成功');
                    if(actionRef.current){
                        actionRef.current.reload();
                    }
                } else {
                    message.error('删除失败');
                }
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };
    const addModal = () => {
        let data = new Array<ParamData>();
        data.push(new ParamData());
        setParams(data);
        setName('');
        setRequestMethod('POST');
        setUrl('');
        setNote("")
        setAdd(true);
        setSuccessValue('')
        setSuccessName('')
        setMsgName('')
        setType('自有')
        setVisible(true);
    };
    const tableOperationsBarRender = () => [
        <Button type={'primary'} onClick={addModal}>
            添加
        </Button>,
    ];

    const saveItem = () => {
        if (add) {
            addItem();
        } else {
            updateItem();
        }
    };
    const addItem = async () => {
        const returnParam:any = [
            {
                key:'err',name:successName,value:successValue
            },
            {
                key:'msg',name:msgName,value:''
            }
        ]
        const data:any={
            type:type,
            name:name,
            url:url,
            param:JSON.stringify(params),
            note:note,
            requestMethod:requestMethod,
            returnParam:JSON.stringify(returnParam),
            projType:'op'
        }
        if(type === '自有'){
            data.wuyeUuid ="root"
        }else{
            data.wuyeUuid = wuyeUuid
        }
        const res = await addDataTran(data)
        if(res.err === 0){
            message.success("添加成功")
            setVisible(false)
            if(actionRef.current){
                actionRef.current.reload()
            }
        }else{
            message.error("添加失败")
        }
    };
    const updateItem = async () => {
        const returnParam:any = [
            {
                key:'err',name:successName,value:successValue
            },
            {
                key:'msg',name:msgName,value:''
            }
        ]
        const data:any={
            id:id,
            name:name,
            type:type,
            url:url,
            param:JSON.stringify(params),
            note:note,
            requestMethod:requestMethod,
            returnParam:JSON.stringify(returnParam),
            projType:'op'
        }
        if(type === '自有'){
            data.wuyeUuid ="root"
        }else{
            data.wuyeUuid = wuyeUuid
        }
        const res = await updateDataTran(data)
        if(res.err === 0){
            message.success("修改成功")
            setVisible(false)
            if(actionRef.current){
                actionRef.current.reload()
            }
        }else{
            message.error("修改失败")
        }
    };


    const deleteChildrenParams = (data: Array<ParamData> | null, index: any) => {
        if (data) {
            data.splice(index, 1);
        }
        setParams([...params]);
    };
    const changeChildrenValue = (data: Array<ParamData> | null, index: number, value: any, type: string) => {
        console.log('data:::::', data);
        if (data) {
            data[index][type] = value;
            if (type === "type" && value !== "string") {
                let array = new Array<ParamData>();
                array.push(new ParamData());
                data[index].children = array;
            }else if (type === "type") {
                data[index].children = null;
            }
        }
        setParams([...params]);
    };
    const addChildrenParams = (data: Array<ParamData> | null) => {
        if (data) {
            data.push(new ParamData());
        }
        setParams([...params]);
    };
    const renderChild = (item: ParamData, name: string): any => {
        if (item.type !== "string" && item.children != null) {
            return (
                <>
                    {item.children.map((item1: ParamData, index: number) => (
                        <>
                            <Row align={"middle"} gutter={15}>
                                <Col span={6}  style={{ textAlign: "right", marginTop: 10 }}>
                                    {name}结构
                                </Col>
                                <Col  span={3} style={{ marginTop: 10 }}>
                                    <Input
                                        value={item1.name}
                                        onChange={(e: any) => {
                                            changeChildrenValue(item.children, index, e.target.value, "name");
                                        }}
                                    />
                                </Col>
                                <Col span={3} style={{ marginTop: 10 }}>
                                    <Select
                                        style={{width: '100%'}}
                                        defaultValue={item1.type || "string"}
                                        onChange={(e: any) => {
                                            changeChildrenValue(item.children, index, e, "type");
                                        }}
                                    >
                                        <Select.Option key={"string"} value={"string"}>
                                            字符串
                                        </Select.Option>
                                        <Select.Option key={"object"} value={"object"}>
                                            对象
                                        </Select.Option>
                                        {
                                            item.type !== 'array' &&  <Select.Option key={"array"} value={"array"}>
                                                对象数组
                                            </Select.Option>
                                        }

                                    </Select>
                                </Col>
                                <Col span={3} style={{ marginTop: 10 }}>
                                    <Input
                                        value={item1.label}
                                        onChange={(e: any) => {
                                            changeChildrenValue(item.children, index, e.target.value, "label");
                                        }}
                                    />
                                </Col>
                                <Col span={4} style={{ marginTop: 10 }}>
                                    <Input
                                        value={item1.note}
                                        onChange={(e: any) => {
                                            changeChildrenValue(item.children, index, e.target.value, "note");
                                        }}
                                    />
                                </Col>
                                <Col span={1} style={{ marginTop: 10 }}>
                                    <Switch
                                        checked={item1.required}
                                        onChange={(e: any) => {
                                            changeChildrenValue(item.children, index, e, "required");
                                        }}
                                    />
                                </Col>
                                <Col span={2} style={{ textAlign: "right", marginTop: 10 }}>
                                    <CloseCircleOutlined style={{ cursor: "pointer" }} onClick={() => {
                                        deleteChildrenParams(item.children, index);
                                    }}/>
                                </Col>
                            </Row>
                            {renderChild(item1, item1.label)}
                        </>
                    ))}
                    <Row align={"middle"} gutter={15}>
                        <Col span={16} offset={6} style={{ marginTop: 10 }}>
                            <Button
                                type={"primary"}
                                onClick={() => {
                                    addChildrenParams(item.children);
                                }}
                            >
                                添加{name}结构
                            </Button>
                        </Col>
                    </Row>
                </>
            );
        }
        return <></>;
    };
    return (
        <>
            <GSTable actionRef={actionRef}  operationsBarRender={tableOperationsBarRender()} columns={columns} search={true} request={(params:any)=>{return  getList(params)}}/>
            <Modal
                title={add ? '添加' : '修改'}
                visible={visible}
                width={1000}
                onCancel={() => {
                    setVisible(false);
                }}
                onOk={saveItem}
            >
                <Row align={'middle'}>
                    <Col  span={6} style={{ textAlign: 'right',marginTop:10 }}>
                        名称 <Tooltip title="流程单中设置数据传输时，显示的数据参数名称"><QuestionCircleOutlined style={{cursor: "pointer"}} /></Tooltip> ：
                    </Col>
                    <Col span={16} style={{marginTop:10}}>
                        <Input
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value);
                            }}
                        />
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={6} style={{ textAlign: 'right',marginTop:10 }}>
                        描述：
                    </Col>
                    <Col span={16} style={{marginTop:10}}>
                        <Input.TextArea value={note} onChange={(e)=>{setNote(e.target.value)}}>

                        </Input.TextArea>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={6} style={{ textAlign: 'right',marginTop:10 }}>
                        类型：
                    </Col>
                    <Col span={16} style={{marginTop:10}}>
                        <Select value={type} style={{width:'100%'}} onChange={(value:any)=>{setType(value)}}>
                            <Select.Option value={'自有'}>自有</Select.Option>
                            <Select.Option value={'其他'}>其他</Select.Option>
                        </Select>
                    </Col>
                </Row>
                <Row align={'middle'} hidden={type==='自有'}>
                    <Col span={6} style={{ textAlign: 'right',marginTop:10 }}>
                        项目：
                    </Col>
                    <Col span={16} style={{marginTop:10}} >
                        <Select value={wuyeUuid} showSearch optionFilterProp="children" style={{width:'100%'}}  onChange={(value:any)=>{setWuyeUuid(value)}}>
                            {projects.length>0?
                                projects.map((item:any)=>{
                                    return  <Select.Option value={item.uuid}>{item.name}</Select.Option>

                                })
                                :""}
                        </Select>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={6} style={{ textAlign: 'right',marginTop:10 }}>
                        url：
                    </Col>
                    <Col span={16} style={{marginTop:10}}>
                        <Input
                            value={url}
                            onChange={(e) => {
                                setUrl(e.target.value);
                            }}
                        />
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={6} style={{ textAlign: 'right' ,marginTop:10}}>
                        请求方式：
                    </Col>
                    <Col span={16} style={{marginTop:10}}>
                        <Select value={requestMethod} style={{ width: '100%' }}>
                            <Option value={'POST'}>POST</Option>
                            <Option value={'GET'}>GET</Option>
                        </Select>
                    </Col>
                </Row>
                <Row align={"middle"} gutter={15}>
                    <Col span={6} style={{ textAlign: "right", marginTop: 10 }}>
                        请求参数：
                    </Col>
                    <Col span={3} style={{ marginTop: 10 }}>
                        参数名{" "}
                        <Tooltip title={"调用api时使用的参数名"}>
                            <QuestionCircleOutlined/>
                        </Tooltip>
                    </Col>
                    <Col span={3} style={{ marginTop: 10 }}>
                        参数类型
                    </Col>
                    <Col span={3} style={{ marginTop: 10 }}>
                        名称
                    </Col>

                    <Col span={4} style={{ marginTop: 10 }}>
                        备注
                    </Col>
                    <Col span={3} style={{ marginTop: 10 }}>
                        是否必填
                    </Col>
                </Row>

                {params.map((item: any, index: any) => {
                    return (
                        <>
                            <Row align={"middle"} gutter={15}>
                                <Col offset={6} span={3} style={{ marginTop: 10 }}>
                                    <Input
                                        value={item.name}
                                        onChange={(e) => {
                                            changeChildrenValue(params, index, e.target.value, "name");
                                        }}
                                    />
                                </Col>
                                <Col span={3} style={{ marginTop: 10 }}>
                                    <Select
                                        style={{width: '100%'}}
                                        defaultValue={item.type || "string"}
                                        onChange={(e: string) => {
                                            changeChildrenValue(params, index, e, "type");
                                        }}
                                    >
                                        <Select.Option key={"string"} value={"string"}>
                                            字符串
                                        </Select.Option>
                                        <Select.Option key={"object"} value={"object"}>
                                            对象
                                        </Select.Option>
                                        <Select.Option key={"array"} value={"array"}>
                                            对象数组
                                        </Select.Option>
                                    </Select>
                                </Col>
                                <Col span={3} style={{ marginTop: 10 }}>
                                    {" "}
                                    <Input
                                        value={item.label}
                                        onChange={(e) => {
                                            changeChildrenValue(params, index, e.target.value, "label");
                                        }}
                                    />
                                </Col>
                                <Col span={4} style={{ marginTop: 10 }}>
                                    <Input
                                        value={item.note}
                                        onChange={(e) => {
                                            changeChildrenValue(params, index, e.target.value, "note");
                                        }}
                                    />
                                </Col>
                                <Col span={1} style={{ marginTop: 10 }}>
                                    <Switch
                                        checked={item.required}
                                        onChange={(e) => {
                                            changeChildrenValue(params, index, e, "required");
                                        }}
                                    />
                                </Col>
                                <Col span={2} style={{ textAlign: "right", marginTop: 10 }}>
                                    <CloseCircleOutlined
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            deleteChildrenParams(params, index);
                                        }}
                                    />
                                </Col>
                            </Row>
                            {renderChild(item, item.label)}
                        </>
                    );
                })}

                <Row align={"middle"} gutter={15}>
                    <Col span={16} offset={6} style={{ marginTop: 10 }}>
                        <Button type={"primary"} onClick={() => addChildrenParams(params)}>
                            添加参数
                        </Button>
                    </Col>
                </Row>
                <Row align={"middle"} gutter={15}>
                    <Col span={6} style={{ textAlign: "right", marginTop: 10 }}>
                        返回参数：
                    </Col>
                    <Col span={3} style={{ marginTop: 10 }}>
                        描述
                    </Col>
                    <Col span={3} style={{ marginTop: 10 }}>
                        参数名
                    </Col>
                    <Col span={4} style={{ marginTop: 10 }}>
                        值
                    </Col>
                </Row>
                <Row align={"middle"} gutter={15}>
                    <Col offset={6} span={3} style={{ marginTop: 10 }}>
                        成功状态
                    </Col>
                    <Col span={3} style={{ marginTop: 10 }}>
                        <Input
                            value={successName}
                            onChange={(e) => {
                                setSuccessName(e.target.value);
                            }}
                        />
                    </Col>
                    <Col span={4} style={{ marginTop: 10 }}>
                        <Input
                            value={successValue}
                            onChange={(e) => {
                                setSuccessValue(e.target.value);
                            }}
                        />
                    </Col>
                </Row>
                <Row align={"middle"} gutter={15}>
                    <Col offset={6} span={3} style={{ marginTop: 10 }}>
                        提示信息
                    </Col>
                    <Col span={3} style={{ marginTop: 10 }}>
                        <Input
                            value={msgName}
                            onChange={(e) => {
                                setMsgName(e.target.value);
                            }}
                        />
                    </Col>
                    {/*<Col span={2} style={{ marginTop: 10 }}> <Input value={successValue} onChange={(e)=>{setSuccessValue(e.target.value)}}/></Col>*/}
                </Row>

            </Modal>
        </>
    );
};
export default DataTransmissionList;
