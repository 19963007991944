import Api from "../data/API";
export async function getAllDetectRecord(params:any) {
    return Api.postWithAuth('/api/device/getDetectRecordList', params);
}
export async function batchUpdateJKM(params: any) {
    return Api.postWithAuth('/api/op/face_device/batchUpdateJKM', params)
}
export async function cpEkey(params: any) {
    return Api.postWithAuth('/api/op/face_device/cpekey', params)
}
export async function sendAdminKey(params: any) {
    return Api.postWithAuth('/admin/api/ekey/sendAdminKey', params)
}
