import React, { useEffect, useRef, useState } from "react";
import GSTable from "../../components/GSTable";
import {
    addProject,
    getAdminWuyeProject,
    updataProject,
    updateApplication,
} from "../../services/WuyeService";
import { ActionType } from "@ant-design/pro-table";
import {
    Button,
    Checkbox,
    Col,
    Divider,
    Form,
    Input,
    Menu,
    message,
    Modal,
    Row,
    Select,
    Space,
    Spin,
    Switch,
    Upload,
} from "antd";
import { FormInstance } from "antd/es/form";
import { getWuyeCompanyList } from "../../services/CompanyService";
import Api from "../../data/API";
import { PlusOutlined } from "@ant-design/icons/lib";
import {
    batchUpdateSmsVendor,
    getAllPerm,
    getAllPermByProject,
    getSmsSendSuccessCount,
    getVersionController,
    updatePermItemProject,
} from "../../services/OpService";
import { getUserToken } from "../../services/UserService";
import appConfig from "../../appConfig";
import API from "../../data/API";
const ProjectList: React.FC = () => {
    useEffect(() => {
        getAllProject();
        const permItemAll = Api.getAllPerm();
        if (permItemAll.length === 0) {
            getPeml();
        }

        console.log("perItemAll", permItemAll);
        setPerItemAll(permItemAll);
        getCompanyList();
    }, []);
    const [projects, setProjects] = useState([]);
    const [permItemAll, setPerItemAll] = useState(API.allPerm);
    const [form] = Form.useForm<FormInstance>();
    const [smsForm] = Form.useForm<FormInstance>();
    const [updateForm] = Form.useForm<FormInstance>();
    const [visibel, setVisible] = useState(false);
    const [addVisible, setAddVisible] = useState(false);
    const [companyList, setCompanyList] = useState([]);
    const [projImage, setProjImage] = useState("");
    const uploadUrl = Api.apiRootPath + "/api/picture/upload_qiniu_two";
    const FormItem = Form.Item;
    const actionRef = useRef<ActionType>();
    const Option = Select.Option;
    const [updateLoading, setUpdateLoading] = useState(false);
    const [projectUuid, setProjectUuid] = useState("");
    const [selectionValue, setSelectionValue] = useState<any>(new Set());
    const [jurisdictionVisible, setJurisdictionVisible] = useState(false);
    const [skey, setskey] = useState("1");
    const [qianDuanVisible, setQianDuanVisible] = useState(false);
    const [uuid, setUuid] = useState("");
    const [smsCount, setsmsCount] = useState(0)
    const [loading, setloading] = useState(false)
    const [xcxProjects, setXcxProjects] = useState([]);
    const [qianDuanForm] = Form.useForm<FormInstance>();
    const [selectedRowKeys, setselectedRowKeys] = useState([]);
    const [batchSmsVisible, setbatchSmsVisible] = useState(false);
    const [smsCountVisible, setSmsCountVisible] = useState(false)
    const columns = [
        {
            title: "项目名称",
            dataIndex: "name",
            key: "name",
            // width: '10%',
            render: (text: string, record: any) => (
                <a
                    onClick={() => {
                        openUpdateModal(record);
                    }}
                >
                    {text}
                </a>
            ),
        },
        {
            title: "公司名称",
            dataIndex: "companyName",
            key: "companyName",
            // width: '10%',
        },
        {
            title: "管理人",
            dataIndex: "realName",
            key: "realName",
            // width: '10%',
        },
        {
            title: "管理分组",
            dataIndex: "groupName",
            key: "groupName",
            // width: '10%',
        },
        {
            title: "操作",
            key: "action",
            // width: '15%',
            render: (text: string, record: any) => (
                <span>
                    <ul
                        className="ant-list-item-action"
                        style={{ marginLeft: 0 }}
                    >
                        <li>
                            <a
                                onClick={() => {
                                    Jurisdiction(record);
                                }}
                            >
                                权限
                            </a>
                            <em className="ant-list-item-action-split" />
                        </li>
                        <li>
                            <a
                                onClick={() => {
                                    openApplicationModel(record);
                                }}
                            >
                                前端配置
                            </a>
                            <em className="ant-list-item-action-split" />
                        </li>
                        <li>
                            <a
                                onClick={() => {
                                    access(record);
                                }}
                            >
                                访问
                            </a>
                        </li>
                        <li>
                            <a
                                onClick={() => {
                                    smsSendCount(record);
                                }}
                            >
                                短息发送条数
                            </a>
                        </li>
                    </ul>
                </span>
            ),
        },
    ];


    const smsSendCount =async (value: any) => {
        setloading(true)
        const res = await getSmsSendSuccessCount({ wuyeUuid: value.uuid })
        setloading(false)
        if (res.err == 0) {
            setSmsCountVisible(true)
            console.log("smsSendCount::", res);
            setsmsCount(res.data || 0)
        }
    }

    const openApplicationModel = (value: any) => {
        console.log("value:::", value);
        setUuid(value.uuid);
        const formData: any = {
            appXcx:
                value.appXcx === null || value.appXcx === ""
                    ? undefined
                    : value.appXcx.split(","),
            orderNotice: value.orderNotice,
            handleNotice: value.handleNotice,
            sendKeyConfig: value.sendKeyConfig ? value.sendKeyConfig.split(","): []
        };
        qianDuanForm.setFieldsValue(formData);
        setQianDuanVisible(true);
    };
    const getAllProject = async () => {
        const res = await getVersionController({});
        if (res.err === 0) {
            setProjects(res.data);
            if (res.data.length > 0) {
                const xcxs = res.data.filter((item: any) => {
                    return item.appType === "xcx";
                });
                setXcxProjects(xcxs);
            } else {
                setXcxProjects([]);
            }
        }
    };
    const openUpdateModal = (value: any) => {
        console.log("value:::", value);
        const formData: any = {
            companyUuid: value.companyUuid,
            projectUuid: value.uuid,
            groupUuid: value.groupUuid,
            projectName: value.name,
            companyName: value.companyName,
            managePhone: value.phone,
            manageGroup: value.groupName,
            wechatAppAppId: value.wechatAppAppId,
            wechatAppAppSecret: value.wechatAppAppSecret,
            wechatMpAppId: value.wechatMpAppId,
            wechatMpAppsecret: value.wechatMpAppsecret,
            wechatMiniProgramAppid: value.wechatMiniProgramAppId,
            wechatMiniProgramAppsecret: value.wechatMiniProgramAppsecret,
            wechatPayMchid: value.wechatPayMchid,
            wechatPayAppid: value.wechatPayAppId,
            wechatPayKey: value.wechatPayKey,
            wechatNotifyUrl: value.wechatNotifyUrl,
            wechatPayCert: value.wechatPayCert,
            projImage: value.projImage,
            proJName: value.name,
            address: value.address,
            companyStatus: value.companyStatus,
            slogan: value.slogan,
            watermark: value.watermark,
            smsSign: value.smsSign,
            smsAccessKeyid: value.smsAccessKeyid,
            smsVendor: value.smsVendor,
            smsAccessKeysecret: value.smsAccessKeysecret,
            quitDeleteKey: value.quitDeleteKey,
        };
        setskey("1");
        setProjImage(value.projImage);
        setVisible(true);
        updateForm.setFieldsValue(formData);
    };
    const getPeml = async () => {
        const res = await getAllPerm({});
        if (res.err === 0) {
            res.data.sort(function (a: any, b: any) {
                return a.order - b.order;
            });
            setPerItemAll(res.data);
            if (actionRef.current) {
                actionRef.current.reload();
            }
            localStorage.setItem("allPerm", JSON.stringify(res.data));
        }
    };
    const access = async (record: any) => {
        const res = await getUserToken({
            userUuid: record.userUuid,
        });
        if (res.err === 0) {
            const url = appConfig.webCompanyUrl + "/wy/login?token=" + res.data;
            window.open(url, "_blank");
        } else {
            message.error("无法访问");
        }
    };
    const Jurisdiction = async (value: any) => {
        setProjectUuid(value.uuid);
        setJurisdictionVisible(true);
        const res = await getAllPermByProject({ proj_uuid: value.uuid });
        if (res.err === 0) {
            setSelectionValue(new Set(res.data));
        }
    };
    const getCompanyList = async () => {
        const res = await getWuyeCompanyList({});
        if (res.err === 0) {
            setCompanyList(res.data);
        }
    };
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };
    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>上传</div>
        </div>
    );
    const openAddModal = () => {
        form.resetFields();
        const formData: any = {
            manageGroup: "超级管理员",
        };
        form.setFieldsValue(formData);
        setProjImage("");
        setAddVisible(true);
    };
    const getFileName = (fileList: any) => {
        console.log("fileList", fileList);
        if (fileList.file.status === "done") {
            setProjImage(fileList.file.response.data[0]);
            console.log(`${fileList.file.name}`);
            console.log("公司上传", fileList.file.response.url);
            console.log("公司上传1", fileList.file.response.data[0]);
        } else if (fileList.file.status === "error") {
            message.error(`${fileList.file.name} 上传文件失败`);
        }
    };
    const addItem = () => {
        form.validateFields().then(async (data: any) => {
            const res = await addProject({
                companyUuid: data.companyUuid,
                projectName: data.projectName,
                managePhone: data.managePhone,
                projImage: projImage,
                manageGroup: "超级管理员",
            });
            console.log("添加项目", res);
            if (res.err === 0) {
                message.success("添加成功");
                setAddVisible(false);
                form.resetFields();
                setProjImage("");
                if (actionRef.current) {
                    actionRef.current.reload();
                }
            } else {
                message.error(res.msg);
            }
        });
    };
    const jurisdictionModalOK = async () => {
        let data = {
            permKeyList: [...selectionValue].toString(),
            projUuid: projectUuid,
        };
        setUpdateLoading(true);
        const res = await updatePermItemProject(data);
        setUpdateLoading(false);
        setJurisdictionVisible(false);
        if (res.err === 0) {
            message.success(res.msg);
        } else {
            message.error(res.msg);
        }
    };
    const tableOperationsBarRender = () => [
        <Button type="primary" onClick={openAddModal}>
            添加项目
        </Button>,
    ];
    const onChangeMenu = (e: any, child: any) => {
        console.log(`checked = ${e.target.checked}`);
        console.log("child::", child);
        let permissions = selectionValue;
        console.log("permissions::::", permissions);
        if (e.target.checked) {
            permissions.add(e.target.value);
            if (child.length > 0) {
                getAllChild(child, (item: any) => {
                    permissions.add(item.permKey + "");
                });
            }
        } else {
            permissions.delete(e.target.value);
            if (child.length > 0) {
                getAllChild(child, (item: any) => {
                    permissions.delete(item.permKey + "");
                });
            }
        }
        setSelectionValue(new Set(permissions));
    };
    const getAllChild = (data: any, cb: any) => {
        (data || []).map((item: any) => {
            cb(item);
            if (item.children) {
                getAllChild(item.children, cb);
            }
        });
    };
    const onChangeButton = (e: any) => {
        let permissions = selectionValue;
        if (e.target.checked) {
            permissions.add(e.target.value);
        } else {
            permissions.delete(e.target.value);
        }
        setSelectionValue(new Set(permissions));
    };
    const getFromData = (type: string) => {
        console.log("type:::", type);
        return (
            <Form form={updateForm} {...formItemLayout}>
                {/*<Row className="margin-top--10" justify={'start'} style={{ alignItems: 'center' }}>*/}
                <FormItem hidden={true} name={"projectUuid"}>
                    <Input />
                </FormItem>
                <FormItem hidden={true} name={"groupUuid"}>
                    <Input />
                </FormItem>
                <div hidden={type == "weixin" || type == "sms"}>
                    <Row
                        className="margin-top--10"
                        justify={"start"}
                        style={{ alignItems: "center" }}
                    >
                        <Col span={12}>
                            <FormItem
                                label={"公司名称"}
                                name={"companyUuid"}
                                rules={[{ required: true }]}
                            >
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    placeholder="请选择项目"
                                    style={{ width: "100%" }}
                                >
                                    {companyList.map((item: any) => {
                                        return (
                                            <Option
                                                value={item.uuid}
                                                key={item.uuid}
                                            >
                                                {item.companyName}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem
                                label={"项目名称"}
                                name={"projectName"}
                                rules={[{ required: true }]}
                            >
                                <Input placeholder="请输入" />
                            </FormItem>
                        </Col>
                    </Row>
                    <Row
                        className="margin-top--10"
                        justify={"start"}
                        style={{ alignItems: "center" }}
                    >
                        <Col span={12}>
                            <FormItem
                                label={"管理人手机"}
                                name={"managePhone"}
                                rules={[{ required: true }]}
                            >
                                <Input placeholder="请输入" />
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem
                                label={"管理分组名称"}
                                name={"manageGroup"}
                                rules={[{ required: true }]}
                            >
                                <Input
                                    placeholder="请输入"
                                    value={"超级管理员"}
                                    disabled={true}
                                />
                            </FormItem>
                        </Col>
                    </Row>
                    <Row
                        className="margin-top--10"
                        justify={"start"}
                        style={{ alignItems: "center" }}
                    >
                        <Col span={12}>
                            <FormItem label={"项目slogan"} name={"slogan"}>
                                <Input placeholder="请输入" />
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem label={"项目状态"} name={"companyStatus"}>
                                <Input placeholder="请输入" />
                            </FormItem>
                        </Col>
                    </Row>
                    <Row
                        className="margin-top--10"
                        justify={"start"}
                        style={{ alignItems: "center" }}
                    >
                        <Col span={12}>
                            <FormItem label={"项目封面图"} name={"projImage"}>
                                <Upload
                                    name="image"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    action={uploadUrl}
                                    onChange={getFileName}
                                >
                                    {projImage !== null &&
                                    projImage !== undefined &&
                                    projImage !== "" ? (
                                        <img
                                            alt="avatar"
                                            style={{ width: "100%" }}
                                            src={projImage}
                                        />
                                    ) : (
                                        uploadButton
                                    )}
                                </Upload>
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem label={"项目地址"} name={"address"}>
                                <Input placeholder="请输入" />
                            </FormItem>
                        </Col>
                    </Row>
                    <Row
                        className="margin-top--10"
                        justify={"start"}
                        style={{ alignItems: "center" }}
                    >
                        <Col span={12}>
                            <FormItem
                                label={"是否开启水印"}
                                name={"watermark"}
                                valuePropName={"checked"}
                            >
                                <Switch />
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem label={"短信签名"} name={"smsSign"}>
                                <Input />
                            </FormItem>
                        </Col>
                    </Row>
                    <Row
                        className="margin-top--10"
                        justify={"start"}
                        style={{ alignItems: "center" }}
                    >
                        <Col span={12}>
                            <FormItem
                                label={"员工离职删除钥匙"}
                                name={"quitDeleteKey"}
                                valuePropName={"checked"}
                            >
                                <Switch />
                            </FormItem>
                        </Col>
                    </Row>
                </div>
                <div hidden={type == "base" || type == "sms"}>
                    <Row
                        className="margin-top--10"
                        justify={"start"}
                        style={{ alignItems: "center" }}
                    >
                        <Col span={12}>
                            <FormItem
                                label={"微信AppAppId"}
                                name={"wechatAppAppId"}
                            >
                                <Input placeholder="请输入" />
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem
                                label={"微信AppSecret"}
                                name={"wechatAppAppSecret"}
                            >
                                <Input placeholder="请输入" />
                            </FormItem>
                        </Col>
                    </Row>
                    <Row
                        className="margin-top--10"
                        justify={"start"}
                        style={{ alignItems: "center" }}
                    >
                        <Col span={12}>
                            <FormItem
                                label={"微信公众号AppId"}
                                name={"wechatMpAppId"}
                            >
                                <Input placeholder="请输入" />
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem
                                label={"微信公众Secret"}
                                name={"wechatMpAppsecret"}
                            >
                                <Input placeholder="请输入" />
                            </FormItem>
                        </Col>
                    </Row>
                    <Row
                        className="margin-top--10"
                        justify={"start"}
                        style={{ alignItems: "center" }}
                    >
                        <Col span={12}>
                            <FormItem
                                label={"微信小程序AppId"}
                                name={"wechatMiniProgramAppid"}
                            >
                                <Input placeholder="请输入" />
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem
                                label={"微信小程序secret"}
                                name={"wechatMiniProgramAppsecret"}
                            >
                                <Input placeholder="请输入" />
                            </FormItem>
                        </Col>
                    </Row>
                    <Row
                        className="margin-top--10"
                        justify={"start"}
                        style={{ alignItems: "center" }}
                    >
                        <Col span={12}>
                            <FormItem
                                label={"wechat_pay_mchid"}
                                name={"wechatPayMchid"}
                            >
                                <Input placeholder="请输入" />
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem
                                label={"wechat_pay_appid"}
                                name={"wechatPayAppid"}
                            >
                                <Input placeholder="请输入" />
                            </FormItem>
                        </Col>
                    </Row>
                    <Row
                        className="margin-top--10"
                        justify={"start"}
                        style={{ alignItems: "center" }}
                    >
                        <Col span={12}>
                            <FormItem
                                label={"wechat_pay_key"}
                                name={"wechatPayKey"}
                            >
                                <Input placeholder="请输入" />
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem
                                label={"wechat_notify_url"}
                                name={"wechatNotifyUrl"}
                            >
                                <Input placeholder="请输入" />
                            </FormItem>
                        </Col>
                    </Row>
                    <Row
                        className="margin-top--10"
                        justify={"start"}
                        style={{ alignItems: "center" }}
                    >
                        <Col span={12}>
                            <FormItem
                                label={"wechat_pay_cert"}
                                name={"wechatPayCert"}
                            >
                                <Input placeholder="请输入" />
                            </FormItem>
                        </Col>
                    </Row>
                </div>
                <div hidden={type == "base" || type == "weixin"}>
                    <Row
                        className="margin-top--10"
                        justify={"start"}
                        style={{ alignItems: "center" }}
                    >
                        <Col span={12}>
                            <FormItem label={"供销商"} name={"smsVendor"}>
                                <Select allowClear>
                                    <Select.Option value={"阿里云"}>
                                        阿里云
                                    </Select.Option>
                                    <Select.Option value={"腾讯云"}>
                                        腾讯云
                                    </Select.Option>
                                    <Select.Option value={"助通"}>
                                        助通
                                    </Select.Option>
                                </Select>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row
                        className="margin-top--10"
                        justify={"start"}
                        style={{ alignItems: "center" }}
                    >
                        <Col span={12}>
                            <FormItem
                                label={"access_keyid"}
                                name={"smsAccessKeyid"}
                            >
                                <Input placeholder="请输入" />
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem
                                label={"access_keysecret"}
                                name={"smsAccessKeysecret"}
                            >
                                <Input placeholder="请输入" />
                            </FormItem>
                        </Col>
                    </Row>
                </div>
            </Form>
        );
    };
    const everyOne = () => {
        switch (skey) {
            case "1":
                return getFromData("base");
            case "2":
                return getFromData("weixin");
            case "3":
                return getFromData("sms");
            default:
                return "";
        }
    };
    const projectUpdata = () => {
        updateForm.validateFields().then(async (data: any) => {
            const formData: any = {
                companyUuid: data.companyUuid,
                projectUuid: data.projectUuid,
                groupUuid: data.groupUuid,
                projectName: data.projectName,
                managePhone: data.managePhone,
                projImage: projImage,
                manageGroup: data.manageGroup,
                wechatAppAppId: data.wechatAppAppId,
                wechatAppAppSecret: data.wechatAppAppSecret,
                wechatMpAppId: data.wechatMpAppId,
                wechatMpAppsecret: data.wechatMpAppsecret,
                wechatMiniProgramAppid: data.wechatMiniProgramAppid,
                wechatMiniProgramAppsecret: data.wechatMiniProgramAppsecret,
                wechatPayMchid: data.wechatPayMchid,
                wechatPayAppid: data.wechatPayAppid,
                wechatPayKey: data.wechatPayKey,
                wechatNotifyUrl: data.wechatNotifyUrl,
                wechatPayCert: data.wechatPayCert,
                address: data.address,
                companyStatus: data.companyStatus,
                slogan: data.slogan,
                watermark: data.watermark,
                smsSign: data.smsSign,
                smsAccessKeysecret: data.smsAccessKeysecret,
                smsVendor: data.smsVendor,
                smsAccessKeyid: data.smsAccessKeyid,
                quitDeleteKey: data.quitDeleteKey,
            };
            console.log("获得更新项目结果", formData);
            const res = await updataProject(formData);
            if (res.err === 0) {
                message.success("更新成功");
            } else {
                message.error(res.msg);
            }
            if (actionRef.current) {
                actionRef.current.reload();
            }
            setVisible(false);
        });
    };

    const saveApplication = () => {
        qianDuanForm.validateFields().then(async (data: any) => {
            const formData: any = {
                wuyeUuid: uuid,
                appXcx:
                    data.appXcx !== null && data.appXcx !== undefined
                        ? data.appXcx.toString()
                        : "",
                orderNotice: data.orderNotice,
                handleNotice: data.handleNotice,
                sendKeyConfig: (data.sendKeyConfig || []).toString()

            };
            const res = await updateApplication(formData);
            if (res.err === 0) {
                message.success("更新成功");
            } else {
                message.error(res.msg);
            }
            if (actionRef.current) {
                actionRef.current.reload();
            }
            setQianDuanVisible(false);
        });
    };
    const batchUpdateSmsGyS = (selectedRows: any) => {
        console.log("selectedRows::::", selectedRows);
        setbatchSmsVisible(true)
    };


    const saveBatchSmsVendor = () => {
        smsForm.validateFields().then(async (data: any) => {
            console.log("data::", data,selectedRowKeys)
            const res = await batchUpdateSmsVendor({ ...data, projUuids: selectedRowKeys.toString() })
            if (res.err == 0) {
                message.success("修改成功");
                setbatchSmsVisible(false)
                if (actionRef) {
                    actionRef.current?.reload()
                }
            } else {
                message.error("修改失败");
            }
        })
    }

    return (
        <>
            <Spin spinning={loading} >
            <GSTable
                columns={columns}
                rowKey="uuid"
                rowSelection={{
                    fixed: true,
                    columnWidth: 100,
                    preserveSelectedRowKeys: true,
                    selectedRowKeys: selectedRowKeys,
                    onChange: (selectedRowKeys: any, selectedRows: any) => {
                        // setquanXuan(false);
                        setselectedRowKeys(selectedRowKeys);
                    },
                    // selections: [
                    //     {
                    //         key: 'SELECT_ALL',
                    //         text: '全选全部页面',
                    //         onSelect: (changableRowKeys: any) => {
                    //             setquanXuan(true);
                    //             setselectedRowKeys(changableRowKeys);
                    //         },
                    //     },
                    // ],
                }}
                tableAlertRender={({
                    selectedRowKeys,
                    selectedRows,
                    onCleanSelected,
                }: any) => {
                    return (
                        <Space size={24}>
                            <span>
                                已选 {selectedRowKeys.length} 项
                                <a
                                    style={{ marginLeft: 8 }}
                                    onClick={() => {
                                        onCleanSelected();
                                    }}
                                >
                                    取消选择
                                </a>
                            </span>
                        </Space>
                    );
                }}
                tableAlertOptionRender={({
                    selectedRowKeys,
                    selectedRows,
                    onCleanSelected,
                }: any) => {
                    console.log("tableAlertOptionRender::::", selectedRowKeys);
                    return (
                        <Space size={16}>
                            <a
                                onClick={() =>
                                    batchUpdateSmsGyS(selectedRowKeys)
                                }
                            >
                                短信供销商
                            </a>
                        </Space>
                    );
                }}
                actionRef={actionRef}
                operationsBarRender={tableOperationsBarRender()}
                pagination={false}
                request={async (params: any = {}, sort: any, filter: any) => {
                    const res = await getAdminWuyeProject({});
                    if (res.err === 0) {
                        return {
                            data: res.data,
                            total: res.count,
                        };
                    } else {
                        return {
                            data: [],
                            total: 0,
                        };
                    }
                }}
            />
            <Modal
                title="创建项目"
                visible={addVisible}
                onOk={addItem}
                onCancel={() => {
                    setAddVisible(false);
                }}
                width={700}
            >
                <Form form={form} {...formItemLayout}>
                    <Row
                        className="margin-top--10"
                        justify={"start"}
                        style={{ alignItems: "center" }}
                    >
                        <Col span={12}>
                            <FormItem
                                label={"公司名称"}
                                name={"companyUuid"}
                                rules={[{ required: true }]}
                            >
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    placeholder="请选择项目"
                                    style={{ width: "100%" }}
                                >
                                    {companyList.map((item: any) => {
                                        return (
                                            <Option
                                                value={item.uuid}
                                                key={item.uuid}
                                            >
                                                {item.companyName}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem
                                label={"项目名称"}
                                name={"projectName"}
                                rules={[{ required: true }]}
                            >
                                <Input placeholder="请输入" />
                            </FormItem>
                        </Col>
                    </Row>
                    <Row
                        className="margin-top--10"
                        justify={"start"}
                        style={{ alignItems: "center" }}
                    >
                        <Col span={12}>
                            <FormItem
                                label={"管理人手机"}
                                name={"managePhone"}
                                rules={[{ required: true }]}
                            >
                                <Input placeholder="请输入" />
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem
                                label={"管理分组名称"}
                                name={"manageGroup"}
                                rules={[{ required: true }]}
                            >
                                <Input
                                    placeholder="请输入"
                                    value={"超级管理员"}
                                    disabled={true}
                                />
                            </FormItem>
                        </Col>
                    </Row>
                    <Row
                        className="margin-top--10"
                        justify={"start"}
                        style={{ alignItems: "center" }}
                    >
                        <Col span={12}>
                            <FormItem label={"项目封面图"} name={"projImage"}>
                                <Upload
                                    name="image"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    action={uploadUrl}
                                    onChange={getFileName}
                                >
                                    {projImage !== null &&
                                    projImage !== undefined &&
                                    projImage !== "" ? (
                                        <img
                                            alt="avatar"
                                            style={{ width: "100%" }}
                                            src={projImage}
                                        />
                                    ) : (
                                        uploadButton
                                    )}
                                </Upload>
                            </FormItem>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <Modal
                title="分配权限"
                visible={jurisdictionVisible}
                onOk={jurisdictionModalOK}
                onCancel={() => {
                    setJurisdictionVisible(false);
                }}
                width={1080}
                confirmLoading={updateLoading}
                key={projectUuid}
            >
                <Row gutter={8} style={{ paddingBottom: 20 }}>
                    <Col span={6}>
                        <div>模块</div>
                    </Col>
                    <Col span={18}>
                        <Row gutter={8} style={{ paddingTop: 0 }}>
                            <Col span={6}>
                                <div>子模块</div>
                            </Col>
                            <Col span={18}>
                                <div>功能模块</div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Checkbox.Group
                    style={{ width: "100%" }}
                    key={selectionValue}
                    value={[...selectionValue]}
                >
                    {permItemAll.map((item: any, index: any) => {
                        let permItemlenght = (permItemAll || []).length;
                        return (
                            <>
                                <Row
                                    gutter={8}
                                    style={{ marginTop: 0 }}
                                    align="middle"
                                >
                                    <Col span={6}>
                                        <Checkbox
                                            value={item.permKey}
                                            onChange={(e) => {
                                                onChangeMenu(
                                                    e,
                                                    item.children || []
                                                );
                                            }}
                                        >
                                            {" "}
                                            {item.name}
                                        </Checkbox>
                                    </Col>
                                    <Col span={18}>
                                        {(item.children || []).map(
                                            (item1: any, index1: number) => {
                                                let childrenLength = (
                                                    item.children || []
                                                ).length;
                                                return (
                                                    <>
                                                        <Row
                                                            gutter={8}
                                                            style={{
                                                                marginTop: 0,
                                                            }}
                                                            align="middle"
                                                        >
                                                            <Col span={6}>
                                                                <Checkbox
                                                                    value={
                                                                        item1.permKey
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        onChangeMenu(
                                                                            e,
                                                                            item1.children ||
                                                                                []
                                                                        );
                                                                    }}
                                                                >
                                                                    {" "}
                                                                    {item1.name}
                                                                </Checkbox>
                                                            </Col>
                                                            <Col span={18}>
                                                                <Row
                                                                    gutter={8}
                                                                    align="middle"
                                                                    style={{
                                                                        marginTop: 0,
                                                                    }}
                                                                >
                                                                    {(
                                                                        item1.children ||
                                                                        []
                                                                    ).map(
                                                                        (
                                                                            item2: any
                                                                        ) => {
                                                                            return (
                                                                                <Col
                                                                                    span={
                                                                                        6
                                                                                    }
                                                                                >
                                                                                    <Checkbox
                                                                                        value={
                                                                                            item2.permKey
                                                                                        }
                                                                                        onChange={
                                                                                            onChangeButton
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            item2.name
                                                                                        }
                                                                                    </Checkbox>
                                                                                </Col>
                                                                            );
                                                                        }
                                                                    )}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        {index1 ===
                                                        childrenLength -
                                                            1 ? null : (
                                                            <Divider
                                                                type={
                                                                    "horizontal"
                                                                }
                                                            />
                                                        )}
                                                    </>
                                                );
                                            }
                                        )}
                                    </Col>
                                </Row>
                                {index === permItemlenght - 1 ? null : (
                                    <Divider type={"horizontal"} />
                                )}
                            </>
                        );
                    })}
                </Checkbox.Group>
            </Modal>
            <Modal
                title="设置项目"
                visible={visibel}
                onOk={projectUpdata}
                onCancel={() => {
                    setVisible(false);
                }}
                width={1100}
            >
                <Row>
                    <Col span={4} style={{ borderRight: "10px solid #f0f2f5" }}>
                        {/*<div style={{padding: '20px 10px 20px 10px', fontSize: 15, color: 'rgba(0, 0, 0, 0.65)', width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{data.projectName}</div>*/}
                        <Menu
                            onClick={(e: any) => {
                                setskey(e.key);
                            }}
                            style={{ width: "100%" }}
                            key={skey}
                            // @ts-ignore
                            defaultSelectedKeys={[...skey]}
                        >
                            <Menu.Item key="1">基本信息</Menu.Item>
                            <Menu.Item key="2">微信设置</Menu.Item>
                            <Menu.Item key="3">短信设置</Menu.Item>
                        </Menu>
                    </Col>
                    <Col span={20} style={{ padding: 10 }}>
                        {everyOne()}
                    </Col>
                </Row>
            </Modal>
            <Modal
                title="前端配置"
                visible={qianDuanVisible}
                onOk={saveApplication}
                onCancel={() => {
                    setQianDuanVisible(false);
                }}
            >
                <Form form={qianDuanForm} {...formItemLayout}>
                    <FormItem name={"appXcx"} label={"前端应用"}>
                        <Select
                            mode="tags"
                            allowClear
                            style={{ width: "100%" }}
                        >
                            {projects.length > 0
                                ? projects.map((item: any) => {
                                      return (
                                          <Option value={item.typeValue}>
                                              {item.name}
                                          </Option>
                                      );
                                  })
                                : ""}
                        </Select>
                    </FormItem>
                    <FormItem
                        name={"orderNotice"}
                        tooltip={"点击流程单接单通知跳转的小程序"}
                        label={"接单xcx"}
                    >
                        <Select allowClear style={{ width: "100%" }}>
                            {xcxProjects.length > 0
                                ? xcxProjects.map((item: any) => {
                                      return (
                                          <Option value={item.typeValue}>
                                              {item.name}
                                          </Option>
                                      );
                                  })
                                : ""}
                        </Select>
                    </FormItem>
                    <FormItem
                        name={"handleNotice"}
                        tooltip={"点击流程单处理结果通知跳转的小程序"}
                        label={"处理结果xcx"}
                    >
                        <Select allowClear style={{ width: "100%" }}>
                            {xcxProjects.length > 0
                                ? xcxProjects.map((item: any) => {
                                      return (
                                          <Option value={item.typeValue}>
                                              {item.name}
                                          </Option>
                                      );
                                  })
                                : ""}
                        </Select>
                    </FormItem>
                    <FormItem name={"sendKeyConfig"} tooltip={'发送钥匙支持的账号类型'} label={'支持的账号类型'}>
                        <Select
                            allowClear
                            mode="tags"

                            style={{width:'100%'}}
                        >
                            <Select.Option value={'员工账号'}>员工账号</Select.Option>
                            <Select.Option value={'按部门选择'}>按部门选择</Select.Option>
                            <Select.Option value={'按入驻企业'}>按入驻企业</Select.Option>
                            <Select.Option value={'输入账号'}>输入账号</Select.Option>
                        </Select>
                    </FormItem>
                </Form>
            </Modal>
            <Modal
                title="短信供销商"
                visible={batchSmsVisible}
                onOk={saveBatchSmsVendor}
                onCancel={() => {
                    setbatchSmsVisible(false);
                }}
            >
                <Form form={smsForm} {...formItemLayout}>
                    <Row
                        className="margin-top--10"
                        justify={"start"}
                        style={{ alignItems: "center" }}
                    >

                        <Col span={12}>
                            <FormItem label={"供销商"} rules={[{required:true,message:'请选择供销商'}]} name={"smsVendor"}>
                                <Select allowClear>
                                    {/* <Select.Option value={"阿里云"}>
                                        阿里云
                                    </Select.Option> */}
                                    <Select.Option value={"腾讯云"}>
                                        腾讯云
                                    </Select.Option>
                                    <Select.Option value={"助通"}>
                                        助通
                                    </Select.Option>
                                </Select>
                            </FormItem>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <Modal title="短信条数"  onCancel={()=>setSmsCountVisible(false)} visible={smsCountVisible} footer={null}>
                短信条数 :{ smsCount}
            </Modal>
            </Spin>
        </>
    );
};
export default ProjectList;
