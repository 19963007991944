import React, {useRef, useState} from 'react';
import GSTable from '../../components/GSTable';
import {ActionType} from '@ant-design/pro-table/lib/typing';
import {getAllDetectRecord} from '../../services/FaceDeviceService';
import {Col, Modal, Row, Image, Tag} from 'antd';

// import {Image} from  'antd'
const DetectRecord:React.FC = () =>{
    const actionRef = useRef<ActionType>();
    const [visible, setVisible] = useState(false);
    const [detectImg, setDetectImg] = useState('');
    const [faceImg, setFaceImg] = useState('');
    const statusEnum: any = {
        'SUCCESS': <Tag color={'green'}>通过</Tag>,
        'ERROR': <Tag color={'red'}>不通过</Tag>,
    };
    const columns = [{
        title: '设备ID',
        dataIndex: 'deviceId',
        key: 'deviceId',
        width: '10%',
    }, {
        title: '分数',
        dataIndex: 'score',
        key: 'score',
        valueType: 'digit',
        width: '10%',
        hideInSearch: true,

    }, {
        title: '识别结果',
        dataIndex: 'status',
        key: 'status',
        valueEnum: statusEnum,
        width: '10%',
    }, {
        title: '姓名',
        dataIndex: 'userName',
        key: 'userName',
        width: '10%',
        hideInSearch: true,
    }, {
        title: '抓拍照片',
        dataIndex: 'detectFaceImg',
        key: 'detectFaceImg',
        width: '10%',
        hideInSearch: true,
        render: (text: string, record: any) => {
            return <img onClick={() => showModal(record)} src={`data:image/png;base64,${text}`} style={{width: 150, height: 150}}></img>
        }
    }, {
        title: '底库照片',
        dataIndex: 'faceImg',
        key: 'faceImg',
        width: '10%',
        hideInSearch: true,
        render: (text: string, record: any) => {
            return <img onClick={() => showModal(record)} src={`data:image/png;base64,${text}`} style={{width: 150, height: 150}}></img>
        }
    }, {
        title: '用户信息',
        dataIndex: 'userInfo',
        key: 'userInfo',
        width: '10%',
        hideInSearch: true,
    },{
        title: '识别时间',
        dataIndex: 'createdTime',
        key: 'createdTime',
        valueType: 'dateTime',
        width: '10%',
        hideInSearch: true,
    },{
        title: '红外活体阈值',
        dataIndex: 'irLivenessScore',
        key: 'irLivenessScore',
        width: '10%',
        hideInSearch: true,
    },{
        title: 'RGB活体阈值',
        dataIndex: 'rgbLivenessScore',
        key: 'rgbLivenessScore',
        width: '10%',
        hideInSearch: true,
    },{
        title: '最小分数',
        dataIndex: 'minScore',
        valueType: 'digit',
        hideInTable:true,
    },{
        title: '最大分数',
        dataIndex: 'maxScore',
        valueType: 'digit',
        hideInTable:true,
    }, {
        title: '创建时间',
        dataIndex: 'time',
        valueType: 'dateRange',
        hideInTable:true,
    }];
    const loadData = async (param: any) => {
        console.log('param:', param);
        let data = {
            deviceId: param.deviceId,
            status: param.status,
            startTime: param.time ? param.time[0] : '',
            endTime: param.time ? param.time[1] : '',
            minScore: param.minScore,
            maxScore: param.maxScore,
            page: param.current,
            size: param.pageSize,
        }
        const res  = await getAllDetectRecord(data)
        if (res.err === 0) {
            return {
                data:res.data,
                total: res.count
            }
        }else{
            return {
                data:[],
            }
        }
    }
    const showModal = (record: any) => {
        setDetectImg('data:image/png;base64,' + record.detectFaceImg)
        setFaceImg('data:image/png;base64,' + record.faceImg)
        setVisible(true)
    }
    return (
        <>
            <GSTable
                search={true}
                columns={columns}
                actionRef={actionRef}
                request={loadData}
            />
            <Modal width={1000} title={'照片对比'} footer={null} visible={visible} onCancel={() => {setVisible(false)}} onOk={() => {setVisible(false)}}>
                <Row gutter={16}>
                    <Col span={12}>
                        <Image src={detectImg} width={480} ></Image>
                    </Col>
                    <Col span={12}>
                        <Image src={faceImg} width={480} ></Image>

                    </Col>
                </Row>
            </Modal>
        </>
    );
};
export default DetectRecord;